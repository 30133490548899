import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, DatePicker, Button } from "antd";
import { clientAction } from "../state/actions";
import moment from "moment";
import { useRef } from "react";

function BirthDateDetails({ data, selectedClient, setModelDrawer }) {
  const dispatch = useDispatch();
  const dateRef = useRef()
  const onFinish = (values) => {
    dispatch(
      clientAction.updateBirthday(
        new Date(values.birthDate).getTime(),
        data,
        selectedClient
      )
    );
    setModelDrawer({ status: false, component: "", title: "" });
  };

  useEffect(() => {
    if (dateRef.current) {
      dateRef.current.focus();
    }
  }, []);

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="Birthday"
        name="birthDate"
        rules={[{ required: true, message: "Please Enter Birth Date!" }]}
      >
        <DatePicker
          ref={dateRef}
          inputReadOnly={true}
          allowClear
          autoFocus
          // disabledDate={(d) => !d || d.isBefore(minimum)}
          placeholder={
            data.birthDate && data.birthDate !== "NaN"
              ? [
                  ("0" + (new Date(data.birthDate).getMonth() + 1)).slice(-2),
                  ("0" + new Date(data.birthDate).getDate()).slice(-2),
                  new Date(data.birthDate).getFullYear(),
                ].join("-")
              : "Select Birthday"
          }
          style={{ width: "150px", cursor: "pointer" }}
          bordered={true}
          disabledDate={(current) => {
            return current.valueOf() >= new Date();
          }}
          format="MM-DD-YYYY"
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}
export { BirthDateDetails };
