/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { SideBar } from "./SideBar";
import { Navbar } from "./Navbar";
import { Layout, Alert, Result, Button } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { accountHandle } from "../../Account/state/actions";
import { appStatusChange } from "../state/actions";
import dotEnv from "dotenv";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Pricing from "../../Pricing/Pricing";
import { userSignUpAction } from "../../User/UserAuthenticate/state/actions";

const { Content } = Layout;
dotEnv.config();
const adminURL = process.env.REACT_APP_ADMIN_BASE_URL;
/**
 * @function PrivateRoute
 * @param {Object} Object parent object paraments
 * @returns {Object} JSX.Element
 * Higher order function that validates the auth token before proceeding
 */

export const PrivateRoute = ({ component: Component, roleDisplay, networkConnection, ...rest }) => {
  const dispatch = useDispatch();
  let accessToken = localStorage.getItem("userAuth");
  const { wholeAppLoading, wholeAppError, getUserDetailsLoading, subscriptionRequired, subscriptionExists, getUserDetailsError,  userId } = useSelector((state) => state.account, shallowEqual);
  const location = useLocation();
  const history = useHistory();
  let urlParams = new URLSearchParams(window.location.search);
  let urlId = JSON.parse(decodeURIComponent(urlParams.get("urlId")));
  let sessionId = JSON.parse(decodeURIComponent(urlParams.get("sessionId")));
  let offerId = JSON.parse(decodeURIComponent(urlParams.get("offerId")));
  let dest = urlParams.get("dest");
  let email = urlParams.get("email");
  let role = urlParams.get("role");
  const category = urlParams.get("category");
  const categoryId = urlParams.get("categoryId");

  // const [planExpired, setPlanExpired] = useState(true);
  let redirectpathname = "",
    searchQuery = "";
  if (dest) {
    if (dest === "RTD" && role === "buyerAgent") {
        redirectpathname = `/transactions/bDashboard/${urlId}`;
    } else if (dest === "RTD" && role === "sellerAgent") {
      redirectpathname = `/transactions/sDashboard/${urlId}`;
    } else if (dest === "offerTable" && role === "sellerAgent") {
      redirectpathname = `${adminURL}/offer/${urlId}`;
    } else if (dest === "PRE_RTD" && role === "sellerAgent") {
      redirectpathname = `${adminURL}/offer/${urlId}/rtd/${offerId}`;
    } else if (dest === "BUG_REPORT") {
      redirectpathname = `/bugsReport`;
      searchQuery = window.location.search || "";
      let queryParams = new URLSearchParams(searchQuery);
      queryParams.delete("sessionId");
      queryParams.delete("email");
      searchQuery = queryParams.toString();
    }else if (dest === "transaction" && category && categoryId) {
      redirectpathname = `/transactions`;
      searchQuery = window.location.search ||"";
      let queryParams = new URLSearchParams(searchQuery);
      queryParams.delete("sessionId");
      queryParams.delete("email");
      searchQuery = queryParams.toString();
     }  else if (dest === "client") {
      redirectpathname = `/contacts`;
      searchQuery = window.location.search ||"";
      let queryParams = new URLSearchParams(searchQuery);
      queryParams.delete("sessionId");
      queryParams.delete("email");
      searchQuery = queryParams.toString();
    }
  }
  useEffect(() => {
    if (accessToken) {
      dispatch(accountHandle.getUserDetails());
    }
    return () => {
      redirectpathname = "";
      dest = "";
      sessionId = "";
      role = "";
    };
  }, [accessToken]);
  // console.log("userId && dest && sessionId", {userId} , {dest} , {role}, {sessionId});
  useEffect(() => {
    if (userId && dest && sessionId && accessToken ) {
      dispatch(appStatusChange.setmanualLoadingFun(true));
      if (sessionId === userId) {
        if ((dest === "offerTable" || dest=== "PRE_RTD") && role === "sellerAgent") {
          window.location.href = `${redirectpathname}`
        } else if (dest === "RTD" && role) {
          history.push({
            pathname: redirectpathname,
          });
        } else if (dest === "transaction") {
          history.push({
            pathname: "/transactions",
            search: searchQuery,
          });
        } else if (dest === "client") {
          history.push({
            pathname: "/contacts",
            search: searchQuery,
          });
        } else if (dest === "BUG_REPORT") {
          history.push({
            pathname: "/bugsReport",
            search: searchQuery,
          });
        }
      } else {
        dispatch(userSignUpAction.LogoutSuccess());
        localStorage.clear();
          history.push({
            pathname: "/login",
            state: { redirectpathname: redirectpathname, dest, sessionId, email, searchQuery },
          });
      }
      dispatch(appStatusChange.setmanualLoadingFun(false));
    }
    return () => {
      dispatch(appStatusChange.setmanualLoadingFun(false));
    };
  }, [userId, accessToken, dest, role]);

  const handleLogin = () => {
    localStorage.removeItem("userAuth");
    window.location.href = "/login";
  };

  const { manualLoading } = useSelector((state) => state.app);

  return (
    wholeAppLoading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    ) : wholeAppError ? (
      "...error"
    ) :
    <>
      <Route
        {...rest}
        render={(props) =>
          accessToken ? (
            <>
              {getUserDetailsLoading || manualLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <Spin size="large" />
                </div>
              ) : getUserDetailsError ? (
                <Result
                  status="404"
                  title="404"
                  subTitle="Not Authorized"
                  extra={
                    <Button type="primary" onClick={handleLogin}>
                      Login
                    </Button>
                  }
                />
              ) : subscriptionRequired && !subscriptionExists ? (
                <div>
                  <Navbar />
                  <Pricing />
                </div>
              ) : (
                <div
                  style={{
                    height: "100vh",
                    overflow: "hidden",
                  }}
                >
                  <Navbar />
                  {location.pathname === "/license" ? "" : <SideBar />}
                  <Layout id="mainLayout">
                    <Content
                      style={{
                        backgroundColor: "rgb(255 255 255)",
                        padding: "0rem 0rem 0rem 0rem",
                      }}
                    >
                      {networkConnection && (
                        <div>
                          <Alert message="No internet" type="error" />
                        </div>
                      )}
                      <Component networkConnection={networkConnection} {...props} />
                    </Content>
                  </Layout>
                </div>
              )}{" "}
            </>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  ...(dest
                    ? {
                        redirectpathname: redirectpathname,
                        dest,
                        sessionId,
                        email,
                        searchQuery,
                      }
                    : {}),
                },
              }}
            />
          )
        }
      />
    </>
  );
};
