// <-------------------- array for duration in reminder and schedule section as well------------->

export const options = [
  {
    label: "30m",
    value: 1,
  },
  {
    label: "1h",
    value: 2,
  },
  {
    label: "1h 30m",
    value: 3,
  },
  {
    label: "2h",
    value: 4,
  },
  {
    label: "2h 30m",
    value: 5,
  },
  {
    label: "3h",
    value: 6,
  },
  {
    label: "3h 30m",
    value: 7,
  },
  {
    label: "4h",
    value: 8,
  },
  {
    label: "4h 30m",
    value: 9,
  },
  {
    label: "5h",
    value: 10,
  },
  {
    label: "5h 30m",
    value: 11,
  },
  {
    label: "6h",
    value: 12,
  },
  {
    label: "6h 30m",
    value: 13,
  },
  {
    label: "7h",
    value: 14,
  },
  {
    label: "7h 30m",
    value: 15,
  },
  {
    label: "8h",
    value: 16,
  },
];
