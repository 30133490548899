import { Button, Input, Modal, Row, Typography } from "antd";
import Title from "antd/lib/skeleton/Title";
import React,{useRef,useEffect} from "react";

const ClauseModal = ({
  showModal,
  setShowModal,
  selectedData,
  setSelectedData,
  onFinish,
}) => {
  const inputRef = useRef();
  const { TextArea } = Input;

  useEffect(() => {
    if (showModal) {
      const focusTimeout = setTimeout(() => {
        inputRef.current && inputRef.current.focus();
      },100);

      return () => {
        clearTimeout(focusTimeout);
      };
    }
  }, [showModal]);

  return (
    <Modal
      visible={showModal}
      title={
        <Typography.Text style={{ fontSize: "25px", color: "grey" }}>
         Update Clause
      </Typography.Text>
      }
      onOk={() => {}}
      footer={false}
      onCancel={() => {
        setShowModal(false);
      }}
    >
      <Row  justify="center">
        <div style={{width:"100%"}}>
        <label style={{fontWeight:"bold",color:"grey", textAlign:"left",width:"100%",marginBottom:"8px"}}>Title</label>
        <Input
            name="title"
            ref={inputRef}
            value={selectedData?.title}
            onChange={(e) =>
                setSelectedData({ ...selectedData, title: e.target.value })
            }
            placeholder="Enter Title Here..."/>
            </div>
            <div style={{width:"100%",marginTop:"20px"}}>
            <label style={{fontWeight:"bold",color:"grey", textAlign:"left",width:"100%",marginBottom:"8px"}}>Description</label>
            <TextArea
                name="name"
                ref={inputRef}
                rows="4"
                cols="60"
                value={selectedData?.name}
                onChange={(e) =>
                    setSelectedData({ ...selectedData, name: e.target.value })
                }
            />
         </div>

        <Button
          type="primary"
          onClick={onFinish}
          style={{ marginTop: "20px" }}
          size="large"
        >
          Save
        </Button>
      </Row>
    </Modal>
  );
};

export { ClauseModal };
