import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const transactionLaneApi = ({ offerId, role, source, delegateSide, delegateOwnerId }) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/RTD/${offerId}`, {
    cancelToken: source.token,
    params: {
      ...(role ? { role } : {}),
      ...(delegateSide ? { delegateSide } : {}),
      ...(delegateOwnerId ? { delegateOwnerId } : {}),
    },
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const getTitleCompanyData = ({ offerId, source, delegateSide, delegateOwnerId }) => {
  const accessToken = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/getSelectedTitleCompanyData`, {
    cancelToken: source.token,
    params: {
      offerId,
      ...(delegateSide ? { delegateSide } : {}),
      ...(delegateOwnerId ? { delegateOwnerId } : {}),
    },
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};


const individualTransactionApi = ({ offerId, ertsId, propertyId, source, delegateSide, delegateOwnerId }) => {
  const accessToken = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/getIndividualTransaction`, {
    cancelToken: source.token,
    params: {
      ...(offerId ? { offerId } : ertsId ? { ertsId } : propertyId ? { propertyId } : {}),
      ...(delegateSide ? { delegateSide } : {}),
      ...(delegateOwnerId ? { delegateOwnerId } : {}),
    },
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const getPropertyDocsRequest = ({ offerId, source, delegateSide, delegateOwnerId }) => {
  const accessToken = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/getSharedDocs`, {
    cancelToken: source.token,
    params: {
      offerId: offerId,
      ...(delegateSide ? { delegateSide } : {}),
      ...(delegateOwnerId ? { delegateOwnerId } : {}),
    },
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};


const draftDocumetnApi = (data) => {
  const accessToken = localStorage.getItem("userAuth");
  return axios.post(
    `${apiURL}/url/builtForSection`,
    {
      ...data,
    },
    {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

const updateDocumentApi = ({documentId, data, contractType, delegateSide,delegateOwnerId, emailSignatureId, emailMessage}) => {
  const accessToken = localStorage.getItem("userAuth");
  let apiUrl = `${apiURL}/offerDocument/${documentId}`;
  return axios.put(
    apiUrl,
    {
      ...data,
      ...(delegateSide ? { delegateSide } : {}),
      ...(delegateOwnerId ? { delegateOwnerId } : {}),
      ...(emailSignatureId ? {emailSignatureId} : {}),
      ...(emailMessage ? {emailMessage} : {}),
    },
    {
     headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

const sendEmailToClientRequest = (data) => {
  const accessToken = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/offerDocument/sendDocument`, data, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};

const getClientScreen = (params) => {

  let accessToken = localStorage.getItem("userAuth");
  return axios.get(
    `${apiURL}/clientRTD`,
    {
      params,

    headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    }
  );
};


const getEmails_FilesApi = (params) => {
  let accessToken = localStorage.getItem("userAuth");
  return axios.get(
    `${apiURL}/emailsAndFiles`,
    {
    params,
    headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    }
  );
};
const getClientDocRequest = (params) => {

  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/propertyDocs`,{
      params,
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const deleteFormRequest = ({documentId, offerId, delegateSide,delegateOwnerId, isToggleFrom}) => {
  const accessToken = localStorage.getItem("userAuth");
  let params = {
    ...(delegateSide ? { delegateSide } : {}),
    ...(delegateOwnerId ? { delegateOwnerId } : {}),
    ...(offerId ? { offerId } : {}),
    ...(isToggleFrom ? {isToggleFrom} : {})
  };
  return axios.delete(`${apiURL}/RTD/deleteForm/${documentId}`, {
    params,
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
}

const urlShorthandApi = (data,source) => {
  let accessTokan = localStorage.getItem("userAuth");
  if(source === 'calendar'){
    return axios.post(`${apiURL}/url`,
      {
        ...data,
      },
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
  }else{
    return axios.post(`${apiURL}/url/builtForSection`,
      {
        ...data,
      },
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
  }
};

const addEmail = (data) => {
  const accessTokan = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/addClientDetail`,{...data},{
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessTokan}`,
    },
  })
}

const getNestedDoraDocuments = ({data, fiters, source}) => {
  let accessTokan = localStorage.getItem("userAuth");
  let params = {
    listType: data.listType,
    offerId: data.offerId,
    ...(fiters?.search ? {search: fiters?.search} : {})
  }
  let url = `${apiURL}/nestedContractDocumentaries`;
  return axios.get( url,
    {
      params,
      cancelToken: source.token,
      headers: {
        accept: "application/json",
        // authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const addTitleoffice = ({payload, delegateSide, delegateOwnerId}) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/updateTitleOrderForm`,
  {
    ...payload,
    ...(delegateSide ? {delegateSide} : {}),
    ...(delegateOwnerId ? {delegateOwnerId} : {})
  },
  {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const gettitlecompany=({type, id, search, source})=>{
  const accessTokan = localStorage.getItem("userAuth");
  let query = "";
  if(type === "COMPANY"){
    query = `?titleType=TITLE_COMPANY&includeLimitedData=true`;
  }
  else if(type==="OFFICE"){
    query = `?titleType=TITLE_OFFICE&parentId=${id}`;
    if(search){
      query += `&search=${search}`;
    }
  }
  return axios.get(`${apiURL}/titleOffice${query}`,{
    cancelToken: source.token,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessTokan}`,
  },
  })
}

const sendTitleCompanyDocument = (payload) => {

  const accessTokan = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/sendMultipleDocuments`,{...payload},{
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessTokan}`,
    },
  })
}

const deleteTitleCompanyData = ({ offerId, delegateSide, delegateOwnerId }) => {
  const accessToken = localStorage.getItem("userAuth");
  return axios.delete(`${apiURL}/clearTitleOrderFormData`, {
    params: {
      offerId,
      ...(delegateSide ? { delegateSide } : {}),
      ...(delegateOwnerId ? { delegateOwnerId } : {}),
    },
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};


export const transactionLaneDataApi = {
  sendEmailToClientRequest,
  transactionLaneApi,
  draftDocumetnApi,
  updateDocumentApi,
  getClientScreen,
  getClientDocRequest,
  getEmails_FilesApi,
  deleteFormRequest,
  individualTransactionApi,
  getPropertyDocsRequest,
  getTitleCompanyData,
  urlShorthandApi,
  addEmail,
  getNestedDoraDocuments,
  addTitleoffice,
  gettitlecompany,
  sendTitleCompanyDocument,
  deleteTitleCompanyData
};
