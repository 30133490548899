import axios from "axios";
import dotEnv from "dotenv";
// import dayjs from "dayjs";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;
const getNotification = (page=1) => {
 let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/notifications`, {
    params: {
     page:page,
      limit:20,
    },
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const getNotificationCount = () => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/notifications/count`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const deleteNotification = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.delete(`${apiURL}/notifications/${data}`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};


const updateNotificationApi = (data={}) => {
  const accessTokan = localStorage.getItem("userAuth");
  let payload = { ...data };
  delete payload._id;

  const config = {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  };

  let url = `${apiURL}/notifications/${data._id}`;
return axios.put(url, payload, config);
};

const updateDeviceTokenApi = (deviceToken = {}, userAuth) => {
  return axios.put(
    `${apiURL}/person/profileUpdate`,
    {
      deviceToken: deviceToken,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${userAuth}`,
      },
    }
  );
};

const updateCustomerSubscription = (data = {}) => {
  return axios.put(
    `${apiURL}/customer-desk`,
    {
      ...data,
    },
    {
      headers: {
        accept: "application/json"
      },
    }
  );
};

// for getting the user's location request
const getLocation = (location) => {
  return axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=AIzaSyBCib14ItJePQD0eIIfppZvrl59fAL8S_M`,
    {}
  );
};


const getHelpData = (data,search) => {
  let accessTokan = localStorage.getItem("userAuth");
  if(search.length > 0){
    return axios.get(`${apiURL}/help?section=${data}&platForm=WEB_APP&search=${search}&limit=15`,{
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    })
  }else{
    return axios.get(`${apiURL}/help?section=${data}&platForm=WEB_APP&limit=15`,{
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    })
  }
}

const getAppVersionRequest = () => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/getWebVersion`,{
    headers:{
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    }
  })
}

const updateAppVersionRequest = () => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/updatePersonOnWebVersionUpdate`,null,{
    headers:{
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    }
  })
}

export const AppDataApi = {
  getNotification,
  updateNotificationApi,
  updateDeviceTokenApi,
  getNotificationCount,
  deleteNotification,
  getLocation,
  getHelpData,
  updateCustomerSubscription,
  getAppVersionRequest,
  updateAppVersionRequest,
};
