import { Col, Row, Space } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CreateCustomerDrawer } from '../../DocumentSection/components/CreateCustomerDrawer'
import { documentAction } from '../../DocumentSection/state/actions'
import { PlusOutlined, FileTextOutlined, HomeOutlined, UserOutlined } from "@ant-design/icons";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { accountHandle } from '../../Account/state/actions'
import axios from 'axios'
import styles from "../style/CustomFileIcon.module.css"
import Transactions from '../../PropertyComp/components/Transactions'

const HomeOptions = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { drawerVisible } = useSelector((state) => state.documentSection.CorpReducer);
    const { getUserDetailsData, getLicenseData } = useSelector((state) => state.account);
    const filteredDelegate = !!getUserDetailsData?.delegateId?._id ? getUserDetailsData?.delegatesAssigned?.filter((item) => String(item?.personId) === String(getUserDetailsData?.delegateId?._id)) : [];
    const isDelegateHasLimitedAccess = filteredDelegate?.[0]?.specificTransaction || false;
    const CustomFileAddIcon = ({origin}) => {
        return (
            <div style={{background:"#19376D", padding:"12px", borderRadius:"10px"}}>
                <div className={styles.file_icon_wrapper}>
                    {origin === "offer" ? <FileTextOutlined className={styles.file_icon} /> : origin === "listing" ? <HomeOutlined className={styles.file_icon} /> : <UserOutlined className={styles.file_icon} />}
                    <PlusOutlined className={styles.plus_icon} />
                </div>
          </div>
        );
    };

    let unmounted = {
        value: false
    };
    let source = axios.CancelToken.source();

    const handleCreateContact = () => {
        dispatch(documentAction.setCorpState({ drawerVisible: true }));
    }
    const drawerClose = () => {
        dispatch(documentAction.clearCustomerData());
        dispatch(documentAction.setCorpState({ drawerVisible: false }));
    };

    const handleTransactions = () => {
        history.push({
            pathname:"/transactions",
        });
    }

    const handleCreateListing = () => {
        history.push({
            pathname:"/transactions",
            state:{modalState: {isListingModal:true}}
        });
    }

    const handleCreateOffer = () => {
        history.push({
            pathname:"/transactions",
            state:{modalState: {isOfferModal:true}}
        });
    }

    useEffect(() => {
        if(!getLicenseData){
          dispatch(accountHandle.getAllLicense({source,unmounted}))
          return () => {
            unmounted.value = true;
            source.cancel("Cancelling in cleanup");
          }
        }
      },[])

  return (
    <>
      <CreateCustomerDrawer visible={drawerVisible} onClose={drawerClose} nameData="contact" />

      <Space direction="vertical" style={{ margin: "20px 0 0 5px", width: "100%", overflow: "hidden" }}>
        <Col>
          {isDelegateHasLimitedAccess ? null : (
            <>
              <Row align={"middle"} style={{ marginBottom: "15px", cursor: "pointer", color: "#19376D" }} onClick={handleCreateOffer}>
                <div style={{ display: "flex", gap: "30px" }}>
                  <CustomFileAddIcon origin={"offer"} />
                  <span style={{ fontSize: "21px", fontWeight: "bold", marginTop: "12px" }}>Create a new offer</span>
                </div>
              </Row>

              <Row align={"middle"} style={{ marginBottom: "15px", cursor: "pointer", color: "#19376D" }} onClick={handleCreateListing}>
                <div style={{ display: "flex", gap: "30px" }}>
                  <CustomFileAddIcon origin={"listing"} />
                  <span style={{ fontSize: "21px", fontWeight: "bold", marginTop: "12px" }}>Create a new listing</span>
                </div>
              </Row>
            </>
          )}
          <Row align={"middle"} style={{ marginBottom: "5px", cursor: "pointer", color: "#19376D" }} onClick={handleCreateContact}>
            <div style={{ display: "flex", gap: "30px" }}>
              <CustomFileAddIcon origin={"contact"} />
              <span style={{ fontSize: "21px", fontWeight: "bold", marginTop: "12px" }}>Create a new contact</span>
            </div>
          </Row>
        </Col>
      </Space>
      {/* need discussion */}
      <div style={{ fontWeight: "bold", textDecoration: "underline", color: "#1990ff", fontSize: "22px", textAlign: "center", margin: "20px 0 10px 0", cursor: "pointer" }} onClick={handleTransactions}>
        Recently Updated Transactions
      </div>

      <div style={{ marginBottom: "20px", cursor: "pointer", color: "#19376D" }}>
        <Transactions section="HOME" isDelegateHasLimitedAccess={isDelegateHasLimitedAccess} readOnly={{ home: true }} />
      </div>
    </>
  );
}

export default HomeOptions
