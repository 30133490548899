/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Modal } from "antd";
import { FolderOpenOutlined, FileImageOutlined, FileTextOutlined } from "@ant-design/icons";
import { FaRegFilePdf } from "react-icons/fa";
import moment from "moment";
import { documentAction } from "../../DocumentSection/state/actions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import { transactionLaneDataHandle } from "../state/actions";
import axios from "axios";

const SharedModel = ({ setshowModal, showmodal, section, client }) => {
  const delegate = section === "DELEGATE";
  const { getIndividualTransactionData, getPropertyDocsData, getPropertyDocsLoading, getPropertyDocsError } = useSelector((state) => state.rtdTransactionLane);
  const { userId } = useSelector((state) => state.account);
  const location = useLocation();
  const dispatch = useDispatch();

  let source = axios.CancelToken.source();
  let unmounted = {
    value: false,
  };
  useEffect(() => {
    if (showmodal && getIndividualTransactionData?.offerId) {
      const delegateSide = delegate && client;
      dispatch(transactionLaneDataHandle.getPropertyDocs({ offerId: getIndividualTransactionData?.offerId, unmounted, source, delegateSide, delegateOwnerId: delegateSide &&getIndividualTransactionData?.currentAgentId._id }));

      return () => {
        unmounted.value = true;
        source.cancel("Cancelling in cleanup");
      };
    }
  }, [showmodal, getIndividualTransactionData?.offerId]);

  const handleOPenDocs = (ele) => {
    if (ele?.fileUrl) {
      window.open(ele?.fileUrl);
    }
  };

  const handlePropertyDocs = (ele) => {
    let buildData = {
      builtForId: userId,
      key: userId,
      builtForSection: "DOCUMENT",
      signable: true,
      openFor: "SELLER_AGENT",
      contractId: ele.contractId,
      documentId: ele?.documentId,
      token: localStorage.getItem("userAuth"),
    };
    dispatch(documentAction.draftDocumentURl(buildData, location?.pathname));
  };

  return (
    <Modal visible={showmodal} onOk={() => {}} footer={false} width={"800px"} onCancel={() => setshowModal(false)}>
      <div style={{ height: "60vh", overflowY: "auto" }}>
        <div
          style={{
            fontWeight: "500",
            fontSize: "20px",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            color: "#096dd9",
          }}
        >
          <div
            style={{
              // border: "1px solid black",
              display: "flex",
              border: "none",
              justifyContent: "center",
            }}
          >
            <div style={{ marginRight: "10px", color: "#2976be" }}>
              <FolderOpenOutlined />
            </div>
          </div>
          Shared
        </div>

        <hr />
        <div style={{ height: "50vh", overflowY: "auto" }}>
          {getPropertyDocsLoading ? (
            <div style={{ height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Spin size="large" />
            </div>
          ) : getPropertyDocsError ? (
            ""
          ) : (
            <>
              {getPropertyDocsData?.length > 0 ? (
                <table className="licenseTable">
                  <thead>
                    <tr>
                      <th> File Name</th>
                      <th>Shared Date</th>
                      <th> Shared By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getPropertyDocsData?.map((ele) => (
                      <tr key={ele._id}>
                        <td
                          onClick={() => (ele.fileType === "IMAGE" || ele.fileType === "PDF" ? handleOPenDocs(ele) : handlePropertyDocs(ele))}
                          style={{
                            fontWeight: "bolder",
                            color: "blue",
                            minWidth: "250px",
                            width: "55%",
                            cursor: "pointer",
                          }}
                        >
                          {ele.fileType === "IMAGE" ? <FileImageOutlined style={{ fontSize: "20px", color: "#ecd540", marginRight: "5px" }} /> : ele.fileType === "PDF" ? <FaRegFilePdf style={{ fontSize: "20px", color: "red", marginRight: "5px" }} /> : <FileTextOutlined style={{ fontSize: "20px", color: "#74b3ed", marginRight: "5px" }} />}
                          {ele.offerDocument ? ele.documentName : ele?.fileName || ""}
                        </td>
                        <td
                          style={{
                            color: "black",
                            fontWeight: "500",
                            textAlign: "center",
                            minWidth: "150px",
                            width: "25%",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {ele.sentDateMili ? moment(ele.sentDateMili).format("MM/DD/YYYY hh:mm A") : ""}
                        </td>
                        <td
                          style={{
                            color: "grey",
                            fontSize: "15px",
                            fontWeight: "400",
                            textAlign: "center",
                            minWidth: "150px",
                            width: "25%",
                          }}
                        >
                          {ele?.sharedBy?.fullName || ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </>
          )}{" "}
        </div>
      </div>
    </Modal>
  );
};

export default SharedModel;
