export const ERTSConstants = {
  POST_PDF_FILE_LOADING: "POST_PDF_FILE_LOADING",
  POST_PDF_FILE_ERROR: "POST_PDF_FILE_ERROR",
  POST_PDF_FILE_SUCCESS: "POST_PDF_FILE_SUCCESS",

  UPLOAD_ERTS_DOC_LOADING: "UPLOAD_ERTS_DOC_LOADING",
  UPLOAD_ERTS_DOC_ERROR: "UPLOAD_ERTS_DOC_ERROR",
  UPLOAD_ERTS_DOC_SUCCESS: "UPLOAD_ERTS_DOC_SUCCESS",

  RESET_ERTS_DATA: "RESET_ERTS_DATA",
};
