import { Modal } from "antd";
import React from "react";
import { CheckCircleFilled, SnippetsOutlined, CopyOutlined } from "@ant-design/icons";
import styles from "../../Rtd/style/rtd.module.css";
const RtdLegend = ({ showlegend, setShowLegend, seller }) => {
  return (
    <Modal
      visible={showlegend}
      width={700}
      onCancel={() => {
        setShowLegend(false);
      }}
      style={{}}
      footer={false}
    >
      <div style={{ height: "70vh", overflow: "auto", margingTop: "20px" }}>
        <table
          style={{
            width: "100%",
            padding: "5%",
            fontWeight: 600,
            border: "1px solid grey",
          }}
        >
          <thead>
            <tr
              style={{
                height: "50px",
                width: "100%",
                fontSize: "17px",
                background: "#3871b9",
                color: "white",
                border: "1px solid #d3d3d3",
              }}
            >
              <th style={{ border: "1px solid lightgray", borderRadius: "0px 0px 0px 0px" }}>Symbols</th>
              <th style={{ border: "1px solid lightgray", borderRadius: "0px 0px 0px 0px" }}>Information</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "15px", padding: "30px" }}>
            <tr className={styles.tableRow}>
              <td className={styles.symbolsColumn}>
                <div className={styles.topHalfCircle} style={{ backgroundColor: "white" }} />
              </td>
              <td className={styles.infoColumn}>The document has not been signed by any of the required clients.</td>
            </tr>

            <tr className={styles.tableRow}>
              <td className={styles.symbolsColumn}>
                <div className={styles.bottomHalfCircle} style={{ backgroundColor: "white" }} />
              </td>
              <td className={styles.infoColumn}>The document has not been signed by any of the required agents.</td>
            </tr>

            <tr className={styles.tableRow}>
              <td className={styles.symbolsColumn}>
                <div className={styles.fullCircle} style={{ backgroundColor: "white" }} />
              </td>
              <td className={styles.infoColumn}>The document has not been signed by any of the required signers.</td>
            </tr>

            <tr className={styles.tableRow}>
              <td className={styles.symbolsColumn}>
                <div className={styles.topHalfCircle} style={{ backgroundColor: "yellow" }} />
              </td>
              <td className={styles.infoColumn}>The document has been partially signed by the required clients.</td>
            </tr>

            <tr className={styles.tableRow}>
              <td className={styles.symbolsColumn}>
                <div className={styles.bottomHalfCircle} style={{ backgroundColor: "yellow" }} />
              </td>
              <td className={styles.infoColumn}>The document has been partially signed by the required agents.</td>
            </tr>

            <tr className={styles.tableRow}>
              <td className={styles.symbolsColumn}>
                <div className={styles.fullCircle} style={{ backgroundColor: "yellow" }} />
              </td>
              <td className={styles.infoColumn}>The document has been partially signed by the required signers.</td>
            </tr>
            <tr className={styles.tableRow}>
              <td className={styles.symbolsColumn}>
                <div className={styles.topHalfCircle} style={{ backgroundColor: "green" }} />
              </td>
              <td className={styles.infoColumn}>The document has been fully signed by the required clients.</td>
            </tr>

            <tr className={styles.tableRow}>
              <td className={styles.symbolsColumn}>
                <div className={styles.bottomHalfCircle} style={{ backgroundColor: "green" }} />
              </td>
              <td className={styles.infoColumn}>The document has been fully signed by the required agents.</td>
            </tr>

            <tr className={styles.tableRow}>
              <td className={styles.symbolsColumn}>
                <div className={styles.fullCircle} style={{ backgroundColor: "green" }} />
              </td>
              <td className={styles.infoColumn}>The document has been fully signed by all required signers.</td>
            </tr>

            <tr className={styles.tableRow}>
              <td className={styles.symbolsColumn}>
                <CopyOutlined />
              </td>
              <td className={styles.infoColumn}>Copy Transaction Id</td>
            </tr>
            <tr className={styles.tableRow}>
              <td className={styles.symbolsColumn}>
                <SnippetsOutlined
                  style={{
                    color: "hsl(209deg 72% 51%)",
                    fontWeight: "bold",
                    marginLeft: "5px",
                  }}
                />
              </td>
              <td className={styles.infoColumn}>Transaction Id copied to clipboard</td>
            </tr>
            <tr className={styles.tableRow}>
              <td className={styles.symbolsColumn}>
                <CheckCircleFilled style={{ fontSize: "18px", backgroundColor: "white", borderRadius: "100px", color: "green" }} />
              </td>
              <td className={styles.infoColumn}>Document sent to opposite agent</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default RtdLegend;
