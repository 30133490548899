export const documentConstants = {
  DOCUMENT_CATEGORY_FAILURE: "DOCUMENT_CATEGORY_FAILURE",
  DOCUMENT_CATEGORY_SUCCESS: "DOCUMENT_CATEGORY_SUCCESS",

  SEARCH_BUYER_FAILURE: "SEARCH_BUYER_FAILURE",
  SEARCH_BUYER_SUCCESS: "SEARCH_BUYER_SUCCESS",

  CREATE_CUSTOMER_FAILURE: "CREATE_CUSTOMER_FAILURE",
  CREATE_CUSTOMER_LOADING: "CREATE_CUSTOMER_LOADING",
  CREATE_CUSTOMER_SUCCESS: "CREATE_CUSTOMER_SUCCESS",

  SEARCH_PROPERTY_FAILURE: "SEARCH_PROPERTY_FAILURE",
  SEARCH_PROPERTY_SUCCESS: "SEARCH_PROPERTY_SUCCESS",
  SEARCH_PROPERTY_LOADING: "SEARCH_PROPERTY_LOADING",

  RESET_CUSTOMER_DATA: "RESET_CUSTOMER_DATA",
  RESET_CREATE_CUSTOMER: "RESET_CREATE_CUSTOMER",
  RESET_SEARCH_PROPERTIES:"RESET_SEARCH_PROPERTIES",

  ON_FOCUS_DATA: "ON_FOCUS_DATA",
  UPDATE_CORP_DATA: "UPDATE_CORP_DATA",
  RESET_CORP_DATA: "RESET_CORP_DATA",
  SET_CORP_TYPE: "SET_CORP_TYPE",
  PROPERTY_SEARCH_BACK_BUTTON: "PROPERTY_SEARCH_BACK_BUTTON",

  CONTRACT_OFFER_CHECK_SUCCESS: "CONTRACT_OFFER_CHECK_SUCCESS",
  CONTRACT_OFFER_CHECK_FAILURE: "CONTRACT_OFFER_CHECK_SUCCESS",
  RESET_OFFER_CHECK_DATA: "RESET_OFFER_CHECK_DATA",

  URL_IMPORT_SUCCESS: "URL_IMPORT_SUCCESS",
  URL_IMPORT_ERROR: "URL_IMPORT_ERROR",
  URL_IMPORT_CLEAR_SUCCESS: "URL_IMPORT_CLEAR_SUCCESS",
  URL_IMPORT_ERROR_CLEAR: "URL_IMPORT_ERROR_CLEAR",
  PDF_IMPORT_ERROR: "PDF_IMPORT_ERROR",
  PDF_IMPORT_LOADING:"PDF_IMPORT_LOADING",

  CBSR_CONTRACT_ID: "CBSR_CONTRACT_ID",

  BUILD_OFFER_SHORTHAND_URL_SUCCESS: "BUILD_OFFER_SHORTHAND_URL_SUCCESS",
  BUILD_OFFER_SHORTHAND_URL_FAILURE: "BUILD_OFFER_SHORTHAND_URL_FAILURE",

  PREIVIEW_BUILD_CONTRACT_SUCCESS: "PREIVIEW_BUILD_CONTRACT_SUCCESS",
  PREIVIEW_BUILD_CONTRACT_FAILURE: "PREIVIEW_BUILD_CONTRACT_FAILURE",

  DRAFT_DOCUMENTS_LOADING: "DRAFT_DOCUMENTS_LOADING",
  DRAFT_DOCUMENTS_SUCCESS: "DRAFT_DOCUMENTS_SUCCESS",
  DRAFT_DOCUMENTS_FAILURE: "DRAFT_DOCUMENTS_FAILURE",

  BUYER_CREATE_REQUEST_SUCCESS: "BUYER_CREATE_REQUEST_SUCCESS",
  BUYER_CREATE_REQUEST_ERROR: "BUYER_CREATE_REQUEST_ERROR",

  REALTOR_CREATE_REQUEST_SUCCESS: "REALTOR_CREATE_REQUEST_SUCCESS",
  REALTOR_CREATE_REQUEST_ERROR: "REALTOR_CREATE_REQUEST_ERROR",

  ADD_PROPERTY_LOADING : "ADD_PROPERTY_LOADING",
  ADD_PROPERTY_ERROR: "ADD_PROPERTY_ERROR",
  ADD_PROPERTY_SUCCESS:"ADD_PROPERTY_SUCCESS",
  RESET_ADD_PROPERTY:"RESET_ADD_PROPERTY",

  CLEAR_FORM_PROPERTY: "CLEAR_FORM_PROPERTY",

  CLEAR_CUSTOMER_CREATE_SUCCESS_DATA: "CLEAR_CUSTOMER_CREATE_SUCCESS_DATA",

  GET_MLS_PROPERTIES_LOADING:"GET_MLS_PROPERTIES_LOADING",
  GET_MLS_PROPERTIES_SUCCESS:"GET_MLS_PROPERTIES_SUCCESS",
  GET_MLS_PROPERTIES_ERROR:"GET_MLS_PROPERTIES_ERROR",

  GET_OWNERS_DETAILS_LOADING:"GET_OWNERS_DETAILS_LOADING",
  GET_OWNERS_DETAILS_SUCCESS:"GET_OWNERS_DETAILS_SUCCESS",
  GET_OWNERS_DETAILS_ERROR:"GET_OWNERS_DETAILS_ERROR",

  SET_PDF_IMPORT_STATE:"SET_PDF_IMPORT_STATE",

  UPDATE_SAFETY_DRIVE_CLIENT_DETAILS : "UPDATE_SAFETY_DRIVE_CLIENT_DETAILS",

  CLOSE_SAFETY_DRIVE_EDIT_MODAL: "CLOSE_SAFETY_DRIVE_EDIT_MODAL"
};
