import { Button, Modal, Select,message } from 'antd';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { clientAction } from '../state/actions';

const FilterClients = ({setPage, setPersonId, isSearchModalOpen,handleSearchCancel,onFilterChange, selectedRoles, setSelectedRoles, selectedSortOrder, setSelectedSortOrder, selectedSortBy, setSelectedSortBy, isClearAllDisabled, setIsClearAllDisabled, setIsSearchModalOpen, filterNotApplied, setfilterNotApplied, listSource}) => {
  const roleHashTags = ["TITLE REP","BUYER","SELLER","STAGER","MORTGAGE","CUSTOMER","PHOTOGRAPHER","PROPERTY INSPECTOR"];
  const dispatch = useDispatch();
  const { Option } = Select;

  const handleRoleSelect = (value) => {
    setSelectedRoles(value);
    setIsClearAllDisabled(value?.length === 0 && selectedSortBy === "" && selectedSortOrder === "");
  };

  const handleSortOrderSelect = (value) => {
    setSelectedSortOrder(value);
    setIsClearAllDisabled(value?.length === 0 && selectedSortBy === "" && selectedSortOrder === "");
  };

const handleSortByChange = (value) => {
    setSelectedSortBy(value);
    setIsClearAllDisabled(value?.length === 0 && selectedSortBy === "" && selectedSortOrder === "");
  };

  const handleApplyButtonClick = () => {
    if (selectedRoles?.length === 0 && selectedSortBy === "" && selectedSortOrder === "") {
      message?.error("Please select at least one filter before applying.")
    } else {
      // dispatch(clientAction?.filterClientList({ selectedSortBy, selectedSortOrder, selectedRoles }));
      setfilterNotApplied(false);
      dispatch(clientAction.clearGetClientData());
      handleSearchCancel();
      onFilterChange(true);
      if(!listSource){
        setPersonId("");
      }
      setPage([1])
    }
  };

  const handleClearAllButtonClick=()=>{
    if(!filterNotApplied){
      dispatch(clientAction.clearGetClientData());
      if(!listSource){
        setPersonId("");
      }
      setPage([1]);
    }
    cleanupFunc();
    handleSearchCancel();
    setIsClearAllDisabled(true);
    onFilterChange(false);
    setfilterNotApplied(true);
  }

  const cleanupFunc=()=>{
      setSelectedRoles([]);
      setSelectedSortBy('');
      setSelectedSortOrder('');
  }
  

  const handleModalCancel = () => {
    if(filterNotApplied){
      setIsClearAllDisabled(true);
      cleanupFunc();
    }
    setIsSearchModalOpen(false);
  }

  return (
    <Modal footer={null} title={<span style={{display:"flex",justifyContent:"center",color: 'rgb(61, 124, 183)', textDecoration: 'underline'}}>FILTER CONTACTS</span>} open={isSearchModalOpen} onCancel={handleModalCancel}>
    <div style={{display:"flex",justifyContent:"space-between",}}>
    <div style={{width:"30%"}}>
        <h4>Hashtag</h4>
     <Select
     style={{width:"100%",overflow:"auto"}}
        mode="multiple"
        placeholder="Select Hashtag"
        onChange={handleRoleSelect}
        value={selectedRoles}
      >
        {roleHashTags.map((role) => (
          <Option key={role} value={role}>
            {role}
          </Option>
        ))}
      </Select>
      </div>

      <div style={{width:"30%"}}>
        <h4>Sort By</h4>
      <Select
      style={{width:"100%"}}
        placeholder="Select sorting criteria"
        onChange={handleSortByChange}
        value={selectedSortBy}
      >
        <Option value="createdAt">Created At</Option>
        <Option value="updatedAt">Updated At</Option>
        <Option value="fullName">Full Name</Option>
      </Select>
      </div>

      <div style={{width:"25%"}}>
        <h4>Order</h4>
      <Select
      style={{width:"100%"}}
        placeholder="Select sorting order"
        onChange={handleSortOrderSelect}
        value={selectedSortOrder}
      >
        <Option value="ASC">Ascending</Option>
        <Option value="DESC">Descending</Option>
      </Select>
      </div>

      </div>
      <div style={{ marginTop: '16px', textAlign: 'right' }}>
      <hr />
      <Button style={{marginLeft:"10px"}} type='primary' onClick={handleClearAllButtonClick} disabled={isClearAllDisabled}>Clear All</Button>
        <Button style={{marginLeft:"10px"}} type="primary" onClick={handleApplyButtonClick}>Apply</Button>
  </div>
      </Modal>
  )
}

export  {FilterClients};
