import { breadcrumConstants } from "./action-types";

const initialState = {
  routes: [
    {
      path: `/`,
      breadcrumbName: `""`,
    },
  ],
};

const breadcrum = (state = initialState, action) => {
  switch (action.type) {
    case breadcrumConstants.UPDATE_NAVBAR_TITLE:
      return {
        ...state,
        routes: action.breadcrum_title,
      };
    default:
      return state;
  }
};
export { breadcrum };
