import React, { useEffect, useState } from 'react';
import {  useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Image, Modal, Select, Table, Typography } from 'antd';
import { Loading } from '../../Common/components/Loading';
import { EmptyData } from '../../Common/components/EmptyData';
import TextArea from 'antd/lib/input/TextArea';
import { toolsAction } from '../../Tools/state/actions';
import axios from 'axios';
import { accountHandle } from '../../Account/state/actions';
import { EyeOutlined } from "@ant-design/icons";
import SubjectLine from '../../PropertyComp/components/SubjectLine';

const { Option } = Select;


const SendTitleCompany = ({ selectedtext, setSelectedText, data, setData,emailSignatureId, setEmailSignatureId, subjectForm }) => {
    const [Email_templatevisible,setEmail_templatevisible]=useState(false);
    const [ispreview, setIspreview] = useState(false);
    const { emailSignData, emailSignLoading } = useSelector(
        (state) => state.account,
      );

    const dispatch = useDispatch();

    const handleInput = (event) => {
        setData({
        ...data,
        emailMessage: event.target.innerHTML,
        });
    };

    const tableTitle = (name) => {
        return <Typography.Text style={{ fontSize: "20px", color: "white" }}>{name}</Typography.Text>;
    };
    const columns = [
        {
        title: tableTitle("Name"),
        dataIndex: 'name',
        key: 'name',

        },
        {
        title: tableTitle("Email"),
        dataIndex: 'email',
        key: 'email',
        },
    ];

    const handleCheckboxChange = (newSelectedRowKeys, selectedRows) => {
        setData({ ...data, selectedPerson: newSelectedRowKeys || []});
    }
    const handleEmail_templateCancel=()=>{
        setEmail_templatevisible(false)
    }
    const rowSelection = {
        selectedRowKeys: data?.selectedPerson || [],
        onChange: handleCheckboxChange,
    };

    const handleselectemail = (value) => {
        setSelectedText(value);
        setData({
        ...data,
        emailMessage: value,
        });
        setEmail_templatevisible(false);
    };

    const handleSelectChange =(id)=>{
        let [filteredValue] = emailSignData?.filter((el)=>el?._id===id).map((el)=>{return {...el, isDefault:true}});
        setEmailSignatureId(filteredValue)
     }

     let unmounted = {
        value: false
      };
      let source = axios.CancelToken.source();

    useEffect(() => {
      dispatch(accountHandle.getEmailSignData({unmounted, source}));

      return () => {
              unmounted.value = true;
              source.cancel("Cancelling in cleanup");
            }
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
          const defaultSignVal = emailSignData?.find(sign => sign.isDefault === true) || { isDefault: false, _id: '' };
          setEmailSignatureId({ ...defaultSignVal });
        }, 100);

        return () => {
          clearTimeout(timeoutId);
        };
      }, [emailSignData]);

    return (
        <>
         <Modal width={'800px'} visible={ispreview} footer={false} onCancel={()=>setIspreview(false)}>
              {emailSignatureId &&
                  <div
                    style={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                      width: "100%",
                      borderRadius: "5px",
                      padding: "10px",
                      margin: "auto",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div>
                        <span
                          style={{
                            color: "gray",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          Name: {emailSignatureId?.title}
                        </span>
                      </div>
                     </div>
                     <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          textAlign: "left",
                          lineHeight: "0.8",
                          marginTop:"5px"
                        }}
                      >
                        {emailSignatureId?.footerText && (
                          <div dangerouslySetInnerHTML={{ __html: emailSignatureId.footerText }} />
                        )}
                      </p>

                    <div style={{display:"flex", justifyContent:"center",minHeight:"300px"}}>
                    {emailSignatureId?.signatureImageUrl && (
                      <Image
                        src={emailSignatureId?.signatureImageUrl}
                        alt="Signature"
                        loading="lazy"
                      />
                    )}
                    </div>
                  </div>
                }

      </Modal>
        <Table
            rowSelection={{
            type: "checkbox",
            ...rowSelection,
            getCheckboxProps: (record) => {},
            }}
            dataSource={data?.personData || []} columns={columns} pagination={false}
        />

        <SubjectLine form={subjectForm}/>

        <div style={{ marginTop: "15px" }}>
            <div
            style={{
                display: "flex",
                justifyContent: "space-between",
            }}
            >
            <h3
                style={{
                marginBottom: "3px",
                paddingBottom: 0,
                }}
            >
                Please Enter your message
            </h3>
            <button
                style={{
                borderRadius: "8px",
                marginBottom: "10px",
                cursor: "pointer",
                backgroundColor: "#2171b9",
                color: "white",
                border: "none",
                }}
                onClick={() => {
                    setEmail_templatevisible(true);
                }}
            >
                Email Template
            </button>
            </div>
            <div style={{ height: "150px", border: "1px solid grey" }}>
            {selectedtext ? (
                <div
                contentEditable={true}
                onInput={handleInput}
                dangerouslySetInnerHTML={{ __html: selectedtext }}
                style={{
                    textOverflow: "ellipsis",
                    overflowY: "auto",
                    height: "100%",
                    padding: "10px",
                }}
                />
            ) : (
                <TextArea
                style={{
                    textOverflow: "ellipsis",
                    overflowY: "auto",
                    height: "100%",
                    padding: "10px",
                }}
                placeholder="Enter message..."
                value={data?.emailMessage}
                onChange={(e) => {
                    setData({
                    ...data,
                    emailMessage: e.target.value,
                    });
                }}
                />
            )}
            </div>
        </div>

        <div style={{ marginTop: "10px" }}>
        <Checkbox
            checked={emailSignatureId.isDefault}
            onChange={(e) => setEmailSignatureId({ ...emailSignatureId, isDefault: e.target.checked })}
        >
            Include Email Signature
        </Checkbox>
        <Select
            value={emailSignatureId._id} // Use value instead of defaultValue
            style={{ width: 200 }}
            loading={emailSignLoading}
            onChange={(value) => handleSelectChange(value)}
            disabled={!emailSignatureId.isDefault}
        >
            {emailSignData && emailSignData?.length && emailSignData?.map((el, ind) => (
            <Option key={ind} value={el?._id}>{el?.title}</Option>
            ))}
        </Select>
        {emailSignatureId?.isDefault && (<Button style={{fontSize:"16px"}} icon={<EyeOutlined />} onClick={()=>setIspreview(true)} type="link">Preview</Button>)}
        </div>

        <EmailTempModal Email_templatevisible={Email_templatevisible} handleEmail_templateCancel={handleEmail_templateCancel} handleselectemail={handleselectemail}/>
        </>
    );
};

const EmailTempModal = ({Email_templatevisible, handleEmail_templateCancel, handleselectemail}) => {
    const { getEmail_TemplateData, getEmail_TemplateLoading, getEmail_TemplatesError, getEmail_TemplateErrorMsg } = useSelector((state) => state.ToolReducers.DocumentReducer);
    const dispatch = useDispatch();

    let unmounted = {
        value: false
    };
    let source = axios.CancelToken.source();
    useEffect(() => {
        if(Email_templatevisible){
            dispatch(toolsAction.getEmail_Template({unmounted, source}));
        }

        return () => {
            unmounted.value = true;
            source.cancel("Cancelling in cleanup");
        }
    }, [Email_templatevisible]);

    return <Modal
            visible={Email_templatevisible}
            onCancel={handleEmail_templateCancel}
            footer={false}
            width={800}
            size="100px"
        >
            {getEmail_TemplateLoading ? (
            <div
                style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: "70px",
                }}
            >
                <Loading />
            </div> )
            : getEmail_TemplatesError ? ""
            : (
                <div
                    style={{
                    width: "100%",
                    overflowY: "auto",
                    overflowX: "hidden",
                    backgroundColor: "#fff",
                    position: "relative",
                    zIndex: "30",
                    marginTop: "20px",
                    }}
                >
                    {getEmail_TemplateData?.length > 0 ? (
                    <div style={{ maxHeight: "500px", overflowy: "auto" }}>
                        <table
                        className="licenseTable"
                        style={{
                            marginTop: "-5px",
                        }}
                        >
                        <thead
                            style={{
                            position: "sticky",
                            top: -2,
                            left: "0px",
                            zIndex: 100,
                            height: "50px",
                            }}
                        >
                            <tr>
                            <th>Title</th>
                            <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getEmail_TemplateData?.map((el, i) => (
                            <tr
                                key={el._id}
                                style={{ marginTop: "20px", cursor: "pointer" }}
                                onClick={() => handleselectemail(el.name)}
                            >
                                <td
                                style={{
                                    fontWeight: "bolder",
                                    width: "20%",
                                    textAlign: "center",
                                }}
                                >
                                {el.title}
                                </td>
                                <td
                                style={{
                                    height: "100px",
                                    width: "60%",
                                    maxWidth: "400px",
                                }}
                                >
                                <div
                                    dangerouslySetInnerHTML={{ __html: el.name }}
                                    style={{
                                    textOverflow: "ellipsis",
                                    overflowY: "auto",
                                    maxHeight: "200px",
                                    }}
                                />
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                    ) : (
                    <div
                        style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80%",
                        textAlign: "center",
                        }}
                    >
                        <EmptyData />
                    </div>
                    )}
                </div>
            )}
        </Modal>
}

export default SendTitleCompany;
