import { Button, Col, Form } from "antd";
import React from "react";

const BuildDocButton = ({ progress, handleBuildButtonClick, delegate }) => {
  return (
    <>
      <Col xs={24} sm={24} md={24} lg={5} xl={5}>
        <Form.Item>
         {/* need to add the loader on the build button */}
          <Button style={{ width: "100%", minWidth: "130px" }} type="primary" onClick={handleBuildButtonClick} disabled={delegate ? progress !== 102 : progress !== 100}>
            Build Document
          </Button>
        </Form.Item>
      </Col>
    </>
  );
};

export { BuildDocButton };
