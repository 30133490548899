export const accountPageConstants = {
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_FAILURE: "UPLOAD_IMAGE_FAILURE",

  USER_PROFILE_SUCCESS: "USER_PROFILE_SUCCESS",
  USER_PROFILE_FAILURE: "USER_PROFILE_FAILURE",

  GET_FRANCHISE_DETAILS_LOADING: "GET_FRANCHISE_DETAILS_LOADING",
  GET_FRANCHISE_DETAILS_SUCCESS: "GET_FRANCHISE_DETAILS_SUCCESS",
  GET_FRANCHISE_DETAILS_FAILURE: "GET_FRANCHISE_DETAILS_FAILURE",

  GET_BROKRAGE_DETAILS_LOADING: "GET_BROKRAGE_DETAILS_LOADING",
  GET_BROKRAGE_DETAILS_SUCCESS: "GET_BROKRAGE_DETAILS_SUCCESS",
  GET_BROKRAGE_DETAILS_FAILURE: "GET_BROKRAGE_DETAILS_FAILURE",

  GET_OFFICE_DETAILS_LOADING: "GET_OFFICE_DETAILS_LOADING",
  GET_OFFICE_DETAILS_SUCCESS: "GET_OFFICE_DETAILS_SUCCESS",
  GET_OFFICE_DETAILS_FAILURE: "GET_OFFICE_DETAILS_FAILURE",

  GET_USER_DETAILS_LOADING: "GET_USER_DETAILS_LOADING",
  GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_FAILURE: "GET_USER_DETAILS_FAILURE",

  UPDATE_USER_PASSWORD_SUCCESS: "UPDATE_USER_PASSWORD_SUCCESS",
  UPDATE_USER_PASSWORD_FAILURE: "UPDATE_USER_PASSWORD_FAILURE",

  EDIT_NAME_SUCCESS: "EDIT_NAME_SUCCESS",
  EDIT_NAME_FAILURE: "EDIT_NAME_FAILURE",

  UPDATE_ADDRESS_SUCCESS: "UPDATE_ADDRESS_SUCCESS",
  UPDATE_ADDRESS_FAILURE: "UPDATE_ADDRESS_FAILURE",

  RESET_ACCOUNT_PAGE: "RESET_ACCOUNT_PAGE",
  CLIENT_ADDRESS_RESET: "CLIENT_ADDRESS_RESET",
  CLIENT_NAME_RESET: "CLIENT_NAME_RESET",
  RESET_UPDATE_PASSWORD: "RESET_UPDATE_PASSWORD",

  CREATE_LICENCE_LOADING: "CREATE_LICENCE_LOADING",
  CREATE_LICENCE_SUCCESS: "CREATE_LICENCE_SUCCESS",
  CREATE_LICENCE_FAILURE: "CREATE_LICENCE_FAILURE",

  GET_LICENCE_LOADING: "GET_LICENCE_LOADING",
  GET_LICENCE_SUCCESS: "GET_LICENCE_SUCCESS",
  GET_LICENCE_FAILURE: "GET_LICENCE_FAILURE",

  UPDATE_LICENCE_LOADING: "UPDATE_LICENCE_LOADING",
  UPDATE_LICENCE_SUCCESS: "UPDATE_LICENCE_SUCCESS",
  UPDATE_LICENCE_FAILURE: "UPDATE_LICENCE_FAILURE",

  DELETE_LICENCE_LOADING: "DELETE_LICENCE_LOADING",
  DELETE_LICENCE_SUCCESS: "DELETE_LICENCE_SUCCESS",
  DELETE_LICENCE_FAILURE: "DELETE_LICENCE_FAILURE",

  GET_USER_SAVED_NOTES_SUCCESS: "GET_USER_SAVED_NOTES_SUCCESS",

  GET_USER_ACCESS_LOADING: "GET_USER_ACCESS_LOADING",
  GET_USER_ACCESS_ERROR: "GET_USER_ACCESS_ERROR",
  GET_USER_ACCESS_DATA: "GET_USER_ACCESS_DATA",

  WHOLE_APP_LOADING: "WHOLE_APP_LOADING",
  WHOLE_APP_ERROR: "WHOLE_APP_ERROR",

  GET_LOG_LOADING: "GET_LOG_LOADING",
  GET_LOG_ERROR: "GET_LOG_ERROR",
  GET_LOG_DATA: "GET_LOG_DATA",

  GET_SAFE_T_DRIVE_LOADING: "GET_SAFE_T_DRIVE_LOADING",
  GET_SAFE_T_DRIVE_SUCCESS: "GET_SAFE_T_DRIVE_SUCCESS",
  GET_SAFE_T_DRIVE_FAILURE: "GET_SAFE_T_DRIVE_FAILURE",

  CREATE_SAFE_T_DRIVE_LOADING: "CREATE_SAFE_T_DRIVE_LOADING",
  CREATE_SAFE_T_DRIVE_SUCCESS: "CREATE_SAFE_T_DRIVE_SUCCESS",
  CREATE_SAFE_T_DRIVE_FAILURE: "CREATE_SAFE_T_DRIVE_FAILURE",

  UPDATE_SELECTED_SAFE_T_DRIVE_LOADING: "UPDATE_SELECTED_SAFE_T_DRIVE_LOADING",
  UPDATE_SELECTED_SAFE_T_DRIVE_SUCCESS: "UPDATE_SELECTED_SAFE_T_DRIVE_SUCCESS",
  UPDATE_SELECTED_SAFE_T_DRIVE_FAILURE: "UPDATE_SELECTED_SAFE_T_DRIVE_FAILURE",

  SEARCH_SAFE_T_DRIVE_LOADING: "SEARCH_SAFE_T_DRIVE_LOADING",
  SEARCH_SAFE_T_DRIVE_SUCCESS: "SEARCH_SAFE_T_DRIVE_SUCCESS",
  SEARCH_SAFE_T_DRIVE_FAILURE: "SEARCH_SAFE_T_DRIVE_FAILURE",

  GET_AWS_SAFE_T_DRIVE_SUCCESS: "GET_AWS_SAFE_T_DRIVE_SUCCESS",

  REMOVE_AWS_DATA_SAFE_T_DRIVE_SUCCESS: "REMOVE_AWS_DATA_SAFE_T_DRIVE_SUCCESS",

  UPDATE_SEARCHED_TEXT_SUCCESS: "UPDATE_SEARCHED_TEXT_SUCCESS",
  UPDATE_DATE_SEARCH_SUCCESS: "UPDATE_DATE_SEARCH_SUCCESS",
  UPDATE_SORTBY_SUCCESS: "UPDATE_SORTBY_SUCCESS",

  GET_DELEGATE_LIST_LOADING: "GET_DELEGATE_LIST_LOADING",
  GET_DELEGATE_LIST_SUCCESS: "GET_DELEGATE_LIST_SUCCESS",
  GET_DELEGATE_LIST_ERROR: "GET_DELEGATE_LIST_ERROR",

  GET_PARTIAL_DATA_LOADING: "GET_PARTIAL_DATA_LOADING",
  GET_PARTIAL_DATA_SUCCESS: "GET_PARTIAL_DATA_SUCCESS",
  GET_PARTIAL_DATA_ERROR: "GET_PARTIAL_DATA_ERROR",

  CLEAR_DELEGATES_LIST:"CLEAR_DELEGATES_LIST",

  GET_DELEGATE_DATA_LOADING: "GET_DELEGATE_DATA_LOADING",
  GET_DELEGATE_DATA_SUCCESS: "GET_DELEGATE_DATA_SUCCESS",
  GET_DELEGATE_DATA_ERROR: "GET_DELEGATE_DATA_ERROR",

  UPDATE_DELEGATE_SUCCESS: "UPDATE_DELEGATE_SUCCESS",

  RESET_DELEGATE_DATA:"RESET_DELEGATE_DATA",

  UPLOAD_EMAIL_SIGN_LOADING:"UPLOAD_EMAIL_SIGN_LOADING",
  UPLOAD_EMAIL_SIGN_SUCCESS:"UPLOAD_EMAIL_SIGN_SUCCESS",
  UPLOAD_EMAIL_SIGN_ERROR:"UPLOAD_EMAIL_SIGN_ERROR",

  GET_EMAIL_SIGN_DATA_LOADING: "GET_EMAIL_SIGN_DATA_LOADING",
  GET_EMAIL_SIGN_DATA_SUCCESS:"GET_EMAIL_SIGN_DATA_SUCCESS",
  GET_EMAIL_SIGN_DATA_ERROR:"GET_EMAIL_SIGN_DATA_ERROR",

  DELETE_EMAIL_SIGN_LOADING: "DELETE_EMAIL_SIGN_LOADING",
  DELETE_EMAIL_SIGN_SUCCESS:"DELETE_EMAIL_SIGN_SUCCESS",
  DELETE_EMAIL_SIGN_ERROR:"DELETE_EMAIL_SIGN_ERROR",

  EDIT_EMAIL_SIGN_LOADING: "EDIT_EMAIL_SIGN_LOADING",
  EDIT_EMAIL_SIGN_SUCCESS:"EDIT_EMAIL_SIGN_SUCCESS",
  EDIT_EMAIL_SIGN_ERROR:"EDIT_EMAIL_SIGN_ERROR",

  EDIT_BROKERAGE_LOADING: "EDIT_BROKERAGE_LOADING",
  EDIT_BROKERAGE_ERROR: "EDIT_BROKERAGE_ERROR",
  EDIT_BROKERAGE_SUCCESS: "EDIT_BROKERAGE_SUCCESS",

  GET_SAFE_T_DRIVE_ERROR: "GET_SAFE_T_DRIVE_ERROR"
};
