import { deleteAccountConstants } from "./action-types";

const initialState = {
  deleteAccountLoading: false,
  deleteAccountError: false,
  deleteAccountMessage: "",
};

const deleteAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case deleteAccountConstants.DELETE_ACCOUNT_LOADING:
      return {
        ...state,
        deleteAccountLoading: true,
      };
    case deleteAccountConstants.DELETE_ACCOUNT_ERROR:
      return {
        ...state,
        deleteAccountLoading: false,
        deleteAccountError: true,
      };
    case deleteAccountConstants.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteAccountLoading:false,
        deleteAccountError:false,
        deleteAccountMessage:action.deleteAccountMessage
      }
    default:
      return state;
  }
};

export { deleteAccountReducer };
