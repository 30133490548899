import React from "react";
import { Loading } from "../../Common/components/Loading";
import {
  Button,
  Form,
  Input,
  Typography,
  Select,
  Image,
} from "antd";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { documentAction } from "../state/actions";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import "../style/DocumentForm.css";
import { useMediaQuery } from "react-responsive";

const { Title } = Typography;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const PropertySearchModal = ({
  fieldEmpty,
  skip,
  setSkip,
  limit,
  setAddressData
}) => {
  const { getPropertySearchLoading } = useSelector(
    (state) => state.documentSection.PropertySearchReducer
  );
  const { demoMode } = useSelector((state) => state.app);
  const [form] = Form.useForm();
  const [switchChange, setSwitchChange] = useState(false);
  const [data, setData] = useState("");
  const [toggleId, setToggleId] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });

  let handleSwitchChange = (e) => {
    setSwitchChange(!switchChange);
  };
  let handleOnfocusChange = (object) => {
    dispatch(documentAction.onFocusData(object));
  };
  let handleOnBlurChange = (object) => {
    dispatch(documentAction.onFocusData(object));
  };

  const autoSearchProperty = () => {
    const directionRegex =
      /\b(?:north|south)(?:\h*(?:east|west))?\b|\b(?:east|west|[NS][WE]?|[EW])\b/i;

    const getStreetName = (term) => {
      let tokens = term.trim().split(" ");
      // test for directions, if direction is present remove select second word other select first word
      let streetName = directionRegex.test(tokens[0]) ? tokens[1] : tokens[0];
      return streetName;
    };
    if (switchChange && data) {
      const address = data["label"];
      const parts = address?.split(", ");
      const streetAddress = parts[0];
      const streetParts = streetAddress.split(" ");

      const streetNumber = streetParts[0];
      const streetName = streetParts.slice(1).join(" ");

      const city = parts[1];
      const state = parts[2];

      data["street name"] = getStreetName(streetName);
      data["street number"] = streetNumber;
      data["city"] = city;
      data["state"] = state;
      data["destination"] = "RE Colorado";
      data["autoSearch"] = true;
      data["propertyType"] = ["PRIVATE", "RECOLORADO" , "IRES", "PPAR", "PAR"];
      dispatch(documentAction.getPropertySearch(data, demoMode, skip, limit));
    }
  };

  const CustomImage = ({ src, alt, style }) => {
    const [loaded, setLoaded] = useState(false);
  
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {!loaded && <span>{alt}</span>}
        <Image
          preview={false}
          src={src}
          alt={alt}
          style={loaded ? style : { display: 'none' }}
          onLoad={() => setLoaded(true)}
          onError={() => setLoaded(true)}
          placeholder={<span>{alt}</span>}
        />
      </div>
    );
  };

  const propertySelectOption = [
    { value: "PRIVATE", label: "RE-Sure" },
    { value: "RECOLORADO", label: <CustomImage style={{ width: "110px", height: "35px" }} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/64d5ce64dff9fe6ba4cfaf06/MEMORIES/18pastedImage34369" alt="RECOLORADO" /> },
    { value: "IRES", label: <CustomImage style={{ width: "100px" }} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/64d5ce64dff9fe6ba4cfaf06/MEMORIES/18pastedImage84057" alt="IRES" /> },
    { value: "PPAR", label: <CustomImage style={{ width: "85px", height: "25px" }} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/657ff2157e660a0701cfaa24/MEMORIES/19PPMLS-logo-MAIN-WebRGB.jpeg" alt="PPAR" /> },
    { value: "PAR", label: <CustomImage style={{ width: "85px", height: "30px" }} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/qa/6444b9c521382a6780572f63/MEMORIES/2pastedImage68271" alt="PAR" /> }
  ]

  let formHandleSubmit = (data) => {
    const trimmedData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        if (typeof value === "string") {
          return [key, value?.trim()];
        } else if (typeof value === "number") {
          return [key, parseFloat(String(value)?.trim())];
        }
        return [key, value];
      })
    );
    if (switchChange) {
      autoSearchProperty();
    } else {
      setAddressData(trimmedData);
      dispatch(documentAction.getPropertySearch(trimmedData, demoMode, skip, limit));
      form.resetFields();
    }
  };

  return (
    <>
      <Title
        level={3}
        style={{
          textAlign: "center",
          marginTop: "10px",
          marginBottom: "1em",
        }}
      >
        Property Search
      </Title>
      <div
        style={{
          height: isMobile ? "" : "470px",
        }}
      >
      {getPropertySearchLoading ? (
        <Loading />
      ) : (
        <Form
          {...layout}
          form={form}
          className="formaccnt"
          name="nest-messages"
          onFinish={(data)=>formHandleSubmit(data)}
          initialValues={{
            destination: "RE Colorado",
            // propertyType:["PRIVATE","RECOLORADO","IRES","PPAR", "PAR"],
            autoSearch: "false",
            "street number": "",
            "street name": "",
          }}
          preserve={true}
          style={{ minHeight: "402px" }}
        >
          <Form.Item
            name="propertyType"
            label="Source"
            className="lbl"
            rules={[{ required: true,message:"Please Select MLS" }]}
          >
            <Select
                placeholder="Select MLS"
                showArrow
                mode="multiple"
                options={propertySelectOption}
                size="large"
                open={dropdownVisible}
                onSelect={() => setDropdownVisible(false)}
                onDropdownVisibleChange={(open) => setDropdownVisible(open)}
              />
          </Form.Item>
          {/* Legend */}
          <div
            style={{
              textAlign: "right",
              position: "absolute",
              top: "20px",
              right: "30px",
              width: "15%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              margin: "auto",
            }}
          >
            <h1 style={{ color: "red" }}>*</h1>{" "}
            <h4 style={{ lineHeight: "35px", color: "grey" }}>Required</h4>
          </div>

          {switchChange && (
            <>
              <Form.Item
                name="address"
                label="Address"
                className="lbl"
                rules={[
                  { required: true, message: "Please enter valid Address" },
                ]}
                onFocus={() =>
                  handleOnfocusChange({
                    propertyType: true,
                    propertyTypeColor: "yellow",
                  })
                }
                onBlur={() =>
                  handleOnBlurChange({
                    propertyType: fieldEmpty.propertyType === "" ? false : true,
                    propertyTypeTypeColor: "green",
                  })
                }
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <GooglePlacesAutocomplete
                    name="address"
                    selectProps={{
                      value: data,
                      onChange: setData,
                      placeholder: "Select Address",
                      onBlur: () => {
                        if (!data) {
                          form.resetFields();
                        }
                      },
                    }}
                    apiKey="AIzaSyCree4zl-bgFXMrJGuO3ROg32WeuM39HmI"
                  />
                  {data.length === 0 ? (
                    <SearchOutlined
                      style={{
                        fontSize: "25px",
                        color: "#085191",
                        zIndex:10,
                        margin: "2% 0 0 -10%",
                      }}
                    />
                  ) : null}
                  {data && (
                    <CloseOutlined
                      className="closeIcon"
                      onClick={() => {
                        form.resetFields();
                        setData("");
                      }}
                      style={{marginTop: "10px" }}
                    />
                  )}
                </div>
              </Form.Item>
            </>
          )}
          {!switchChange && (
            <>
              <Form.Item
                name="street number"
                label="Street Number"
                className="lbl"
                rules={[
                  { required: toggleId ? false : true, message: "Please Enter Street Number" },
                ]}
              >
                <Input placeholder="Please Enter Street Number"/>
              </Form.Item>
              <Form.Item
                name="street name"
                label="Street Name"
                className="lbl"
                rules={[
                  { required: toggleId ? false : true, message: "Please Enter Street Name" },
                ]}
              >
                <Input placeholder="Please Enter Street Name"/>
              </Form.Item>
              <Form.Item
                name="unit"
                label="Unit"
                className="lbl"
              >
                <Input placeholder="Please Enter Unit"/>
              </Form.Item>

              <Form.Item
                className="lbl1"
                name="city"
                label="City"
              >
                <Input placeholder="Please Enter City Name" />
              </Form.Item>
              <h2 style={{margin:"-15px 0 15px 0",textAlign:"center",color:"gray"}}>OR</h2>
              <Form.Item
                className="lbl"
                name="listingId"
                label="Listing Id"
                rules={[
                    { required: toggleId ? true : false, message: "Please Enter Listing Id" },
                  ]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="Please Enter Listing Id"
                  onChange={(e)=>setToggleId(e)}
                />
              </Form.Item>
            </>
          )}
          <Form.Item label=" " colon={false} style={{ marginBottom: "25px",marginTop:"15px" }}>
            <Button type="primary" htmlType="submit" style={{ width: isMobile ? "100%" : "50%" }} onClick={() => setSkip(0)}>
              Search
            </Button>
          </Form.Item>
          {/* <div style={{display:"flex",justifyContent:"space-evenly"}}>
            <Text
                level={4}
                style={{ marginLeft: "10px" }}
                className="search_label"
              >
                {" "}
                Basic Search
              </Text>
            <Switch
                defaultChecked={switchChange}
                onChange={handleSwitchChange}
            />
              <Text
                level={4}
                style={{ marginLeft: "10px" }}
                className="search_label"
              >
              {" "}
              Auto Search
            </Text>
          </div> */}
        </Form>
      )}
      </div>
    </>
  );
};

export { PropertySearchModal };
