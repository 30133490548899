import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button } from "antd";
import { accountHandle } from "../state/actions";

function PositionDetails({ data, selectedClient, setModelDrawer }) {
  const dispatch = useDispatch();
  const inputRef = useRef()
  const updateLocation = "Designation";
  const onFinish = (values) => {
    dispatch(accountHandle.updateName(values, selectedClient, updateLocation));
    setModelDrawer({ status: false, component: "", title: "" });
  };
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  return (
    <Form
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 16 }}
      initialValues={{
        designation:
          data.designation && data.designation !== "null"
            ? data.designation
            : "",
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="Designation"
        name="designation"
        rules={[{ required: true, message: "Please Enter designation" }]}
      >
        <Input ref={inputRef} placeholder="Enter designation" allowClear autoFocus/>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}
export { PositionDetails };
