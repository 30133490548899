import React from "react";
import { Timeline } from "antd";
import { useSelector } from "react-redux";

function TimeLine({ delegate }) {
  const { onFocusData } = useSelector(
    (state) => state.documentSection.onFocusReducer
  );
  return (
    <div>
      <Timeline
        style={{
          marginBottom: 10,
        }}
      >
        <div style={{ display: "block" }}>
       {delegate && <Timeline.Item
            color={
              onFocusData.documentType === true
                ? onFocusData.buyerTypeColor
                : "blue"
            }
            style={{ fontSize: "14px" }}
          >
            Buyer Agent
          </Timeline.Item>}

          <Timeline.Item
            color={
              onFocusData.documentType === true
                ? onFocusData.documentTypeColor
                : "blue"
            }
            style={{ fontSize: "14px" }}
          >
            Property Type
          </Timeline.Item>
          {/* <Timeline.Item color={onFocusData["template"]}>
          Template Selection
        </Timeline.Item> */}

          <Timeline.Item
            color={
              onFocusData.closingDateType === true
                ? onFocusData.closingDateTypeColor
                : "blue"
            }
          >
            Closing Date
          </Timeline.Item>
          <Timeline.Item
            color={
              onFocusData.priceType === true
                ? onFocusData.priceTypeColor
                : "blue"
            }
          >
            Price
          </Timeline.Item>
        </div>
        <Timeline.Item
          color={
            onFocusData.searchBuyerType === true
              ? onFocusData.searchBuyerTypeColor
              : "blue"
          }
        >
          Search Buyer
        </Timeline.Item>
        <Timeline.Item
          color={
            onFocusData.propertyType === true
              ? onFocusData.propertyTypeTypeColor
              : "blue"
          }
        >
          Property Search
        </Timeline.Item>
        {/* <Timeline.Item
        //  color={onFocusData["template"]}
        >
          Build Document
        </Timeline.Item> */}
      </Timeline>
    </div>
  );
}

export { TimeLine };
