/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { DeleteTwoTone } from "@ant-design/icons";
import { Divider, Popconfirm, Tabs, Tooltip, message } from "antd";
import { Button, Form, Input, Select } from "antd";
import { PlusCircleFilled, CloseOutlined } from "@ant-design/icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "../../Account/styles/Licence.css"
import { Loading } from "../../Common/components/Loading";
import { useState } from 'react';
import { useEffect } from 'react';
import { accountHandle } from '../../Account/state/actions';
import TabPane from 'antd/lib/tabs/TabPane';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const LicenseId = () => {
  const [idEdit, setIdEdit] = useState(false);
  const [selectedRow, setSelectedrow] = useState({});
  const [updatedId, setUpdatedId] = useState("");
  const [formOpen, setFormOpen] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { getUserDetailsData, getLicenseData, getLicenseLoading, createLicenseData } = useSelector(
    (state) => state.account,
    shallowEqual
  );
  const history=useHistory()

  let unmounted = {
    value: false
  };
  let source = axios.CancelToken.source();
  // get all the LicenseDetais once the component is mounted
  useEffect(() => {
    dispatch(accountHandle.getAllLicense({unmounted, source}));
    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  }, [createLicenseData]);

  useEffect(() => {
    if (
      !getLicenseLoading &&
      getLicenseData?.length === 0 &&
      getLicenseData?.length !== 3
    ) {
      setFormOpen(true);
    }
  }, [getLicenseData]);
  // create a new License ID on the submit of form
  const onFinish = (values) => {
    dispatch(accountHandle.createLicense({payload: values, getUserDetailsData}));
   
    setFormOpen(false);
    form.resetFields();
    if(getUserDetailsData?.hasSubscription)
    {
      history.push("/")
    }
    else{
      history.push("/pricing")
    }
  };

  // when the user clicks on any particular id to update then to opening the input box
  const handleEdit = (el) => {
    setSelectedrow(el);
    setIdEdit(true);
    setUpdatedId(el.id);
  };
  //logic for updating the LicenseId when the user clicks on save button
  const handleIdUpdate = () => {
    let payload = {
      idType: selectedRow.idType,
      id: updatedId,
    };
    dispatch(accountHandle.updateLicense({licenseMongoId: selectedRow._id, payload, getUserDetailsData}));
    setIdEdit(false);
  };

  // delete a particular license ID
  const handleDelete = (licenseMongoId) => {
    dispatch(accountHandle.deleteLicense(licenseMongoId, message));
  };
  const handleOutsideClick = () => {
    if (idEdit) {
      setIdEdit(false);
    }
  };
  const handlepush=()=>{
    
    if(getUserDetailsData?.hasSubscription)
    {
      history.push("/")
    }
    else{
    history.push("/pricing")
    }
  }
  return (
<div style={{width:"40%", height:"60vh",margin:"auto",boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",padding:"20px",marginTop:"80px"}}>
<Tabs style={{marginTop:"20px"}}
tabBarExtraContent={
          <Button style={{borderRadius:"8px", color:"#178DFA",fontWeight:"bold"}} onClick={handlepush}>Skip</Button>
}
          >
        <TabPane
          tab={
            <span
              className="mozFontSize"
              style={{
                fontSize: "1.2rem",
              }}
             >
             ADD LICENSE ID
            </span>
          }
        
        >
         
        </TabPane>
      </Tabs>
      <div>
      <div onClick={handleOutsideClick} >
      {getLicenseLoading ? (
        <Loading />
      ) : (
        <>
          {getLicenseData?.length > 0 ? (
            <table className="licenseTable" style={{marginTop:"20px"}}>
              <thead>
                <tr>
                  <th>ID Type</th>
                  <th>ID No.</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {getLicenseData?.map((el) => (
                  <tr key={el._id}>
                    <td
                      style={{
                        fontWeight: "bolder",
                        width: "50%",
                      }}
                    >
                      {el.idType}
                    </td>
                    <td
                      onClick={() => handleEdit(el)}
                      style={{
                        color: "#178DFA",
                        fontWeight: "bolder",
                        textAlign: "center",
                        cursor: "pointer",
                        width: "35%",
                      }}
                    >
                      {selectedRow.id === el.id &&
                      el._id === selectedRow._id &&
                      idEdit ? (
                        <Input
                          value={updatedId}
                          autoFocus
                          onChange={(e) => setUpdatedId(e.target.value)}
                        />
                      ) : (
                        `${el.id}`
                      )}
                    </td>
                    <td
                      style={{
                        color: "#DC143C",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Popconfirm
                        title="Are you sure to delete this Licence?"
                        onConfirm={() => handleDelete(el._id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteTwoTone />
                      </Popconfirm>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
        </>
      )}
      {formOpen && !getLicenseLoading && getLicenseData?.length < 3 ? (
        <>
          <Divider />
          <Form
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="ID Type"
              name="idType"
              rules={[{ required: true, message: "Please Select the ID Type" }]}
            >
              <Select
                allowClear
                placeholder="Select the ID type"
                options={[
                  {
                    value: "RECOLORADO",
                    disabled: getLicenseData?.some(
                      (el) => el.idType === "RECOLORADO"
                    ),
                    label: "RECOLORADO",
                  },
                  {
                    value: "COLORADO STATE LICENSE",
                    disabled: getLicenseData?.some(
                      (el) => el.idType === "COLORADO STATE LICENSE"
                    ),
                    label: "COLORADO STATE LICENSE",
                  },
                  {
                    value: "NRDS ID",
                    disabled: getLicenseData?.some(
                      (el) => el.idType === "NRDS ID"
                    ),
                    label: "NRDS ID",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label="ID No"
              name="id"
              rules={[{ required: true, message: "Please Enter Id Number"}]}
            >
              <Input placeholder="Enter ID Number" allowClear />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 12, span: 12 }} >
              <Button type="primary" htmlType="submit" style={{marginTop:"25px"}}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </>
      ) : null}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        {idEdit ? (
          <div style={{ marginTop: "-10px" }}>
            <Button type="primary" onClick={handleIdUpdate}>
              Save
            </Button>
          </div>
        ) : (
         null
        )}
      </div>
    </div></div>
    </div>
  )
}

export {LicenseId} 
