import { DatePicker, Modal, Select, Button } from 'antd';
import React from 'react'
import { useState } from 'react';
import moment from "moment";
import { bugReportHandle } from '../state/actions';
import { useDispatch, useSelector } from 'react-redux';
import styles from "../styles/Listing.module.css";

const FilterModal = ({showFilter, setShowFilter, setPage, page, form, filterToggle, setFilterToggle, source}) => {

  const { status, like, priority, startDate, endDate } = useSelector((state) => state.bugReport);

  const dispatch = useDispatch();

    const handlePriority = (value) => {
        dispatch(bugReportHandle.changeFilterPriority(value));
      };
    
      const handleStartDate = (value) => {
        const formattedDate = value.format("YYYY-MM-DD");
        dispatch(bugReportHandle.changeFilterStartDate(formattedDate));
      };
    
      const handleEndDate = (value) => {
        const formattedDate = value.format("YYYY-MM-DD");
        dispatch(bugReportHandle.changeFilterEndDate(formattedDate));
      };

      const handleCancel = () => {
        setShowFilter(false);
      };
    
      const handleStatus = (value) => {
        dispatch(bugReportHandle.changeFilterStatus(value));
      };
    
      const handleLike = (value) => {
        dispatch(bugReportHandle.changeFilterLike(value));
      };

      const handleFilter = () => {
        if (
            status?.length > 0 ||
            like !== null ||
            startDate?.length > 0 ||
            priority?.length > 0
        ) {
          dispatch(
            bugReportHandle.getFilteredData(
              status,
              like,
              priority,
              startDate,
              endDate,
              source
            )
          );
          setFilterToggle(true);
          setShowFilter(false);
        }
      };

    const statusOptions = [
        { value: "ACTIVE", label: "Active" },
        { value: "IN_PROGRESS",label: "In-Progress"},
        { value: "AWAITING_INPUT",label: "Awaiting Input"},
        { value: "INPUT_RECEIVED",label: "Input Received"},
        { value: "NEEDS_TESTING",label: "Completed / Needs Testing"},
        { value: "COMPLETED",label: "Completed"},
        { value: "CLOSED", label: "Closed" },
      ];
      const likeOptions = [
        { value: true, label: "Liked" },
        { value: false, label: "Disliked" },
      ];
      const priorityOptions = [
        { value: "LOW", label: "Low" },
        { value: "MEDIUM", label: "Medium" },
        { value: "HIGH", label: "High" },
        { value: "CRITICAL", label: "Critical" },
      ];

      const handleClearAll = () => {
        dispatch(bugReportHandle.resetFilteredData());
        setPage(1)
        form.resetFields();
        setFilterToggle(false);
        setShowFilter(false);
        dispatch(bugReportHandle.resetAllData(source));
        if(source === "BUGS"){
          dispatch(bugReportHandle.getBugListingDataWith(page));
        }else if(source === "SUGGESTIONS"){
          dispatch(bugReportHandle.getSuggestionsData(page));
        }else{
          dispatch(bugReportHandle.getSupportData(page));
        }
        dispatch(bugReportHandle.getBugListingDatayourBugs(source, true));
      };

      const customFooter = (
        <div>
          <Button
            onClick={handleClearAll}
            disabled={filterToggle === false}
            type="primary"
          >
            Clear All
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            type="primary"
            disabled={
              status?.length <= 0 &&
              like === null &&
              startDate?.length <= 0 &&
              endDate?.length <= 0 &&
              priority?.length <= 0
            }
            onClick={handleFilter}
          >
            Apply
          </Button>
        </div>
      );
  return (
    <Modal
        footer={customFooter}
        width={"60%"}
        title={
          <span
            style={{ display: "flex", justifyContent: "center", color: "grey" }}
          >
            FILTER
          </span>
        }
        open={showFilter}
        onCancel={handleCancel}
      >
        <div className={styles.filterDiv} style={{ gap: "10px" }}>
          <div style={{ width: "100%" }}>
            <h4 style={{ textAlign: "center" }}>Status</h4>
            <Select
              style={{ width: "100%" }}
              placeholder="Select Status"
              showArrow
              mode="single"
              options={statusOptions}
              value={status}
              onChange={handleStatus}
              size="medium"
            ></Select>
          </div>

          <div style={{ width: "100%" }}>
            <h4 style={{ textAlign: "center" }}>Liked/Disliked</h4>
            <Select
              style={{ width: "100%" }}
              placeholder="Select Liked/Disliked"
              showArrow
              mode="single"
              options={likeOptions}
              value={like}
              onChange={handleLike}
              size="medium"
            ></Select>
          </div>

          <div style={{ width: "100%" }}>
            <h4 style={{ textAlign: "center" }}>Priority</h4>
            <Select
              style={{ width: "100%" }}
              placeholder="Select Priority"
              showArrow
              mode="single"
              options={priorityOptions}
              value={priority}
              onChange={handlePriority}
              size="medium"
            ></Select>
          </div>

          <div style={{ width: "100%" }}>
            <h4 style={{ textAlign: "center" }}>Start Date</h4>
            <DatePicker
              style={{ width: "100%" }}
              inputReadOnly={true}
              autoFocus
              bordered={true}
              format="YYYY-MM-DD"
              value={startDate ? moment(startDate, "YYYY-MM-DD") : null}
              onChange={handleStartDate}
            />
          </div>
          <div style={{ width: "100%" }}>
            <h4 style={{ textAlign: "center" }}>End Date</h4>
            <DatePicker
              style={{ width: "100%" }}
              disabled={startDate?.length <= 0}
              inputReadOnly={true}
              autoFocus
              bordered={true}
              format="YYYY-MM-DD"
              value={endDate ? moment(endDate, "YYYY-MM-DD") : null}
              onChange={handleEndDate}
            />
          </div>
        </div>
      </Modal>
  )
}

export {FilterModal}
