import { clientConstants } from "../action-types";

const initialState = {
  updateTitleDetails: null,
  updateTitleLoading: false,
  updateTitleError: null,
};

const titleReducer = (state = initialState, action) => {
  switch (action.type) {
    // update && post Title
    case clientConstants.UPDATE_TITLE_SUCCESS:
      return {
        ...state,
        updateTitleDetails: action.updateTitleDetails,
        updateTitleLoading: action.updateTitleLoading,
      };
    case clientConstants.UPDATE_TITLE_FAILURE:
      return {
        ...state,
        updateTitleError: action.updateTitleError,
        updateTitleLoading: action.updateTitleLoading,
      };
    case clientConstants.TITLE_RESET:
      return {
        ...state,
        updateTitleDetails: null,
        updateTitleLoading: true,
        updateTitleError: null,
      };
    default:
      return state;
  }
};
export { titleReducer };
