import axios from "axios";
import dotEnv from "dotenv";
// import dayjs from "dayjs";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const getClientScreen = (params) => {
  let accessToken = localStorage.getItem("clientAuth");
  return axios.get(
    `${apiURL}/clientRTD`,
    {
    params,
    headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    }
  );
};
const getEmails_FilesApi = (params) => {
  let accessToken = localStorage.getItem("clientAuth");
  return axios.get(
    `${apiURL}/emailsAndFiles`,
    {
    params,
    headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    }
  );
};
const DocumentApi = (data) => {
    let accessTokan = localStorage.getItem("clientAuth");
    return axios.post(
      `${apiURL}/url/builtForSection`,
      {
        ...data,
      },
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
  };
// only used for client dashboard upload Pdf/images by clients
  const uploadPropertyDocsRequest = (data) => {
 
    let accessTokan = localStorage.getItem("clientAuth");
    return axios.post(`${apiURL}/uploadPropertyDocs`, data, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  };

  const getClientDocRequest = (params) => {
       
    let accessTokan = localStorage.getItem("clientAuth");
    return axios.get(`${apiURL}/propertyDocs`,{
        params,
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
  };

  const deletepropertyDocsRequest = (documentId) => {
    let accessTokan = localStorage.getItem("clientAuth");
    return axios.delete(`${apiURL}/propertyDocs/${documentId}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  };
export const clienScreenApi = {
    getClientScreen,
    getEmails_FilesApi,
    DocumentApi,
    uploadPropertyDocsRequest,
    deletepropertyDocsRequest,
    getClientDocRequest
  };
