import React from "react";
import { Row, Col, Layout, Alert } from "antd";
import { Route } from "react-router-dom";
import styles from "./styles/User.module.css";
import Logo from "../../Assets/Icons/newLogo.png";
import { useSelector } from "react-redux";
const { Content } = Layout;

/**
 * @function PrivateRoute
 * @param {Object} Object parent object paraments
 * @returns {Object} JSX.Element
 * Higher order function that validates the auth token before proceeding
 */

const UserAuthenticate = ({ component: Component, ...rest }) => {
  const { userSignupError } = useSelector((state) => state.userSignup);

  return (
    <>
      <Route
        {...rest}
        render={(props) => (
          <div>
            <Layout>
              {userSignupError ? (
                <Alert
                  style={{
                    width: "20%",
                    position: "absolute",
                    top: "60px",
                    right: "0px",
                  }}
                  message="Email already exists"
                  type="error"
                  showIcon
                  closable
                />
              ) : (
                ""
              )}
              <Row style={{ height: "100%" }}>
                <Col
                  xs={0}
                  sm={0}
                  md={0}
                  lg={12}
                  xl={12}
                  xxl={12}
                  style={{
                    height: "100%",
                    position: "sticky",
                    top: "0",
                    left: "0",
                  }}
                >
                  <Content>
                    <div className={styles.alignBackground}></div>
                    <img
                      className={styles.alignForhead}
                      src={Logo}
                      alt="userLoginLogo"
                    />
                  </Content>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Content>
                    <Component {...props} />
                  </Content>
                </Col>
              </Row>
            </Layout>
          </div>
        )}
      />
    </>
  );
};
export { UserAuthenticate };
