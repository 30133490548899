/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Col, Form, Input, Row } from "antd";
import { FolderFilled, FolderOpenOutlined, FileZipOutlined, CaretDownOutlined, CaretRightOutlined, SearchOutlined } from "@ant-design/icons";
import {  useDispatch, useSelector } from "react-redux";
import { formatAllSelerAgentsDetailsFun, formatPhoneNumber, getCoAgentsIds } from "../../Common/utils/extraFunctions";
import { useLocation } from "react-router-dom";
import { Loading } from "../../Common/components/Loading";
import { filteredTransactionData,removePersonObject } from "./helper";
import { PropertyHandlers } from "../state/actions";
import axios from "axios";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";
import UploadPdfDoc from "./UploadPdfDoc";
import styles from "../../Rtd/style/document.module.css";
import _ from "lodash";
import CreateDoraDocsModal from "./CreateDoraDocsModal";

const StyledTree = styled.div`
  line-height: 1.5;
`;
const StyledFile = styled.div`
  padding-left: px;
  display: flex;
  align-items: center;
  span {
    margin-left: 0px;
  }
`;
const StyledFolder = styled.div`
  padding-left: 0px;
  .folder--label {
    display: flex;
    align-items: center;
    span {
      margin-left: 0px;
    }
`;
const Collapsible = styled.div`
  height: ${(p) => (p.isOpen ? "0" : "auto")};
  overflow: hidden;
`;

const File = ({ name, selectedData, role, propertyDocs, insideFolder, section, handleCreateDocCancel }) => {
  const delegate = (section === "DELEGATE");
  const dispatch = useDispatch();
  const [isDoraDocsModalVisible, setIsDoraDocsModalVisible] = useState(false);
  const location = useLocation();
  const [isEmailPresent] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);

  const currentAgentDetails =  selectedData?.currentAgentId || {}
  const [ allData, setAllData ] = useState({
    uploadPdfDoc: false,
    file: "",
    ...(delegate ? {delegateSide: role, delegateOwnerId: currentAgentDetails?._id} : {}),
  })
 

  const urlShortHand = (ele) => {
    selectedData = filteredTransactionData({selectedData, ele, source:"PROPERTY_DOCS"});
    let contractId = ele?.contractId?.pop();
    let prefillValues = ele?.prefillValues?.pop() || {};
    let allFormattedAgents={};
    let transactionData = selectedData?.newTransactionData || selectedData?.transactionData || {}
    if (!selectedData.offerId && Array.isArray(selectedData?.offerSellerAgentIds) && selectedData?.offerSellerAgentIds.length) {
       allFormattedAgents = formatAllSelerAgentsDetailsFun({persistId: selectedData.persistId, offerSellerAgentIds: selectedData.offerSellerAgentIds, transactionData});
       transactionData['SellerAgents'] = allFormattedAgents?.SellerAgents;
    }
    if (ele?.contractType === "SA") {
      transactionData["BrokerName"] = currentAgentDetails?.fullName || "";
      transactionData["BrokerEmailAddress"] = currentAgentDetails?.email || "";
      transactionData["BrokerFirmName"] = currentAgentDetails.brokerageData.name || "";
      transactionData["BrokerFirmAddress"] = currentAgentDetails.brokerageData.address || "";
      transactionData["BrokerPhoneNumber"] = formatPhoneNumber(currentAgentDetails.mobilePhoneNumber.phoneNumber)
      transactionData["BrokerFaxNumber"] = formatPhoneNumber(currentAgentDetails.brokerageData.faxNumber);
    }
    // console.log("coming outside this one", transactionData);
    let buildData = {
      builtForId: currentAgentDetails?._id,
      key: currentAgentDetails?._id,
      builtForSection: "DOCUMENT",
      signable: true,
      buyerAgentIds: getCoAgentsIds(selectedData?.buyerAgentIds),
      sellerAgentIds: getCoAgentsIds(selectedData?.sellerAgentIds),
      openFor: role === "SELLER" ? "SELLER_AGENT" : "BUYER_AGENT",
      RTDInitiator: role,
      contractId: ele.contractType === "SPD" ? "64f97e26494d69c486381500" : ele.contractType === "NTT" && role === "SELLER" ? "65bb626913d7f6bd9231b13b" : ele.contractType === "NTT" && role === "BUYER" ? "65bb61d613d7f6bd9231b13a" : contractId,
      contractType: ele?.contractType,
      openForPropertyDoc: role === "SELLER" ? "SELLER_AGENT" : "BUYER_AGENT",
      ...(selectedData?.offerId ? { offerId: selectedData?.offerId || "" } : {}),
      token: localStorage.getItem("userAuth"),
      ...(selectedData && selectedData.propertyId ? { propertyId: selectedData.propertyId } : {}),
      ...(selectedData && selectedData.persistId ? { ertsId: selectedData.persistId } : {}),
      ...(selectedData?.thirdpartyId ? { thirdpartyId: selectedData?.thirdpartyId } : {}),
      ...(delegate ? { delegateOwnerId: currentAgentDetails?._id } : {}),
      urlData: {
        ...(ele?.contractType !== "LCAES" && transactionData ? { ...transactionData } : {}),
        FullAddress: selectedData?.address,
        ...(ele?.contractType === "LCAES" ? { LcaeCheckedListingContract: "ERTSListingContract" } : {}),
        ...(ele?.contractType === "LCAES" ? { LcaeInitatingParty: "LcaeInitatingPartySeller" } : {}),
        ...(ele?.contractType === "LCAES" && selectedData?.ertsData ? { ...selectedData.ertsData } : {}),
        ...(ele?.contractType === "LCAES" && !selectedData?.ertsData?.SellerAgents ? { SellerAgents: transactionData?.SellerAgents } : {}),
        ...prefillValues,
        ...(selectedData?.sellerIds?.length > 0 ? { Sellers: removePersonObject(selectedData?.sellerIds) } :{}),
        ...(selectedData?.buyerIds?.length > 0 ? { Buyers: removePersonObject(selectedData?.buyerIds) } : {}),
      },
    };
    // console.log("buildData", buildData);
    dispatch(transactionLaneDataHandle.generateUrlIdAndRedirect({ buildData, currentPath: location?.pathname }));
  };

  let docInRtd = false;
  propertyDocs?.forEach((el) => {
    if (el.documentName === name.name) {
      docInRtd = true;
    }
  });

  let str = name.name;

  let span1 = "";
  let span2 = "";
  if (str.length > 24) {
    str = str.substr(0, 24);
    var lastSpace = str.lastIndexOf(" ");
    if (!lastSpace) {
      lastSpace = str.lastIndexOf("/");
    }
    if (lastSpace !== -1) {
      span1 = str.substr(0, lastSpace);
      span2 = name.name.substr(lastSpace, name.name.length);
    }
  } else {
    span1 = name.name;
  }

  const handleCreateNew = () => {
    urlShortHand(name);
  };

  const handleUploadNew = () => {
    setAllData({
      ...allData,
      uploadPdfDoc: true,
    })
  }

  const handleModalCancel = () => {
    setAllData({
      ...allData,
      uploadPdfDoc: false
    })
  }


  return (
    <>
      {/* create Dora Docs Modal */}
      {isDoraDocsModalVisible && <CreateDoraDocsModal isDoraDocsModalVisible={isDoraDocsModalVisible} setIsDoraDocsModalVisible={setIsDoraDocsModalVisible} selectedData={selectedData} element={name} handleCreateNew={handleCreateNew} handleUploadNew={handleUploadNew } />}
      <UploadPdfDoc selectedData={{ ...selectedData, ...name, RTDInitiator: role }} allData={allData} setAllData={setAllData} handleModalCancel={handleModalCancel} open={allData?.uploadPdfDoc} successFuncAll={handleCreateDocCancel} />
      <StyledFile >
        <span
          style={{
            fontSize: "18px",
            fontWeight: 500,
            textAlign: "left",
            color: docInRtd ? "rgb(110 153 214)" : "#b0b0b0",
            width: "100%",
            marginTop: "4px",
            marginLeft: insideFolder ? "21px" : "auto",
            paddingLeft: 0,
          }}
        >
          <FileZipOutlined style={{ fontSize: "20px", cursor: "pointer", marginRight: "5px" }} />
          <span
            id="fileNameContainer"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              if (!isEmailPresent) {
                setIsDoraDocsModalVisible(true);
              } else {
                setIsEmailModalVisible(true);
              }
            }}
          >
            {span1}
            {span2}
          </span>
        </span>
      </StyledFile>
    </>
  );
};
// its an folder structure component
const Folder = ({ name, children, fiters }) => {
  const [isOpen, setIsOpen] = useState(fiters?.search?.trim() ? true : false);
  const handleToggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <StyledFolder>
      <div
        className="folder--label"
        onClick={handleToggle}
        style={{
          cursor: "pointer",
        }}
      >
        {isOpen ? (
          <>
          <CaretDownOutlined
            style={{
                fontSize: "1.3rem",
                marginRight: "5px",
                color: "rgb(107,152,199)",
            }}
          />{" "}
            <FolderOpenOutlined style={{ fontSize: "20px", color: "rgb(107,152,199)" }} />
          </>
        ) : (
          <>
            <CaretRightOutlined style={{ fontSize: "1.3rem", color: "#8C8C8C" }} />
            <FolderFilled
              style={{
                fontSize: "20px",
                color: "rgb(107,152,199)"
              }}
            />
          </>
        )}
        <span
          style={{
            fontSize: "19px",
            fontWeight: 600,
            color: "#8C8C8C",
            marginTop: "4px",
            paddingLeft: "4px",
          }}
        >
          {name}
        </span>
      </div>
      <Collapsible isOpen={!isOpen}>{children}</Collapsible>
    </StyledFolder>
  );
};

const Tree = ({ children }) => {
  return <StyledTree>{children}</StyledTree>;
};

Tree.File = File;
Tree.Folder = Folder;

export default function CreateDocs({ role, item, section, handleCreateDocCancel,loading }) {
  const dispatch = useDispatch();
  const { getPreDoraDocumentsData, getPreDoraDocumentsLoading, getPreDoraDocumentsError, propertyDocs } = useSelector((state) => state.PropertyReducer);

  const { getIndividualTransactionData } = useSelector((state) => state.rtdTransactionLane);
   const [fiters, setFilters] = useState({search: ""});
    const [form] = Form.useForm();
    let unmounted = {
      value: false
    };
    let source = axios.CancelToken.source();
    const handleClear = ({ renderWithClear }) => {
        form.resetFields();
        setFilters({
            search: ""
        })
    }
    useEffect(()=>{
      let data = {
        listType: role,
        ...(item?.offerId ? { offerId: item?.offerId }: {}),
      };
      dispatch(PropertyHandlers.getPreDoraDocumentsData({data, unmounted, source, fiters}));

      return () => {
        unmounted.value = true;
        source.cancel("Cancelling in cleanup");
      }
    }, [fiters])

 

  //debounce logic to call search api
    const debouncedFunction = _.debounce((value) => {
        setFilters({
            search: value
        })
    }, 1000);

    const handleSearch = (event) => {
        const value = event.target.value;
        debouncedFunction(value);
    };

  return (
    <div style={{ height: "calc(85vh - 8rem)", display: "flex", flexDirection: "column" }}>

      <Row style={{ width: "100%", flexWrap: "nowrap", justifyContent: "center", gap: 5 }}>
        <Col className={styles.searchInputBoxCol}>
          <div className={styles.searchInputBox}>
            <Form form={form} initialValues={{}}>
              <Form.Item name="search">
                <Input
                  allowClear
                  placeholder="Search here..."
                  suffix={<SearchOutlined />}
                  onChange={handleSearch}
                  size="middle"
                />
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col>
          <div
            className={styles.sortBox}
          >
            <Button
              style={{
                background: "#3a74b6",
                color: "whitesmoke",
                fontWeight: "bold",
                border: "none",
                padding: "0 10px"
              }}
              onClick={handleClear}
            >
              <div>Reset</div>
            </Button>
          </div>
        </Col>
      </Row>
      {loading || getPreDoraDocumentsLoading  ? (
        <Loading />
      ) : getPreDoraDocumentsError ? "" : (
        <Row span={24} style={{ height: "50vh", overflowY: "auto", marginBottom: "5px" }}>
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Tree>
              {getPreDoraDocumentsData?.length &&
                getPreDoraDocumentsData.map((ele) => {
                  if (ele.doc_second.length > 0) {
                    return (
                      <Tree.Folder key={ele._id} fiters={fiters} name={ele.name} style={{ cursor: "pointer" }}>
                        {ele.doc_second.map((element) => {
                          return <Tree.File key={element._id} name={element} selectedData={getIndividualTransactionData||item} role={role} propertyDocs={propertyDocs} insideFolder={true} section={section} handleCreateDocCancel={handleCreateDocCancel}  />;
                        })}
                      </Tree.Folder>
                    );
                  } else return <Tree.File key={ele._id} name={ele} selectedData={getIndividualTransactionData||item} role={role} propertyDocs={propertyDocs} insideFolder={false} section={section} handleCreateDocCancel={handleCreateDocCancel}  />
                })}
            </Tree>
          </Col>
        </Row>
      )}
    </div>
  );
}
