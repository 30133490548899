import React, { useState } from "react";
import { Select, Form } from "antd";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

const IconTextStatus = ({ item, statusOptions, form, icon, handleStatus }) => {
  const { searchbugdata } = useSelector((state) => state.bugReport);
  const { Option } = Select;
  const [optionColor,setOptionColor] = useState(item?.status);
  const isMobile = useMediaQuery({maxWidth:480});

  const handleStatusChange = (values) => {
    handleStatus(item?._id,values)
    setOptionColor(values);
  };

  const getColorForStatus = (status) => {
    switch (status) {
      case "ACTIVE":
        return "red";
      case "IN_PROGRESS":
        return "#DFC103";
      case "NEEDS_TESTING":
        return "orange";
      case "AWAITING_INPUT":
        return "purple"
      case "INPUT_RECEIVED":
        return "#33CEFF"
      case "COMPLETED":
        return "blue";
      case "CLOSED":
        return "green";
      default:
        return "black";
    }
  };

  const customTagRender = ({ value, label }) => (
    <span style={{ color: getColorForStatus(value) }}>
      {React.createElement(icon)} {label}
    </span>
  );

  useEffect(() => {
    if (searchbugdata?.[0]) {
      const newStatus = searchbugdata?.[0]?.status;
      form.setFieldsValue({ status: newStatus });
      setOptionColor(newStatus);
    }
  }, [searchbugdata, form]);

  return (
    <Form form={form} initialValues={{ status: item?.status }}>
      <Form.Item name="status">
        <Select style={{ width: isMobile ? "140px" : "170px",color: getColorForStatus(optionColor || item?.status), textAlign:"left" }} placeholder="Select Status" showArrow mode="single" size="medium" bordered={false} onChange={handleStatusChange} tagRender={customTagRender}>
          {statusOptions?.map((status) => (
            <Option key={status?.value} value={status?.value} style={{color:getColorForStatus(status?.value)}}>
              {React.createElement(icon)} {status?.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export { IconTextStatus };