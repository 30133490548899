import React from 'react'
import styles from '../style/NewsLetterBox.module.css'
import { List, Avatar, Typography, Button, Row, Col } from "antd";
const { Title, Paragraph, Text, Link } = Typography;

function NewsLetterBox({data}) {
    const contentHTML = { __html: data.content };
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <h3 className={styles.h3}>{data?.title}</h3>
            </div>
            <div className={styles.content}>
                <p style={{ color: 'aliceblue' }} dangerouslySetInnerHTML={contentHTML}></p>
            </div>
        </div>
    )
}

export default NewsLetterBox