/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { decryptQuery } from '../../Common/commondata/commonData';
import { useLocation, Link } from "react-router-dom";
import { Button, Result, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { appStatusChange } from '../state/actions';
import { CheckCircleOutlined } from '@ant-design/icons';

export default function HandleRedirections() {
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const customerId = query.get("stripe") ? decryptQuery(query.get("stripe")) : "";
    const deleteAccount = query.get("isDel") ? decryptQuery(query.get("isDel")) : "";
    const action = query.get("action");
    const dispatch = useDispatch();
 
    const { customerDeskLoading, customerDeskError, customerDeskSuccess, } = useSelector((state) => state.app);

    useEffect(() => {
        if(action){
            dispatch(appStatusChange.handleCustomerDesk({customerId, ...(action ? {action} : {}), ...(deleteAccount ? {deleteAccount} : {})}));
        }
    }, [action])

    if(!action){
        return;
    }
    
    const pageShow = (message) => {
        return <Result
            status="404"
            title="404"
            subTitle= {message || "Sorry, the page you visited does not exist."}
            extra={
            <Button type="primary">
                <Link to="/">Home</Link>
            </Button>
            }
        />
    }
    
    return (
        <div style={{height:"100vh",display: "flex", fontSize: "19px", gap: "13px", fontWeight: 500, flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
            {
                customerDeskLoading.status ? <>
                    <Spin size='large'/>
                    {customerDeskLoading.message}
                </> : customerDeskError.status ? pageShow(customerDeskError.message) : customerDeskSuccess.status ? <>
                    <CheckCircleOutlined style={{color: "green", fontSize: "56px"}}/>
                    {customerDeskSuccess.message}
                </> : ""
            }
        </div>
    )
}
