import { clientConstants } from "../action-types";

const initialState = {
  globalClientLoading: false,

  postEventDetails: [],
  postEventLoading: false,
  postEventError: null,

  editEventDetails: [],
  editEventLoading: false,
  editEventError: null,
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    // post event data
    case clientConstants.POST_EVENT_SUCCESS:
      return {
        ...state,
        postEventDetails: action.postEventDetails,
        postEventLoading: action.postEventLoading,
      };
    case clientConstants.POST_EVENT_FAILURE:
      return {
        ...state,
        postEventError: action.postEventError,
        postEventLoading: action.postEventLoading,
      };

    // edit event data
    case clientConstants.EDIT_EVENT_SUCCESS:
      return {
        ...state,
        editEventDetails: action.editEventDetails,
        editEventLoading: action.editEventLoading,
      };
    case clientConstants.EDIT_EVENT_FAILURE:
      return {
        ...state,
        editEventError: action.editEventError,
        editEventLoading: action.editEventLoading,
      };

    default:
      return state;
  }
};
export { eventReducer };
