export const landingPageConstants = {
  GET_REMINDERS_LIST_LOADING: "GET_REMINDERS_LIST_LOADING",
  GET_REMINDERS_LIST_SUCCESS: "GET_REMINDERS_LIST_SUCCESS",
  GET_REMINDERS_LIST_FAILURE: "GET_REMINDERS_LIST_FAILURE",

  CREATE_REMINDER_SUCCESS: "CREATE_REMINDER_SUCCESS",
  CREATE_REMINDER_FAILURE: "CREATE_REMINDER_FAILURE",

  GET_REMINDERS_BY_ID_SUCCESS: "GET_REMINDERS_BY_ID_SUCCESS",
  GET_REMINDERS_BY_ID_FAILURE: "GET_REMINDERS_BY_ID_FAILURE",

  UPDATE_REMINDER_SUCCESS: "UPDATE_REMINDER_SUCCESS",
  UPDATE_REMINDER_FAILURE: "UPDATE_REMINDER_FAILURE",

  DELETE_REMINDERS_BY_ID_SUCCESS: "DELETE_REMINDERS_BY_ID_SUCCESS",
  DELETE_REMINDERS_BY_ID_FAILURE: "DELETE_REMINDERS_BY_ID_FAILURE",

  GET_SCHEDULE_LIST_SUCCESS: "GET_SCHEDULE_LIST_SUCCESS",
  GET_SCHEDULE_LIST_FAILURE: "GET_SCHEDULE_LIST_FAILURE",
  GET_SCHEDULE_LIST_LOADING: "GET_SCHEDULE_LIST_LOADING",

  GET_REMINDER_AND_SCHEDULE_SUCCESS: "GET_REMINDER_AND_SCHEDULE_SUCCESS",
  GET_REMINDER_AND_SCHEDULE_FAILURE: "GET_REMINDER_AND_SCHEDULE_FAILURE",
  GET_REMINDER_AND_SCHEDULE_LOADING: "GET_REMINDER_AND_SCHEDULE_LOADING",

  RESET_NOTES_STATE:"RESET_NOTES_STATE",
  NOTES_SAVING_STATUS:"NOTES_SAVING_STATUS",
  NOTES_PANEL_LOADING:"NOTES_PANEL_LOADING",
  GET_SAVED_NOTES_SUCCESS: "GET_SAVED_NOTES_SUCCESS",


  GET_NEWSLETTER_LOADING: "GET_NEWSLETTER_LOADING",
  GET_NEWSLETTER_PAGE_DATA_SUCCESS: "GET_NEWSLETTER_PAGE_DATA_SUCCESS",
  GET_NEWSLETTER_PAGE_DATA_FAILURE: "GET_NEWSLETTER_PAGE_DATA_FAILURE",

  RENDER_SUPPORT_COMPONANT_LOADING : "RENDER_SUPPORT_COMPONANT_LOADING",
  RENDER_SUPPORT_COMPONANT_SUCESS : "RENDER_SUPPORT_COMPONANT_SUCESS",
  RENDER_SUPPORT_COMPONANT_FAILURE : "RENDER_SUPPORT_COMPONANT_FAILURE",

  RENDER_SCHEDULE: "RENDER_SCHEDULE",
  RENDER_REMINDER: "RENDER_REMINDER"
};
