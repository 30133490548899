import { documentConstants } from "../action-types";

const initialState = {
  getDraftDocumentData: [],
  getDraftLoading: false,
  getDraftFailure: null,
  isModalVisibles: false,
  setIsModalVisibles: true,

  statusCode: "",
};

const draftDocumentReducer = (state = initialState, action) => {
  switch (action.type) {
    case documentConstants.DRAFT_DOCUMENTS_LOADING:
      return {
        ...state,
        getDraftLoading: true,
        getDraftFailure: null,
      };
    case documentConstants.DRAFT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        getDraftDocumentData: action.getDraftDocumentData,
        getDraftLoading: false,
        getDraftFailure: null,
      };
    case documentConstants.DRAFT_DOCUMENTS_FAILURE:
      return {
        ...state,
        getDraftDocumentData: [],
        getDraftLoading: false,
        getDraftFailure: " Error Processing Request",

        statusCode: action?.getDraftFailure?.response?.data,
        isModalVisibles: true,
      };

    default:
      return state;
  }
};
export { draftDocumentReducer };
