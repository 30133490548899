import { Button, Image, Modal, Spin } from "antd";
import React, { useState } from "react";
import AvatarImage from "./NotesImageUpload";

const ImagePreviewModal = ({ notesState, setNotesState, UploadImageForNotes, EditImageOFNotes, handleDeleteImage }) => {
  const [loading, setLoading] = useState(true);

  const handleCancel = () => {
    setNotesState((prevState) => ({ ...prevState, showImagePreview: false }));
  };

  return (
    <Modal visible={notesState?.showImagePreview} onCancel={handleCancel} footer={[]} width={500}>
      <div style={{ textAlign: "center" }}>
        {loading && (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "300px" }}>
            <Spin size="large" />
          </div>
        )}
        <Image src={notesState?.imgPreviewUrl} alt="Preview" style={{ display: loading ? "none" : "block", maxWidth: "100%" }} onLoad={() => setLoading(false)} onError={() => setLoading(false)} />
        {!loading && (
          <div style={{ display: "flex", justifyContent: "space-evenly", alignContent: "center" }}>
            <div>
              <AvatarImage id="forEdit" UploadImageForNotes={UploadImageForNotes} EditImageOFNotes={EditImageOFNotes} />
            </div>
            <div style={{ marginTop: "14px", marginLeft: "0" }}>
              <Button type="primary" onClick={handleDeleteImage}>
                Delete
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ImagePreviewModal;
