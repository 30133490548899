import { documentConstants } from "../action-types";

const initialState = {
    templateType: "",
    propertyType: "",
    closingDateType: null,
    documentType: "66ab7a6a7bfcc2a4deedb08d", // new CBSR 2024
    priceType: null,
    searchBuyerType: "",
    clientsArray: [],
    agentsArray: [],
    // showClientListModal: false,
    entityName: "",
    drawerVisible:false,
    currentlySelectedCorpData:{},
    isCorpTrue:false,
};

const CorpReducer = (state = initialState, action) => {
  switch (action.type) {
    case documentConstants.UPDATE_CORP_DATA: {
        // console.log(action.payload,"poiu");
      return {
        ...state,
        ...action.payload,
      };
    }
    case documentConstants.RESET_CORP_DATA: {
      return {
        ...state,
        clientsArray: [],
        currentlySelectedCorpData:{},
      }
    }
    case documentConstants.SET_CORP_TYPE: {
      return {
        ...state,
        isCorpTrue:action.isCorp,
      }
    }
    default:
      return state;
  }
};
export { CorpReducer };
