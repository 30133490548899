import React, { useState } from "react";
import { Drawer, Input, Spin, Modal, Image, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { appStatusChange } from "../state/actions";
import {
  SearchOutlined,
  QuestionOutlined,
  PlayCircleTwoTone,
} from "@ant-design/icons";
import { EmptyData } from "../../Common/components/EmptyData";
import styles from "../styles/Sidebar.module.css"
import { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

const Help = ({ tabName }) => {
  const dispatch = useDispatch();
  const { helpData, helpDataLoading } = useSelector((state) => state.app);
  const [searchVal, setSearchVal] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [answer, setAnswer] = useState({});
  const [showAns, setShowAns] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [visible, setVisible] = useState(false);
  const [videoId, setVideoId] = useState("");
  const [htmlString, setHtmlString] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const iframeRef = useRef(null)
  const inputRef = useRef();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTab = useMediaQuery({ minWidth:576,maxWidth: 767 });
  const location = useLocation()
  const urlName = location.pathname.startsWith("/bugsReport") || location.pathname.startsWith("/suggestions") || location.pathname.startsWith("/mobilesupport") || location.pathname.startsWith("/yourBugs") || location.pathname.startsWith("/yourSuggestions") || location.pathname.startsWith("/yourTickets")

  // const handleSeeMoreBtn = () => {
  //   window.open("https://resure.realestate/support", "_blank");
  // };

  const handleShowDrawer = () => {
    setShowDrawer(true);
  };

  const onClose = () => {
    setSearchVal("");
    setShowDrawer(false);
  };

  const onAnsClose = () => {
    setShowAns(false);
    setVideoId("");
  };

  const handleCancel = () => {
    setShowVideo(false);
    stopVideo()
  };

  const getVideoId = () => {
    const { videos } = answer;
    for (let key in videos) {
      const parts = videos[key].split("/");
      const videoID = parts[parts.length - 1].split("?")[0];
      if(videoID.length > 0 && parts[parts.length-2] === "youtu.be"){
        setVideoId(videoID);
      }else{
        setVideoId("");
        break;
      }
    }
  };

  const stopVideo = () => {
    const player = iframeRef.current;
    if (player && player.contentWindow && player.contentWindow.postMessage) {
      player.contentWindow.postMessage(
        '{"event":"command","func":"stopVideo","args":""}',
        '*'
      );
    }
  };

  window.handleImage = (imageSrc) => {
    setImageSrc(imageSrc);
    setVisible(true);
  };

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(answer?.content, "text/html");
    const imgElements = doc.querySelectorAll("img");
    imgElements.forEach((img) => {
      const imageSrc = img.getAttribute("src");
      img.setAttribute("onclick", `handleImage('${imageSrc}')`);
    });
    let updatedHtmlString = new XMLSerializer().serializeToString(doc);
    setHtmlString(updatedHtmlString);
    getVideoId();
  }, [showAns]);

  const handleImageCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (searchVal.length > 0) {
      const timeoutId = setTimeout(() => {
        dispatch(appStatusChange.helpApiCalling(tabName, searchVal));
      }, 1000);
      return () => clearTimeout(timeoutId);
    } else {
      if (showDrawer) {
        dispatch(appStatusChange.helpApiCalling(tabName, searchVal));
      }
    }
  }, [dispatch, searchVal, showDrawer]);

  useEffect(() => {
    if(showDrawer){
      const focusTimeout = setTimeout(() => {
        inputRef.current && inputRef.current.focus();
      },100);

      return () => {
        clearTimeout(focusTimeout);
      };
  }
  }, [showDrawer]);

  const styling = `<style>
  img{
      object-fit:contain !important;
  }
  </style>`

  return (
    <>
      <Tooltip title="Help!">
        <div onClick={handleShowDrawer} style={{position: "fixed", bottom: 30, right: 24, zIndex: 12}}>
          <QuestionOutlined
            style={{ fontSize: "29px", cursor: "pointer", backgroundColor: "#085191", padding: "8px", color: "white", borderRadius: "100px" }}
          />
        </div>
      </Tooltip>
      <Drawer
        placement="right"
        open={showDrawer}
        onClose={onClose}
        title={<h2 style={{ textAlign: "center" }}>Help</h2>}
      >
        <div>
          <Input
          ref={inputRef} autoFocus
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
            prefix={<SearchOutlined />}
            style={{ marginLeft: "5%", width: "90%" }}
            allowClear
            type="text"
            placeholder="Type in keyword to search"
          />
        </div>
        {helpDataLoading ? (
          <div style={{ textAlign: "center", margin: "100px 0" }}>
            <Spin size="medium" />
          </div>
        ) : (
          <div style={{ margin: "10px 0" }}>
            <ul>
              {helpData?.length > 0 ? (
                helpData?.map((el) => {
                  return (
                    <li
                      style={{
                        fontSize: "18px",
                        cursor: "pointer",
                        marginTop: "10px",
                      }}
                      onClick={() => {
                        setAnswer(el);
                        setShowAns(true);
                      }}
                    >
                      {el?.title}
                    </li>
                  );
                })
              ) : (
                <EmptyData />
              )}
            </ul>
            {/* {helpData.length > 0 && (
              <p
                onClick={handleSeeMoreBtn}
                style={{
                  marginLeft: "8%",
                  color: "#1990ff",
                  cursor: "pointer",
                }}
              >
                Show more
              </p>
            )} */}
          </div>
        )}
        {/* Answer */}
        <Drawer
          placement="right"
          open={showAns}
          onClose={onAnsClose}
          title={<h2 style={{ textAlign: "center" }}>Help</h2>}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>{answer?.title}</h2>
            {answer?.videos?.length > 0 && videoId.length > 0 ? (
              <PlayCircleTwoTone
                style={{ fontSize: "40px" }}
                onClick={() => {
                  setShowVideo(true);
                }}
              />
            ) : null}
          </div>
          {showAns === true &&
          <div
            dangerouslySetInnerHTML={{ __html: htmlString + styling }}
            style={{
              padding: "10px 10px",
              textOverflow: "ellipsis",
              overflowY: "auto",
            }}
          />
          }
        </Drawer>
      </Drawer>

      {/* Video Modal */}
      <Modal
        open={showVideo}
        footer={false}
        onCancel={handleCancel}
        width={"80%"}
      >
        <div>
          <iframe
            ref={iframeRef}
            src={`https://youtube.com/embed/${videoId}?enablejsapi=1`}
            title={answer?.title}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className={styles.video}
          ></iframe>
        </div>
      </Modal>

      {/* Image modal */}
      <Modal
        open={visible}
        onCancel={handleImageCancel}
        footer={false}
        width={"75%"}
        style={{marginTop:"-90px",maxHeight:"700px"}}
      >
        <Image src={imageSrc} preview={false}  style={{maxHeight:"700px"}} width={"100%"}></Image>
      </Modal>
    </>
  );
};

export { Help };
