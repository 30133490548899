import { Button, Modal, Typography } from "antd";
import React, { useState } from "react";
import { FileAddOutlined, FilePdfOutlined } from "@ant-design/icons";
import "../styles/client.css";
import Title from "antd/lib/typography/Title";
import UploadPdfDoc from "../../PropertyComp/components/UploadPdfDoc";

const DocumentDetailModal = ({ modelDrawer, setModelDrawer, setIsModalVisible, dataElement, isModalVisible, handleOk, handleCancel, createNewDocument, createDocPayload, personDetails, selectedClient, isSmallScreen}) => {
  const Text = Typography;
  const [ allData, setAllData ] = useState({
    uploadPdfDoc: false,
    file: ""
  })

  const handleUploadNew = () => {
    setAllData({
      ...allData,
      uploadPdfDoc: true,
    })
  }

  const handleModalCancel = () => {
    setAllData({
      ...allData,
      uploadPdfDoc: false
    })
  }

  const handleCreateDocCancel = () => {
    handleCancel();
    handleModalCancel();
  }

  return (
    <>
      {modelDrawer.status && (
        <Modal visible={modelDrawer.status} title={<Text style={{ color: "#1890ff", textDecorationLine: "underline" }}>{modelDrawer.title}</Text>} okText="Save" cancelText="Cancel" footer={false} onCancel={() => setModelDrawer({ status: false, component: "", title: "" })}>
          {modelDrawer.component}
        </Modal>
      )}
      <UploadPdfDoc selectedData={{...createDocPayload()}} allData={allData} setAllData={setAllData} handleModalCancel={handleModalCancel} open={allData?.uploadPdfDoc} successFuncAll={handleCreateDocCancel}/>

      <Modal title={<Title style={{textAlign:"center", fontWeight:"bold", fontSize: isSmallScreen ? "15px" : "18px", color: "#178DFA"}}>{dataElement?.name?.toUpperCase()}</Title>} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={[]}>
        <div style={{ display: "flex", flexDirection: "column", maxWidth: "230px", margin: "auto" }}>
          <Button
            style={{
              border: "none",
              fontSize: "18px",
              fontWeight: "bold",
              color: "#2976be",
              background: 'none',
              textAlign: "start",
            }}
            icon={<FileAddOutlined style={{ fontSize: "22px" }} />}
            onClick={createNewDocument}
            disabled={dataElement?.hasOwnProperty("url")}
          >
            Create New
          </Button>
          <Button
            style={{
              border: "none",
              fontSize: "18px",
              fontWeight: "bold",
              color: "#2976be",
              background: 'none',
              textAlign: "start",
              marginTop: "5%"
            }}
            icon={<FilePdfOutlined style={{ fontSize: "22px" }} />}
            onClick={handleUploadNew}
          >
            Upload PDF
          </Button>
        </div>
      </Modal>
    </>
  );
};

export { DocumentDetailModal };
