import React, { useState } from "react";
import {
  Button,
  Avatar,
  List,
  Modal,
  Space,
  Typography,
  Image,
  Row,
  Col,
  Spin,
  Dropdown,
  Menu
} from "antd";
import { StarOutlined, MenuOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles/Listing.module.css";
import { EmptyData } from "../../Common/components/EmptyData";
import { useEffect } from "react";
import { bugReportHandle } from "../state/actions";
import { useHistory } from "react-router-dom";
import { detectWebView, roleAccessFunc} from "../../Common/utils/extraFunctions"
import { userSignUpAction } from "../../User/UserAuthenticate/state/actions";
const { Title } = Typography;

function YourBugsMobile({type}) {
    const { myBugLlistingData, myBugLlistingLoading} = useSelector(
    (state) => state.bugReport
    );
    const { getUserDetailsData, roleAccessed } = useSelector((state) => state.account);
  const [clientcount, setClientCount] = useState(7);
  const [handleModal, setHandleModal] = useState(false);
  const [handleData, setHandleData] = useState(null);
  const [role, setRole] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory()

  const getColorForStatus = (status) => {
    switch (status) {
      case "ACTIVE":
        return "red";
      case "IN_PROGRESS":
        return "#DFC103";
      case "NEEDS_TESTING":
        return "orange";
      case "AWAITING_INPUT":
        return "purple"
      case "INPUT_RECEIVED":
        return "#33CEFF"
      case "COMPLETED":
        return "blue";
      case "CLOSED":
        return "green";
      default:
        return "black";
    }
  };

  const IconText = ({ icon, text, item, handle }) => (
    <Space style={{color : getColorForStatus(text)}}>
      {React.createElement(icon)}
      {text === "NEEDS_TESTING" ? "COMPLETED/NEEDS TESTING" : text === "AWAITING_INPUT" ? "AWAITING-INPUT" : text === "INPUT_RECEIVED" ? "INPUT-RECEIVED" : text === "IN_PROGRESS" ? "IN-PROGRESS" : text}
    </Space>
  );

  let handleModalData = (item, modal) => {
    setHandleModal(modal);
    setHandleData([item]);
  };

  const handlescroll=(e)=>{
    const element=e.target
   if (element.scrollHeight - element.scrollTop-50 <= element.clientHeight+1  ) {
    setClientCount(clientcount + 3);
}}

useEffect(() => {
    dispatch(bugReportHandle.getBugListingDatayourBugs(type,true))
}, [type,dispatch])

let userKey = new URLSearchParams(window.location.search).get(
  "app_request_key"
);
let redirectHome = async () => {
  await window?.ReactNativeWebView?.postMessage("WINDOW_CLOSED");
};
const handleClose = async () => {
  const isInWebView =  detectWebView();
      if (isInWebView) {
      redirectHome();
    } else {
       history.push("/tools");
    }
};
const logout = () => {
  dispatch(userSignUpAction.logOut(history));
};
const dropdownMenu = () => {
  if (userKey === null || userKey === undefined) {
    return (
      <Menu
        style={{ minWidth: "200px" }}
        onClick={(e) => handledropDownMenu(e)}
      >
        <Menu.Item key="0">Bug Listings</Menu.Item>
        <Menu.Item key="5">Suggestions</Menu.Item>
        <Menu.Item key="7">Support</Menu.Item>
        {(roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS")) || role ? <Menu.Item key="1">Your Bugs</Menu.Item> : null}
        {(roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS")) || role ? <Menu.Item key="6">Your Suggestions</Menu.Item> : null}
        {(roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS")) || role ? <Menu.Item key="8">Your Tickets</Menu.Item> : null}
        <Menu.Item key="4">Close</Menu.Item>
      </Menu>
    );
  }
  return (
    <Menu
      style={{ minWidth: "200px" }}
      onClick={(e) => handledropDownMenu(e)}
    >
      <Menu.Item key="0">Bug Listings</Menu.Item>
      <Menu.Item key="5">Suggestions</Menu.Item>
      <Menu.Item key="7">Support</Menu.Item>
      {(roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS")) || role ? <Menu.Item key="1">Your Bugs</Menu.Item> : null}
      {(roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS")) || role ? <Menu.Item key="6">Your Suggestions</Menu.Item> : null}
      {(roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS")) || role ? <Menu.Item key="8">Your Tickets</Menu.Item> : null}
      {/* <Menu.Item key="2">Report Bug</Menu.Item> */}
      <Menu.Item key="4">Close</Menu.Item>
      {/* <Divider />
      <Menu.Item key="3">Logout</Menu.Item> */}
    </Menu>
  );
};
let handledropDownMenu = (e) => {
  switch (e.key) {
    case "0": {
      history.push("/bugsReport");
      break;
    }
    case "1": {
      history.push("/yourBugs");
      break;
    }
    // case "2": {
    //   handleReportBug();
    //   break;
    // }
    case "3": {
      logout();
      break;
    }
    case "4": {
      handleClose()
      break;
    }
    case "5": {
      history.push("/suggestions");
      break;
    }
    case "6": {
      history.push("/yourSuggestions");
      break;
    }
    case "7": {
       history.push("/mobilesupport");
      break;
    }
    case "8": {
      history.push("/yourTickets");
      break;
    }
    default:
      break;
  }
};

useEffect(() => {
  for (let key in getUserDetailsData?.roles) {
    if (getUserDetailsData?.roles[key] === "RESURE_SUPPORT") {
      setRole(true);
      break;
    } else {
      setRole(false);
    }
  }
}, [getUserDetailsData?.roles]);

  return (
    <>
      <Modal
        title="My Bug"
        visible={handleModal}
        footer={false}
        onCancel={() => handleModalData(null, false)}
      >
        <List
          itemLayout="vertical"
          size="large"
          dataSource={handleData}
          renderItem={(item) => (
            <List.Item
              key={item.title}
              actions={[
                <Typography.Title
                  level={5}
                  style={{
                    margin: 0,
                  }}
                >
                  Bug Id: {item._id}
                </Typography.Title>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    size={65}
                    style={{
                      background: "#085191",
                      // marginLeft: "40%",
                    }}
                    src={item.createdBy.profileImg.original}
                  >
                    {item &&
                      (item?.createdBy?.firstName?.[0] ? item.createdBy.firstName[0]?.toUpperCase() : "") +
                        " " +
                      (item?.createdBy?.lastName?.[0] ? item.createdBy.lastName[0]?.toUpperCase() : "")}
                  </Avatar>
                }
                title={item?.title?.toUpperCase()}
                description={
                  (item?.createdBy?.firstName ? item.createdBy.firstName : "") + " " + (item?.createdBy?.lastName ? item.createdBy.lastName : "")
                }
              />
              <Row gutter={[24, 24]}>
                <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <div dangerouslySetInnerHTML={{ __html: item?.content?.[0]?.description }} />
                </Col>
                {item?.imageUrls &&
                <Col
                  xs={24}
                  sm={24}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                {item.imageUrls.map(el=>(
                  <Image
                    width={200}
                    alt="logo"
                    src={el}
                  />))}
                </Col>
                }
              </Row>
            </List.Item>
          )}
        />
      </Modal>
      {myBugLlistingLoading ? (
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30%",
          }}
        >
          <Spin size="large" />
        </div>
      ) : myBugLlistingData && myBugLlistingData?.length > 0 ? (
        <div
          style={{
            background: "#fff",
            position: "relative",
            // marginBottom: "-25px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              position: "sticky",
            //   top: "65px",
            //   padding: "30px 0px 20px 0px",
            //   zIndex: "1",
            display: "flex",
              width: "100%",
              height:"50px",
              background: "white",
              paddingTop:"10px",
             boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
          >
            <span style={{marginLeft:"5%"}}>
              <Dropdown overlay={dropdownMenu} trigger={["click", "hover"]}>
                <MenuOutlined style={{ color: "black",fontSize:"22px",marginTop:"35%"}} />
              </Dropdown>
            </span>
            <Title level={3} style={{marginLeft:type === "SUGGESTIONS" ? "20%" : "25%"}}>{type==="BUGS" ? 'Your Bugs' : type==="SUGGESTIONS" ? 'Your Suggestions' : "Your Tickets"}</Title>
          </div>
          <div style={{overflowX:"hidden",overflowY:"auto",height:"80vh",marginTop:"-50px"}} className={styles.yourBugsInMobile}  onScroll={handlescroll}>
          <List
            className={styles.yourBugsInMobile}
            itemLayout="horizontal"
            dataSource={myBugLlistingData}
            style={{
              width: "100vw",
              height: "auto",
              background: "#fff",
              padding: "0rem 1rem 0rem 1.5rem",
              marginRight: "10px",
              overflowY: "auto",
              overflowX: "hidden",
              marginTop:"60px"
            }}
            renderItem={(item, index) => {
              if (index < clientcount)
                return (
                  <List.Item
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleModalData(item, true)}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          size={65}
                          style={{
                            background: "#085191",
                          }}
                          src={item.createdBy.profileImg.original}
                        >
                          {item &&
                            (item?.createdBy?.firstName?.[0] ? item.createdBy.firstName[0]?.toUpperCase() : "") +
                              " " +
                            (item?.createdBy?.lastName?.[0] ? item.createdBy.lastName[0]?.toUpperCase() : "")}
                        </Avatar>
                      }
                      title={
                        <span className={styles.yourBugsTitle}>
                          {item.title.toUpperCase()}
                        </span>
                      }
                      description={
                        <IconText
                          icon={StarOutlined}
                          text={item?.status}
                          key="list-vertical-star-o"
                          item={item}
                          handle={null}
                        />
                      }
                    />
                  </List.Item>
                );
            }}
          />
          </div>
        </div>
      ) : (
        <>
        <div
            style={{
              textAlign: "center",
              position: "sticky",
              display: "flex",
              width: "100%",
              height:"50px",
              background: "white",
              paddingTop:"10px",
             boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
          >
            <span style={{marginLeft:"5%"}}>
              <Dropdown overlay={dropdownMenu} trigger={["click", "hover"]}>
                <MenuOutlined style={{ color: "black",fontSize:"22px",marginTop:"35%"}} />
              </Dropdown>
            </span>
            <Title level={3} style={{marginLeft:type === "SUGGESTIONS" ? "20%" : "25%"}}>{type==="BUGS" ? 'Your Bugs' : type==="SUGGESTIONS" ? 'Your Suggestions' : "Your Tickets"}</Title>
          </div>
        <div
          style={{
            display: "flex",
            height: "100vw",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <EmptyData />
        </div>
        </>
      )}
    </>
  );
}

export { YourBugsMobile };
