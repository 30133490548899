import React, { useState, useEffect, useRef } from "react";
import { Menu, Avatar, Typography, Button } from "antd";
import styles from "../style/landingPage.module.css"
import perecent10 from "../../Assets/10.00%.png";
import perecent6 from "../../Assets/6.00%.png";
import gross from "../../Assets/grossChart.png";
import totalTrans from "../../Assets/totaltrans.png";
import arrow from "../../Assets/asset-1.png";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

import "../style/Landing.css";
const { Title } = Typography;

const CurrenClient = () => {
  const ref = useRef(null);
  // const [mytext, setMytext] = useState({
  //   fontWeight: 500,
  //   fontSize: "20px",
  //   color: "#5c5555",
  // });

  useEffect(() => {
    // if (ref.current.offsetWidth < 140) {
    //   setMytext({
    //     fontWeight: 500,
    //     fontSize: "20px",
    //     color: "#5c5555",
    //     whiteSpace: "nowrap",
    //     overflow: "hidden",
    //     textOverflow: "ellipsis",
    //     width: "100px",
    //     cursor: "pointer",
    //   });
    // }
  }, []);

  return (
    <>
      <div className="metrics">
        <div style={{ padding: "0.5%", width: "100%", marginTop: "3%",overflowX:"hidden" }}>
          <div
            style={{
              border: "1px solid lightgrey",
              borderRadius: "8px",
              padding: "10px",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              borderLeft: "5px solid rgb(60, 179, 113)",
              textAlign: "center",
             
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "5px",
              }}
            >
              <div ref={ref}>
                <div className={styles.mytext}>ACTIVE CLIENTS</div>
              </div>
              <div>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "20px",
                    color: "#5c5555",
                 
                  }}
                  className={styles.changeclient}
                >
                  Change
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                height: "30px",
                paddingBottom: "3px",
              }}
            >
              <div>
                <Title level={4} style={{ fontSize: "20px", color: "#8C8C8C" }}>
                  500
                </Title>
              </div>
              <div>
                <Title level={4} style={{ fontSize: "20px", color: "green" }}>
                  <ArrowUpOutlined />
                  60%
                </Title>
              </div>
            </div>
          </div>

          <div
            style={{
              border: "1px solid lightgrey",
              borderRadius: "8px",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              padding: "10px",
              marginTop: "10px",
              borderLeft: "5px solid red",
              textAlign: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div className={styles.mytext}>CURRENT CLIENTS</div>
              </div>
              <div>
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: "20px",
                    color: "#5c5555",
                  }}
                  className={styles.changeclient}
                >
                  Change
                </p>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                height: "30px",
                paddingBottom: "3px",
              }}
            >
              <div style={{ height: "40px" }}>
                <Title
                  level={4}
                  style={{ fontSize: "1.2rem", color: "#8C8C8C" }}
                >
                  320
                </Title>
              </div>
              <div>
                <Title level={4} style={{ fontSize: "1.2rem", color: "red" }}>
                  <ArrowDownOutlined />
                  20%
                </Title>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div>
            <Title
              level={4}
              style={{
                fontSize: "1.3rem",
                fontWeight: 700,
                color: "cornflowerblue",
                marginTop: "10px",
              }}
            >
              GROSS SALES
            </Title>
          </div>
        </div>
        <div
          style={{
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "8px",
            border: "1px solid lightgrey",
            borderLeft: "5px solid SlateBlue",
            textAlign: "center",
            minHeight:"15vh"
          }}
        >
          {/* <img
            src={gross}
            style={{ maxWidth: "100vh", width: "80%", marginTop: "10px" }}
          /> */}
          <p style={{fontSize:"24px",marginTop:"40px",color:"#000000",zIndex:100,fontWeight:"bold"}}>Coming Soon...</p>
        </div>

        <div
          style={{
            borderBottom: "3px solid #EFEFEF",
            paddingTop: "10px",
            paddingBottom: "15px",
          }}
        >
          <div>
            <p
              style={{
                fontSize: "1.3rem",
                fontWeight: 700,
                color: "cornflowerblue",
                marginTop: "10px",
              }}
            >
              TOTAL TRANSACTIONS
            </p>
          </div>

          <div
            style={{
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              borderRadius: "8px",
              border: "1px solid lightgrey",
              borderLeft: "5px solid rgb(255, 165, 0)",
              textAlign: "center",
            }}
          >
            <img
              src={totalTrans}
              style={{
                maxWidth: "100vh",
                width: "100%",
                marginTop: "10px",
                padding: "5px",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrenClient;
