import React, { useEffect, useMemo, useState } from "react";
import { List, Avatar, Typography, Button, Row, Col, Image } from "antd";
import styles from '../style/NewsLetterComponent.module.css'
import NewsLetterBox from "./NewsLetterBox";
import googleplay from "../../Assets/Icons/google-play.png"
import Logo from "../../Assets/Icons/RS_color.png";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { landingPageAction } from "../state/actions";
import { Link, useHistory } from "react-router-dom";
const { Title, Paragraph, Text } = Typography;

const NewsLetterComponent = () => {
  const today = moment();
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.landingPageReducer.newsletterReducer);
//   useEffect(() => {
//     // dispatch(landingPageAction.getNewsletterPageData());
//   }, []);

const memoizedData = useMemo(() => {
    if (data.length!==0) {
      return data;
    } else {
      dispatch(landingPageAction.getNewsletterPageData());
      return null;
    }
  }, [data,dispatch]);


  const formattedDate = today.format("MM-DD-YYYY");

  const dateFormate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString(undefined, options);
  }
  const handleRedirect = (link) => {
    link ? window.open(link) : window.open('https://play.google.com/apps/internaltest/4700887751399815225');
  }

  return(
    <div style={{height:"100%",}}>
         <div
        style={{
          color: "grey",
          fontSize: "18px",
          textAlign: "center",
          fontWeight: "bold",
          margin: "auto",
          width: "auto",
          position: "sticky",
          top: "0",
          zIndex: "100",
          background: "#fff",
          padding:"10px",
          borderBottom:"1px solid grey"
        }}
      >
        NEWSLETTERS
      </div>
        {data &&
        data.map((el, index) => {
        const elements = [];

        if (el.imageURLs.length > 0) {
        el.imageURLs.forEach((imageURL, imgIndex) => {
            elements.push(<div style={{textAlign:"center",color:"grey",fontSize:"18px",fontWeight:"bold",border:"2px solid grey",padding:"5px",borderRadius:"5px",marginTop:"10px"}}>
            <p>{el?.heading}</p>
            <img key={`image_${index}_${imgIndex}`} src={imageURL} alt="Newsletter image" width={"100%"} minHeight="600px" />
            </div>
            );
        });
        } else {
        // Display the PDF link
        const pdfLink = el.pdfURLLink;

        elements.push(
        <div style={{textAlign:"center",color:"grey",fontSize:"18px",fontWeight:"bold",border:"2px solid grey",padding:"5px",borderRadius:"5px",marginTop:"10px"}}>
        <p>{el?.heading}</p>
        <iframe key={`iframe_${index}`} src={`${pdfLink}#toolbar=0`} width={"100%"} className={styles.newsletter}/>

        </div>
        );
        }

        return (
        <div key={`element_${index}`} className="element-container">
            {elements}
        </div>
        );
    })}









    </div>
  )
//   if (data && data[0] && data[0].pdfURLLink && data[0].pdfURLLink.length) {
//     const { pdfURLLink } = data[0];
//     return (
//       <div className={styles.readyMadeNewsletterPdf}>
//         <iframe src={pdfURLLink} className={styles.iframe} style={{border:'none', padding:0, margin:0}} width='100%' height='500px'></iframe>
//       </div>
//     )
//   } else if (data && data[0] && data[0].heading && data[0].sections && data[0].sections.length && data[0].sections[0].title && data[0].sections[0].content) {
//     const { heading, sections, publishDate, imageURLs, webReleaseDate, mobileReleaseDate, googlePlayStoreLink } = data[0];
//     return (
//       <div className={styles.container}>
//         <div className={styles.title}>
//           <Title level={1} className={styles.h1}>{heading}</Title>
//         </div>
//         <div className={styles.col}>
//           <div className={styles.Logo}>
//             <img src={Logo} alt="RE-Sure logo" />
//           </div>
//           <div className={styles.date}>
//             <Text className={styles.dateVal}>{dateFormate(publishDate) || formattedDate}</Text>
//           </div>
//         </div>
//         <div className={styles.subContainer}>
//           <div className={styles.col1}>
//             {
//               imageURLs?.map((el, i) => {
//                 return (
//                   <div className={styles.boxPoster} key={i}>
//                     <img src={el} alt="RE-Sure poster" />
//                   </div>
//                 )
//               })
//             }
//             <Row style={{ backgroundColor: 'aliceblue', padding: '1rem' }}>
//               <Row xs={24} sm={24} md={24} lg={14} xl={24} style={{ width: '100%' }}>
//                 <h2 className={styles.theFutureTitle}>The Future</h2>
//               </Row>
//               <Row xs={24} sm={24} md={24} lg={14} xl={24} style={{ width: '100%' }}>
//                 <h3 style={{ color: '#5969a9', fontWeight: 'bold' }} level={5}>Next Web Release: <span style={{ color: 'black' }}>{dateFormate(webReleaseDate)}</span></h3>
//                 <h3 style={{ color: '#5969a9', fontWeight: 'bold' }} level={5}>Next Mobile Release: <span style={{ color: 'black' }}>{dateFormate(mobileReleaseDate)}</span></h3>
//               </Row>
//               <Row style={{ maxWidth: '95%' }}>
//                 <Col>
//                   <strong style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Android Users: </strong>
//                 </Col>
//                 <div style={{ display: 'flex', width: '90%', gap: '20px' }}>
//                   <Col>
//                     <p style={{ textAlign: "justify" }}>
//                       In order for you to install the latest release you'll need to tap the provided link from your mobile device.
//                     </p>
//                   </Col>
//                   <Row onClick={() => handleRedirect(googlePlayStoreLink || null)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
//                     <img className={styles.playstoreImg} src={googleplay} alt="Google play store logo" />
//                     <p style={{ whiteSpace: 'nowrap' }} className={styles.playstoreLink}><u>Google Play</u></p>
//                   </Row>

//                 </div>
//               </Row>
//               <Row style={{ maxWidth: '95%' }}>
//                 <Col>
//                   <strong style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>IOS Users: </strong>
//                 </Col>
//                 <div style={{ display: 'flex', width: '90%', gap: '20px' }}>
//                   <Col>
//                     <p style={{ textAlign: "justify" }}>
//                       Your device will automatically
//                       notify you when an update is available
//                     </p>
//                   </Col>
//                 </div>
//               </Row>
//               <strong>If for any reason our web application is not working, please refresh your page before submitting a bug report:
//               </strong>
//               <Paragraph>
//                 Press Shift and the refresh button on your browser at the same time.
//               </Paragraph>
//             </Row>
//           </div>
//           <div className={styles.col1}>
//             {sections?.map((el, i) => {
//               let props = {
//                 title: el.title,
//                 content: el.content,
//                 videos: el.videos ? el.videos : null
//               };
//               return <NewsLetterBox key={el?.title} data={props} />
//             })}
//           </div>
//         </div>
//       </div>
//     )
//   }
//   else if (data && data[0] && data[0].imageURLs && data[0].imageURLs[0]) {
//     const { imageURLs } = data[0];
//     return (
//       <div className={styles.readyMadeNewsletterImg}>
//         <img src={imageURLs[0]} alt="newsletter image" />
//       </div>
//     )
//   } else {
//     return (
//       <div style={{display:"flex", flexDirection:'column',justifyContent:'center',textAlign:'center', width:'100%',paddingTop:'30px'}}>
//         <Title level={4}>Newsletter will be uploaded soon...</Title>
//         <Link to="/">
//           <Button type="primary">Back to Home</Button >
//         </Link>
//       </div>
//     )
//   }
};

export default NewsLetterComponent;
