/* eslint-disable react-hooks/exhaustive-deps */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Spin, Tag, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { accountHandle } from '../state/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation} from 'react-router-dom';
import styles from "../styles/Delegates.module.css";

const { confirm } = Modal;

export default function DelegateAssigned({ setDelegateSelected, handlePartialDelegate, handleCancel }) {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [delegateAssigned, setDelegateAssigned] = useState([]);
    const { updateDelegate, getDelegateData, getDelegateDataLoading, getDelegateDataError } = useSelector((state) => state.account);
    
    const activeKey = (pathname === "/account/delegates") ? "1" : "2";
    useEffect(()=>{
        if(activeKey === "1"){
            dispatch(accountHandle.getDelegateData("DELEGATES_ASSIGNED"))
        }
    },[pathname, updateDelegate])

    useEffect(() => {
        let postDelegateAssigned = [];
        if(getDelegateData?.delegatesAssigned){
          getDelegateData?.delegatesAssigned?.forEach((element) => {
            postDelegateAssigned.push({
              userDetails: {
                fullName: element?.personId?.fullName,
                email: element?.personId?.email,
                profileImg: element?.personId?.profileImg
              },
              delegateAssignedId: element?._id,
              _id: element?.personId?._id,
              officeName: element?.personId?.brokerageData?.brokerageName || "-",
              action: element?.isUsingAccount,
              specificTransaction: element?.specificTransaction,
            });
          });
          setDelegateAssigned(postDelegateAssigned);
        }
    }, [getDelegateData]);

    const handleRemoveDelegate = (data, currentName) => {
        let selectedUser;
        selectedUser = data?.fullName;
        confirm({
            title: `Are you sure you want to remove ${
                (selectedUser ? selectedUser + " to" : "") || "user from"
            } ${currentName}'s account?`,
            okText: "Yes",
            cancelText: "No",
            icon: <ExclamationCircleOutlined />,
            content: "",
            onOk() {
                let obj = { 
                    delegateRemoved: data._id,
                    delegateValue : "DELEGATES_ASSIGNED"
                };
                dispatch(accountHandle.updatePersonCreated({...obj}));
                handleCancel();
            },
            onCancel() {},
        });
    };

    return (
        <div>
            {getDelegateDataLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <Spin size="large"/>
                </div>
            ): getDelegateDataError ? (
                <div>
                    { message.error("Oops!. Please try again later.")}
                </div>
            ):(
                <div className={styles.tableContainer}>
                    <table
                        style={{ borderCollapse: "collapse", border: "1px solid #bfbfbf" }}
                    >
                        <thead>
                            <tr>
                            <th
                                style={{
                                width: "15%",
                                background: "#2171B9",
                                color: "whitesmoke",
                                }}
                            >
                                USERS
                            </th>
                            <th
                                style={{
                                width: "15%",
                                background: "#2171B9",
                                color: "whitesmoke",
                                }}
                            >
                                OFFICE NAME
                            </th>
                            <th
                                style={{
                                width: "15%",
                                background: "#2171B9",
                                color: "whitesmoke",
                                }}
                            >
                                STATUS
                            </th>
                            <th
                                style={{
                                width: "15%",
                                background: "#2171B9",
                                color: "whitesmoke",
                                }}
                            >
                                ACTION
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            {delegateAssigned.map((item, index) => (
                            <tr key={index}>
                                <td>
                                <div>
                                    <div
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        color: "grey",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                    >
                                    <p>
                                        {item?.userDetails?.fullName
                                        ? item?.userDetails?.fullName
                                        : "-"}
                                    </p>
                                    {!item?.specificTransaction ? (
                                        <Tag
                                        color="green"
                                        style={{
                                            height: "25%",
                                            width: "25%",
                                            textAlign: "center",
                                            cursor: "pointer",
                                        }}
                                        onClick={
                                            () =>{
                                                setDelegateSelected(item?.userDetails);
                                                handlePartialDelegate(item?._id, false)
                                            }
                                        }
                                        >
                                        Full
                                        </Tag>
                                    ) : (
                                        <Tag
                                        color="blue"
                                        style={{
                                            height: "25%",
                                            width: "25%",
                                            textAlign: "center",
                                            cursor: "pointer",
                                        }}
                                        onClick={
                                            () =>{
                                                setDelegateSelected(item?.userDetails);
                                                handlePartialDelegate(item?._id, false)
                                            }
                                        }
                                        >
                                        Limited
                                        </Tag>
                                    )}
                                    </div>
                                    <div
                                    style={{
                                        fontSize: "11px",
                                        color: "#787474",
                                        marginTop: "-5%",
                                    }}
                                    >
                                    {item?.userDetails?.email
                                        ? `${item?.userDetails?.email}`
                                        : ""}
                                    </div>
                                </div>
                                </td>
                                <td>
                                <div>
                                    <div
                                    style={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        color: "grey",
                                        textAlign: "center",
                                    }}
                                    >
                                    {item?.officeName}
                                    </div>
                                </div>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    {item?.action ? (
                                    <div
                                        style={{
                                        fontSize: "14px",
                                        display: "flex",
                                        gap: "5px",
                                        justifyContent: "center",
                                        }}
                                    >
                                        <p style={{ color: "red", fontWeight: "bold" }}>
                                        ACTIVE
                                        </p>
                                    </div>
                                    ) : (
                                    <div
                                        style={{
                                        fontSize: "14px",
                                        display: "flex",
                                        gap: "5px",
                                        justifyContent: "center",
                                        }}
                                    >
                                        <p style={{ color: "grey", fontWeight: "bold" }}>
                                        INACTIVE
                                        </p>
                                    </div>
                                    )}
                                </div>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                <div>
                                    <Button
                                    type="danger"
                                    onClick={() =>
                                        handleRemoveDelegate(
                                        item,
                                        item?.userDetails?.fullName
                                        )
                                    }
                                    >
                                        Remove
                                    </Button>
                                </div>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table> 
                </div>
                
            )}
        </div>
    )
}
