import { clientConstants } from "../action-types";

const initialState = {
  documetsClientLoading: false,
  currentCLientDetails: [],
  currentCLientLoading: false,
  currentCLientError: null,
};

const currentClientReducer = (state = initialState, action) => {
  switch (action.type) {
    // current Client
    case clientConstants.CURRENT_CLIENTS_LOADING:
      return {
        ...state,
        currentCLientDetails: [],
        documetsClientLoading: true,
        currentCLientError: false,
      };
    case clientConstants.CURRENT_CLIENTS_SUCCESS:
      return {
        ...state,
        currentCLientDetails: action.currentCLientDetails,
        // globalClientLoading: false,
        currentCLientError: false,
      };
    case clientConstants.CURRENT_CLIENTS_FAILURE:
      return {
        ...state,
        currentCLientError: true,
        currentCLientLoading: false,
      };
    default:
      return state;
  }
};
export { currentClientReducer };
