import React, { useEffect } from 'react'
import { useState } from 'react'
import { DocumentSign } from './DocumentSign'
import { EmailFile } from './EmailFile'
import { useDispatch, useSelector } from 'react-redux'
import { clientScreenHandle } from './state/actions'
import { Tabs } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import { Help } from '../App/components/Help'
import styles from "./styles/ClientScreen.module.css"
const DefaultScreen = () => {

  return (
    <div  style={{width:"100%"}}  >
    <div style={{display:"flex",width:"100%",fontSize:"15px"}}>
    <Tabs defaultActiveKey="1"  className={styles.responsivetabs} tabBarExtraContent={ <div style={{margin:"5px 5px 0 0"}}>
      <Help tabName="CLIENT_SCREEN"/>
    </div>}> 
      <TabPane tab={<div className={styles.tabname}>Document Signing</div>} key="1" style={{width:"100%",height:"100%",fontSize:"10px"}}>
      <DocumentSign  />
      </TabPane>
      <TabPane tab={<div className={styles.tabname}>Email & Files</div>}  key="2" style={{width:"100%",height:"100%"}}>
      <EmailFile/>
      </TabPane>

    </Tabs>
    {/* <div style={{border:"1px solid #888888",width:"50%",textAlign: "center",backgroundColor:toggle?"#96c8f6":"transparent",cursor:"pointer"}} onClick={()=>setToggle(true)}>Document Signing</div>
    <div style={{border:"1px solid #888888",width:"50%", textAlign: "center",cursor:"pointer",backgroundColor:!toggle?"#96c8f6":"transparent"}} onClick={()=>setToggle(false)}> Email & Files</div> */}
    </div>
     {/* <div style={{ width:"98%",
    
     height:"calc(100vh - 8.4rem)",
     margin:"auto",
     marginTop:"10px",
       padding:"5px",
       border:"1px solid #0078d4", 
       borderTopLeftRadius: "5px",
       borderTopRightRadius: "5px",
       borderBottomRightRadius: "5px",
       borderBottomLeftRadius: "5px",
       }} >{toggle?<DocumentSign  />:<EmailFile/>}</div> */}
     </div>
  )
}

export {DefaultScreen}