import { Avatar, Modal, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { documentAction } from "../../DocumentSection/state/actions";
import { EditTwoTone, MailOutlined, UserOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { formatPhoneNumber, generateInitialsFromObj } from "../../Common/utils/extraFunctions";
import { BsTelephone } from "react-icons/bs";

const SignersModal = ({ state, setState, item }) => {
  const {Text} = Typography
  const dispatch = useDispatch();
  let { clientsArray } = useSelector((state) => state.documentSection.CorpReducer);
  let agentType = item?.isProperty || item?.isErts ? "SELLER_AGENT" : "BUYER_AGENT";
  const isMobile = useMediaQuery({ maxWidth: 470 });

  useEffect(() => {
    if (agentType === "SELLER_AGENT" && state.isSignersModal) {
      dispatch(documentAction.setCorpState({ clientsArray: item?.sellerIds }));
    } else if (agentType === "BUYER_AGENT" && state.isSignersModal) {
      dispatch(documentAction.setCorpState({ clientsArray: item?.buyerIds }));
    }
  }, [item, state.isSignersModal, agentType]);

  return (
    <>
      <Modal
        open={state.isSignersModal}
        footer={false}
        onCancel={() => setState({ ...state, isSignersModal: false })}
        width={600}
        title={
          <div style={{ display: "flex", justifyContent: "center", gap: "30px" }}>
            <p style={{ display: "flex", justifyContent: "center", color: "#178DFA", fontWeight: "bold", fontSize: "22px", height: "0px" }}>{agentType === "SELLER_AGENT" ? "Sellers" : "Buyers"}</p>
            {agentType === "SELLER_AGENT" && !item?.persistId && (
              <span onClick={() => setState({ ...state, isUseDetailModal: true })}>
                <Tooltip placement="top" title="Edit Sellers">
                  <EditTwoTone style={{ fontSize: "18px", cursor: "pointer" }} />
                </Tooltip>
              </span>
            )}
          </div>
        }
        style={{ maxHeight: "90%", marginTop:"-30px" }}
      >
        <div style={{ maxHeight:"calc(100vh - 13.5rem)", overflowY: "auto", marginLeft: isMobile ? "5px" : "40px", marginTop: "-10px" }}>
          {clientsArray
            ?.filter((ele) => !ele.isCorp)
            ?.map((ele, i) => {
              const initials = generateInitialsFromObj(ele);
              return (
              <ul key={i} style={{ marginLeft: "10px" }}>
                <li style={{ display:"flex",fontSize: "18px", margin:"0 0 25px -15px",listStyleType:"none"}}>
                  <div style={{ display:"flex", flexDirection:"column" }}>
                  <span style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}>
                      <Avatar 
                        src={ele?.personId?.profileImg?.original} 
                        size={35} 
                        style={{ marginRight: "8px", fontWeight: "normal", fontSize: "16px", border: "1px solid #085191", background: "#085191" }}
                      >
                        {!ele?.personId?.profileImg?.original && initials ? initials : <UserOutlined style={{ fontSize: "20px" }} />}
                      </Avatar>
                      <span>{ele?.fullName}</span>
                    </span>
                    {ele?.personId?.email && (
                      <span style={{ display: "flex", alignItems: "center", width: "95%", fontSize: "16px", margin: "5px 0 0 8px" }}>
                        <MailOutlined style={{ fontSize: "20px", marginRight: "15px" }} />
                        <Text ellipsis copyable>{ele?.personId?.email}</Text>
                      </span>
                    )}
                    {ele?.personId?.mobilePhoneNumber && (
                      <span style={{ display: "flex", alignItems: "center", fontSize: "15px", margin: "3px 0 0 10px", width: "95%" }}>
                        <BsTelephone size={18} style={{ marginRight: "15px" }} />
                        <Text ellipsis="rows" copyable>{formatPhoneNumber(ele?.personId?.mobilePhoneNumber?.phoneNumber)}</Text>
                      </span>
                    )}
                  </div>
                </li>
              </ul>
            )})}

          {clientsArray
            ?.filter((ele) => ele.isCorp)
            ?.map((ele, i) => (
              <div key={i} style={{ marginLeft: "10px" }}>
                <ul>
                  <li style={{ fontSize: "18px", display:"flex",listStyleType:"none",alignItems:"center", marginLeft:"-15px" }}>
                    <span style={{fontSize: "18px",color: "orange", fontWeight:"bold"}}>{ele?.fullName || ""} {" Signers"}</span>
                  </li>
                </ul>
                {ele.signers?.map((signer, idx) => {
                  const initials = generateInitialsFromObj(signer);
                  return (
                  <div key={idx} style={{ margin: "0 0 0 25px" }}>
                    <ul>
                      <li style={{ display:"flex",fontSize: "16px", margin:"0 0 35px -15px" }}>
                        <div style={{ display:"flex", flexDirection:"column" }}>
                          <span style={{fontWeight:"bold"}}>
                          <Avatar src={signer?.personId?.profileImg?.original} size={35} style={{ margin: "auto", fontWeight:"normal", fontSize: "16px", border: "1px solid #085191", background: "#085191" }}>
                            {!signer?.personId?.profileImg?.original && initials ? initials : <UserOutlined style={{ fontSize: "20px" }} />}
                          </Avatar>
                          <span style={{marginLeft:"7px"}}>{signer?.fullName}</span>
                          </span>
                          {signer?.personId?.email && (
                            <span style={{ display: "flex", alignItems: "center", width: "95%", fontSize: "16px", margin: "5px 0 0 8px" }}>
                              <MailOutlined style={{ fontSize: "20px", marginRight: "15px" }} />
                              <Text ellipsis copyable>{signer?.personId?.email}</Text>
                            </span>
                          )}
                            {signer?.personId?.mobilePhoneNumber && (
                              <span style={{ display: "flex", alignItems: "center", fontSize: "15px", margin: "3px 0 0 10px", width: "95%" }}>
                                <BsTelephone size={18} style={{ marginRight: "14px" }} />
                                <Text ellipsis="rows" copyable>{formatPhoneNumber(signer?.personId?.mobilePhoneNumber?.phoneNumber)}</Text>
                              </span>
                            )}
                        </div>
                      </li>
                    </ul>
                  </div>
                )})}
              </div>
            ))}
        </div>
      </Modal>
    </>
  );
};

export { SignersModal };
