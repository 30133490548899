import { documentConstants } from "../action-types";

const initialState = {
  realtorData: {},
  realtorError: null,
  buyerData: [],
};

const realtorReducer = (state = initialState, action) => {
  switch (action.type) {
    case documentConstants.REALTOR_CREATE_REQUEST_SUCCESS:
      return {
        ...state,
        realtorData: action.payload,
        realtorError: null,
      };

    case documentConstants.REALTOR_CREATE_REQUEST_ERROR:
      return {
        ...state,
        realtorData: {},
        realtorError: null,
      };

    case documentConstants.BUYER_CREATE_REQUEST_SUCCESS:
      return {
        ...state,
        buyerData: action.payload,
        realtorError: null,
      };

    case documentConstants.BUYER_CREATE_REQUEST_ERROR:
      return {
        ...state,
        buyerData: {},
        realtorError: null,
      };

    default:
      return state;
  }
};
export { realtorReducer };
