import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Col, Modal, Row, Input, Form } from "antd";
import {
  FolderFilled,
  FolderOpenOutlined,
  FileZipOutlined,
  CaretRightOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { clientAction } from "../state/actions";
import { useHistory } from "react-router-dom";
import { getCategoryDataHandle } from "../../PropertyComp/utils/helper";
import axios from "axios";
const StyledTree = styled.div`
  line-height: 1.5;
`;
const StyledFile = styled.div`
  padding-left: 20px;
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
  }
`;
const StyledFolder = styled.div`
  padding-left: 20px;

  .folder--label {
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }
`;
const Collapsible = styled.div`
  height: ${(p) => (p.isOpen ? "auto" : "0")};
  overflow: hidden;
`;

const File = ({
  name,
  currentCLientDetails,
  id,
  items,
  handleMenuChange,
  propertyId,
  status,
  setSelectedClientData,
  source
}) => {
   const [taskModalVisible, setTaskModalVisible] = useState(false);
 
  const handleCancelTask = () => {
    setTaskModalVisible(false);
  };

  const history = useHistory();
  const handleredirectproperty = () => {
    let data = {
      ...items,
      openFor: source === "offer" ? "BUYER_AGENT" : source === "listing" ? "SELLER_AGENT" : null,
      ...(source === "offer" ? { offerId: items._id } : {})
    };
    
    const {category,categoryId, status}= getCategoryDataHandle({notificatonData:data})
    history.push({ pathname: "/transactions", state: { category, categoryId, status} });

  };
  return (
    <StyledFile>
    <span
        onClick={() => (id ? "" : handleredirectproperty())}
        style={{
          fontSize: "20px",
          fontWeight: 500,
          color: "#8C8C8C",
          cursor: "pointer",
        }}
      >
      
        {(currentCLientDetails?.offerList?.length > 0 ||
          currentCLientDetails?.offerList?.length === undefined) &&
        (currentCLientDetails?.listingList?.length > 0 ||
          currentCLientDetails?.listingList?.length === undefined) ? (
          <>
            <FileZipOutlined style={{ fontSize: "22px" }} />
            {name}
          </>
        ) : (
          ""
        )}
      </span>

      <Modal visible={taskModalVisible} onCancel={handleCancelTask} footer={[]}>
        <div
          style={{
            marginTop: "10%",
          }}
        >
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 24 }}
            initialValues={{
              task: "",
            }}
            onFinish={handleCancelTask}
            autoComplete="off"
          >
            <Form.Item
              name="task"
              // rules={[{ required: true, message: "Please input your Email!" }]}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // width: "70%",
                }}
              >
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#8C8C8C",
                  }}
                >
                  Task
                </p>
                <Input
                  style={{ height: "40px", marginLeft: "30px", width: "370px" }}
                />
              </div>
            </Form.Item>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save Task
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
    </StyledFile>
  );
};


const Folder = ({ name, children, documents, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isCurrentOpen } = useSelector(
    (state) => state.client.draftReducer,
    shallowEqual
  );
  const dispatch = useDispatch();

  const handleToogle = (e) => {
    e.preventDefault();
    if (name === "Current") {
      dispatch(clientAction.handleCurrentOpen());
    } else {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    if (name === "Current") {
        dispatch(clientAction.handleCurrentOpen());
      } else {
        setIsOpen(!isOpen);
      }
  }, [])

  return (
    <StyledFolder>
      <div className="folder--label" onClick={handleToogle}>
        {(isCurrentOpen && name === "Current") || isOpen ? (
          <>
            <CaretDownOutlined style={{ fontSize: "22px", color: "#8C8C8C" }} />{" "}
            <FolderOpenOutlined style={{ fontSize: "24px" }} />
          </>
        ) : (
          <>
            <CaretRightOutlined style={{ fontSize: "22px", color: "#8C8C8C" }} />
            <FolderFilled style={{ fontSize: "24px", color: "#6B98C7" }} />
          </>
        )}
        <span
          style={{ fontSize: "22px", fontWeight: "bold", color: "#8C8C8C" }}
        >
          {name}
        </span>
      </div>
      <Collapsible isOpen={name === "Current" ? isCurrentOpen : isOpen}>
        {children}
      </Collapsible>
    </StyledFolder>
  );
};

const Tree = ({ children }) => {
  return <StyledTree>{children}</StyledTree>;
};

Tree.File = File;
// Tree.File1= File1;
Tree.Folder = Folder;


function CurrentDocumentTransaction({
  id,
  handleMenuChange,
  setSelectedClientData,
  newRedirectClientToOffer,
  documents,
}) {
  const { currentCLientDetails } = useSelector(
    (state) => state.client.currentClientReducer,
    shallowEqual
  );

  const dispatch = useDispatch();
  let unmounted = {
    value: false
  };
  let source = axios.CancelToken.source();
  useEffect(() => {
    if (id) {
      if (documents === "CURRENT") {
        dispatch(clientAction.getCurrentClient({customerId: id, statusType: "CURRENT", source, unmounted}));
      }
    }

    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  }, [id]);


  return (
    <Row style={{cursor:"pointer"}}>
      <Col span={24}>
        <Tree.Folder name="Current" documents="CURRENT" id={id}>
          <Tree.Folder name="Offer">
            {currentCLientDetails?.offerList?.length > 0 ? (
              currentCLientDetails?.offerList.map((item) => {
                return (
                  <Tree.File
                    name={item.address}
                    key={item._id}
                    items={item}
                    handleMenuChange={handleMenuChange}
                    propertyId={item.propertyId}
                    status={item.status}
                    setSelectedClientData={setSelectedClientData}
                    source={'offer'}
                  />
                );
              })
            ) : (
              <></>
            )}
          </Tree.Folder>
          <Tree.Folder name="Listing">
            {currentCLientDetails?.listingList?.length > 0 ? (
              currentCLientDetails?.listingList.map((item) => {
                return (
                  <Tree.File key={item._id} name={item.address} items={item} source={'listing'} status={item.status} />
                );
              })
            ) : (
              <></>
            )}
          </Tree.Folder>
        </Tree.Folder>
      </Col>
    </Row>
  );
}

export { CurrentDocumentTransaction };
