import { Button, Col, Input, Layout,  Modal,  Row, Select, Tabs, Tooltip, } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { PlusCircleFilled, SearchOutlined, FilterFilled } from "@ant-design/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import DocumentsList from "./DocumentsList";
import ClausesList from "./ClausesList";

import {
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { DocsClauseModal } from "./DocsClauseModal";
import { toolsAction } from "../state/actions";

const { TabPane } = Tabs;
export const DocsClause = () => {
  return (
    <Layout
    style={{ width: "100%", height: "100%", padding: "0px" }}
     >
      <Content>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{
              paddingLeft: "6px",
              paddingRight: "6px",
            }}
          >
            <Switch>
              <Route
                exact
                path="/tools/docsClause/clauses"
                component={() => <TabCommon idx={"1"} />}
              />
              <Route
                exact
                path="/tools/docsClause/documents"
                component={() => <TabCommon idx={"2"} />}
              />

              {/* <Route path="*">
                <Redirect to="/error" />
              </Route> */}
            </Switch>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

function TabCommon({ idx }) {
  const [visible, setVisble] = useState(false);
  const [handleFilterModal, sethandleFilterModal] = useState(false);
  const [filterOptions, setfilterOptions] = useState([]);
  const [searchText, setSearchText] = useState('');
  // const [listType, setListType] = useState("DOCUMENTS");

  const location = useLocation();
  const history = useHistory();
  const { Option } = Select;
  const dispatch = useDispatch();
  const pathname = location.pathname.split("/").pop();


  const handleSearch = ()=>{
    if (pathname === 'clauses') {
    dispatch(toolsAction.getClause(searchText,filterOptions));
    }else{
        dispatch(toolsAction.getDocuments(searchText,filterOptions));
    }
    sethandleFilterModal(false);
    setfilterOptions([]);
  }

  const handleReset =()=>{
    setfilterOptions([]);
    setSearchText('');
    if(pathname === 'clauses'){
        dispatch(toolsAction.getClause());
        }else{
            dispatch(toolsAction.getDocuments());
        }
    sethandleFilterModal(false);
    setfilterOptions([]);
  }

  const handlesearchChange =(e)=>{
    setSearchText(e.target.value);
    if(!e.target.value){
      if(pathname === 'clauses'){
        dispatch(toolsAction.getClause());
        }else{
            dispatch(toolsAction.getDocuments());
        }
    }
  }

  // created this function for showing the exact routes when key changes
  const handleChange = (key) => {
    if (key === "2") {
      history.push("/tools/docsClause/documents");
    } else if (key === "1") {
      history.push("/tools/docsClause/clauses");
    }
  };


  return (
    <div
      style={{
        border: "1px solid  rgba(0,0,0,0.2)",
        width: "100%",
        borderRadius: "8px",
        backgroundColor: "#fff",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s",
        height: "calc(100vh - 7.5rem)",
        msOverflowY: "auto",
        // border:"2px solid red"
      }}
    >
    <Modal
    visible={handleFilterModal}
    onCancel={()=>sethandleFilterModal(false)}
    footer={false}
    title={<span style={{display:"flex",justifyContent:"center",color:"grey",fontWeight:"bold"}}>
    FILTER {pathname==='clauses' ? "CLAUSES" : "DOCUMENTS"}
    </span>}
    >
    <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
     <Select value={filterOptions} onChange={(val)=>setfilterOptions([...val])} style={{width:"300px"}} placeholder='Select Options' mode="multiple">
        <Option value='CUSTOM'>CUSTOM</Option>
        <Option value='FJGG'>FJGG</Option>
        <Option value='SHARED'>SHARED</Option>
     </Select>
     <div style={{margin:"10px auto",}}>
        <Button onClick={handleSearch} size="small" type="primary" style={{width:"100px"}}>Filter</Button>
        <Button onClick={handleReset} size="small"  style={{width:"100px", marginLeft:"10px"}}>Clear Filter</Button>
     </div>
     </div>
    </Modal>
      <DocsClauseModal
        visible={visible}
        setVisble={setVisble}
        pathname={pathname}

      />
       <div style={{
        position:"absolute",
        top:"20",
        left:"40%",
        zIndex:"111",
        padding:"10px",
        width:"40%",
        display:"flex",
        justifyContent:"space-around"
      }}>
        <div style={{display:"flex"}}>
        <Input  onChange={handlesearchChange}
        onPressEnter={handleSearch}
        placeholder="Search by description..." allowClear enterButton suffix={<SearchOutlined />}/>
        <Button onClick={handleSearch} type='primary'>Search</Button>
        </div>
        <Tooltip trigger={['hover']} title="Filter By Types">
        <FilterFilled
        onClick={()=>sethandleFilterModal(true)}
        style={{
            cursor:'pointer',
            color:"#085191",
            fontSize:"20px",
            margin:"5px auto auto 10px"
           }}
          />
        </Tooltip>
      </div>
      <Tabs
        defaultActiveKey={idx}
        activeKey={idx}
        onChange={handleChange}
        // defaultActiveKey="1"
        tabBarExtraContent={
          <Button
            shape="circle"
            style={{
              //   border: "1px solid #085191",
              marginLeft: "-5rem",
              paddingTop: "0px",
              //   color: "#085191",
            }}
            icon={
              <PlusCircleFilled
                style={{
                  fontSize: "30px",
                  color: "#085191",
                }}
                onClick={() => setVisble(true)}
              />
            }
          ></Button>
        }
      >
         <TabPane
          tab={
            <span
              className="mozFontSize"
              style={{
                fontSize: "1.2rem",
                paddingLeft: "1rem",
              }}
              // onClick={() => setListType("CLAUSES")}
            >
              Clauses
            </span>
          }
          key="1"
        >
          <ClausesList searchText={searchText} />
        </TabPane>
        <TabPane
          tab={
            <span
              className="mozFontSize"
              style={{
                fontSize: "1.2rem",
                paddingLeft: "1rem",
              }}
              // onClick={() => setListType("DOCUMENTS")}
            >
              DOCUMENTS
            </span>
          }
          key="2"
        >
          <DocumentsList searchText={searchText} />
        </TabPane>
      </Tabs>

    </div>
  );
}
