import { documentConstants } from "../action-types";

const initialState = {
  createCustomerData: null,
  createCustomerLoading: true,
  createCustomerError: null,

  datas: "",
  statusCode: "",
};

const clearDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case documentConstants.CLEAR_FORM_PROPERTY: {
      return {
        ...state,
        datas: action,
      };
    }

    default:
      return state;
  }
};
export { clearDataReducer };
