import { BroadcastChannel } from 'broadcast-channel';

const logoutChannel = new BroadcastChannel('logout');

export const logoutAndInWithBroadcast = () => {
    logoutChannel.postMessage("Logout")
}

export const logoutAndInAllTabs = () => {
    logoutChannel.onmessage = (e) => {
        window.location.reload();
        logoutChannel.close(); 
    }
}