import React, { useState, useEffect } from 'react';
import { Button, Modal, Spin, Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { appStatusChange } from '../state/actions';
import { ReloadOutlined } from '@ant-design/icons'

const AppUpdate = () => {
    const [visible, setVisible] = useState(false);
    const { isAppUpdateAvailable, isAppUpdateAvailableLoading } = useSelector((state) => state.app);
    const dispatch = useDispatch();

    const handleRefresh = () => {
        dispatch(appStatusChange.updateAppVersion());
        handleCancel()
    };

    const handleCancel = () => {
        setVisible(false)
    }

    useEffect(() => {
        if (isAppUpdateAvailable) {
            setVisible(true);
        }
    }, [isAppUpdateAvailable]);

    return (
        <Modal
            open={visible}
            centered
            footer={null}
            closable={true}
            maskClosable={false}
            bodyStyle={{
                padding: '30px 20px',
                textAlign: 'center',
                boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
            }}
            width="350px"
            onCancel={handleCancel}
        >
            {isAppUpdateAvailableLoading ? <Spin style={{fontSize:"30px"}}/> : <>
                <Typography.Text
                    style={{
                        fontSize: '22px',
                        fontWeight: 'bold',
                        color: '#1990ff',
                        marginBottom: '20px',
                        display: 'block',
                    }}
                >
                    App update is available!
                </Typography.Text>
                <Button
                    type="primary"
                    onClick={handleRefresh}
                    style={{
                        borderRadius: '8px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        boxShadow: '0 4px 14px rgba(255, 126, 95, 0.4)',
                    }}
                    size="large"
                >
                    Click to refresh
                    <ReloadOutlined />
                </Button>
            </>}
        </Modal>
    );
};

export default AppUpdate;

