import firebase from "firebase/app";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyALynq5_na2o_LSFx_PtAVPTAVqqD9-Tcs",
  authDomain: "re-sure-57da6.firebaseapp.com",
  projectId: "re-sure-57da6",
  storageBucket: "re-sure-57da6.appspot.com",
  messagingSenderId: "574250717136",
  appId: "1:574250717136:web:88b4045cf6a684607933d5",
  measurementId: "G-5059B9VKWJ"
};


// Checking if Firebase is already initialized to avoid re-initialization
if (!firebase.apps.length) {
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
}

// const messaging = firebase.messaging();

// export { messaging };
  
export default firebase;
