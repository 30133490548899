import React from "react";
import { Form, Input, Button, Row, Col } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import {InboxOutlined} from "@ant-design/icons";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const Step1 = (props) => {
  return (
    <>
    {props.source === "PDF_IMPORT" ?
    <div style={{ width: "90%", margin: "auto", textAlign: "center" }}>
            <Dragger
            style={{marginTop:10}}
              accept="application/pdf"
              showUploadList={false}
              customRequest={props.handleCustome}
              beforeUpload={props.beforeUpload}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Supports for a single upload
              </p>
            </Dragger>
            <br />
            <div style={{ width: "100%" }}>
              {props.fileText?.name && (
                <>
                  <h4>{props.fileText?.name}</h4>
                  <p> Size: {props.fileText.size}</p>
                </>
              )}
            </div>
          </div>:
      <Form
        {...layout}
        className="formaccnt d-flex justify-content-center"
        preserve={true}
        style={{ minHeight: "405px" }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Button
            style={{
              border: "none",
              fontSize: "18px",
              fontWeight: "bold",
              color: "#8C8C8C",
              marginTop: "2%",
            }}
          >
            URL
          </Button>
        </div>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
          }}
        >
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Input
              onChange={(e) => {
                props.setImportUrl(e.target.value);
              }}
              value={props.importUrl}
            />
          </Col>
        </Row>
      </Form>
}
    </>
  );
};

export default Step1;
