import { documentConstants } from "../action-types";

const initialState = {
  onFocusData: {
    propertyType: false,
    templateType: false,
    closingDateType: false,
    documentType: false,
    priceType: false,
    searchBuyerType: false,
    documentTypeColor: "",
    templateTypeColor: "",
    propertyTypeColor: "",
    closingDateTypeColor: "",
    priceTypeColor: "",
    searchBuyerTypeColor: "",
  },
};

const onFocusReducer = (state = initialState, action) => {
  switch (action.type) {
    case documentConstants.ON_FOCUS_DATA: {
      return {
        ...state,
        onFocusData: { ...state.onFocusData, ...action.onFocusData },
      };
    }

    default:
      return state;
  }
};
export { onFocusReducer };
