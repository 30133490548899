import { message } from "antd";
import {clienScreenApi} from "../utils/api.js"
import { clientScreenConstants } from "./action-types.js";
import { transactionLaneDataApi } from "../../Rtd/utils/api.js";
import { v4 as uuidv4 } from "uuid";

const adminURL = process.env.REACT_APP_ADMIN_BASE_URL;
const clientScreenError = (error) => {
  return {
    type: clientScreenConstants.CLIENT_SCREEN_ERROR,
    setError: error,
    setLoading: false,
  };
};

const clientScreenLoading = () => {
  return {
    type: clientScreenConstants.CLIENT_SCREEN_LOADING,
    setLoading: true,
  };
};
const clientScreenSuccess = (response) => {
  
  return {
    type: clientScreenConstants.CLIENT_SCREEN_SUCCESS,
    payload:response.data.info
  };
};

const ClientScreenData = (data) => {
  return (dispatch) => {
    dispatch(clientScreenLoading());
    clienScreenApi
      .getClientScreen(data)
      .then((response) => {
        dispatch(clientScreenSuccess(response))
        // let redirectURL = `${apiURL}/contractDocument/${JSON.parse(urlId)}`
        // window.location.assign(encodeURI(redirectURL));
      })
      .catch((error) => {
        dispatch(
            clientScreenError(
            error?.response?.data?.message || "We encountered an issue while getting client screen data. Please try again later!"
          )
        );
      });
  };
};

const EmailFileDataError = (error) => {
  return {
    type: clientScreenConstants.EMAIL_FILES_ERROR,
    emailFilesError: error,
    emailFilesLoading: false,
  };
};

const EmailFileDataLoading = () => {
  return {
    type: clientScreenConstants.EMAIL_FILES_LOADING,
    emailFilesLoading: true,
  };
};
const EmailFileDataSuccess = (response) => {
  
  return {
    type: clientScreenConstants.EMAIL_FILES_SUCCESS,
    payload:response.data.info.emails
  };
};

const getDraftDocumentData = (data, currentPath) => {
  return (dispatch, getState) => {
    transactionLaneDataApi
      .draftDocumetnApi(data)
      .then((response) => {
        const tabId = uuidv4();
        document.cookie = `${tabId}=${currentPath}; domain=.resure.realestate; path=/;`;
      //  window.location.href = `${adminURL}/closingSheet/${response.data.info._id}?tabId=${tabId}`;      
       window.location.href = `${adminURL}/contractDocument/${response.data.info._id}?tabId=${tabId}`;      
  });
};
}
const EmailFileData = (data) => {
  return (dispatch) => {
    dispatch(EmailFileDataLoading());
    clienScreenApi
      .getEmails_FilesApi(data)
      .then((response) => {
        dispatch(EmailFileDataSuccess(response))
        // let redirectURL = `${apiURL}/contractDocument/${JSON.parse(urlId)}`
        // window.location.assign(encodeURI(redirectURL));
      })
      .catch((error) => {
        dispatch(
          EmailFileDataError(
            error?.response?.data?.message || "We encountered an issue while getting email file data. Please try again later!"
          )
        );
      });
  };
};


const DraftDocumentData = (data, currentPath) => {
    return (dispatch, getState) => {
      clienScreenApi
        .DocumentApi(data)
        .then((response) => {
          const tabId = uuidv4();
          document.cookie = `${tabId}=${currentPath}; domain=.resure.realestate; path=/;`;
         window.location.href = `${adminURL}/contractDocument/${response.data.info._id}?tabId=${tabId}`;
        })
        .catch((error) => {
        });
     };
  };

// pROERTY dOCS sCENC


const getPropertyDocsFun = (data) => {
  return (dispatch, getState) => {
    dispatch(uploadPropertyDocsLoading());
    clienScreenApi.getClientDocRequest(data)
      .then((response) => {
        dispatch(uploadPropertyDocsSuccess(response))
      })
      .catch((error) => {
        dispatch(uploadPropertyDocsError(error));
        message.error(error?.response?.data?.message || "We encountered an issue while getting property docs data. Please try again later!");
      });
  };
}

const uploadPropertyDocsError = (error) => {
  return {
    type: clientScreenConstants.UPLOAD_DOCUMENT_FAILURE,
    uploadPdfError: error,
    uploadPdfLoading: false,
  };
};

const uploadPropertyDocsLoading = (error) => {
  return {
    type: clientScreenConstants.UPLOAD_DOCUMENT_LOADING,
    uploadPdfError: error,
    uploadPdfLoading: false,
  };
};

const uploadPropertyDocsSuccess = (response) => {
  return {
    type: clientScreenConstants.UPLOAD_DOCUMENT_SUCCESS,
    propertUploadDocs: response?.data?.info,
    uploadPdfLoading: false,
  };
}; 
// only used for client dashboard upload Pdf/images by clients
  const uploadPropertyDocs = (data, params) => {
    return (dispatch, getState) => {
      dispatch(uploadPropertyDocsLoading());
      clienScreenApi
      .uploadPropertyDocsRequest(data)
        .then((response) => {
          dispatch(getPropertyDocsFun(params));
          message.success(response?.data?.info?.message || "File Uploaded Successfully!");
        })
        .catch((error) => {
          dispatch(uploadPropertyDocsError(error));
          message.error(error?.response?.data?.message || "We encountered an issue while uploading property docs data. Please try again later!");
        });
    };
  }
// Delete PropertyDocs
const removePropertyDocsFun = (docId, params) => {
  return (dispatch, getState) => {
    dispatch(uploadPropertyDocsLoading());
    clienScreenApi
    .deletepropertyDocsRequest(docId)
      .then((response) => {
        dispatch(getPropertyDocsFun(params))
        message.success(response?.data?.info?.message || "Document deleted Successfully!");
      })
      .catch((error) => {
        dispatch(uploadPropertyDocsError(error));
        message.error(error?.response?.data?.message || "We encountered an issue while deleting property docs data. Please try again later!");
      });
  };
}

export const clientScreenHandle = {
  getDraftDocumentData,
    ClientScreenData,
    EmailFileData ,
    DraftDocumentData,
    getPropertyDocsFun,
    removePropertyDocsFun,
    uploadPropertyDocs,
};
