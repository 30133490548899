import { Avatar, Image } from "antd";
import React from "react";
import { ImageUploadDetails } from "./ImageUploadDetails";
import imagePlaceholder from "../../Assets/imagePlaceholder.webp";
import { EditOutlined, UserOutlined } from "@ant-design/icons";
import styles from "../styles/Client.module.css";

const ProfileImg = ({ personDetails, setModelDrawer, selectedClient, forProfileClient }) => {
  return (
    <>
      {!personDetails.profileImg ||
      personDetails?.profileImg?.original === "" ? (
        <>
          <Avatar
            shape="circle"
            onClick={() =>
              setModelDrawer({
                status: true,
                component: (
                  <ImageUploadDetails
                    setModelDrawer={setModelDrawer}
                    data={personDetails}
                    selectedClient={selectedClient}
                    section={"CLIENT"}
                    supportedFormats={["image"]}
                    forProfileClient={forProfileClient}
                  />
                ),
                title: "Upload Image",
              })
            }
            size={67}
            style={{
              background: "#085191",
              border: "3px solid #085191",
              cursor: "pointer",
              fontSize: "24px",
              display: "flex",
              alignItems: "center",
              height: "65px",
            }}
          >
            {(personDetails.firstName
              ? personDetails?.firstName?.[0]?.toUpperCase()
              : "") +
              "" +
              (personDetails.lastName
                ? personDetails?.lastName?.[0]?.toUpperCase()
                : "")}
          </Avatar>
        </>
      ) : personDetails.profileImg &&
        personDetails.profileImg.original !== "" ? (
        <div className={styles.profilephoto}>
          {
            <>
              <Image
                style={{
                  border: "3px solid #085191",
                }}
                preview={
                  (personDetails.profileImg &&
                  personDetails.profileImg.original === ""
                    ? false
                    : true,
                  {
                    mask: (
                      <div
                        style={{
                          marginTop: 50,
                          marginLeft: 48,
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          setModelDrawer({
                            status: true,
                            component: (
                              <ImageUploadDetails
                                setModelDrawer={setModelDrawer}
                                data={personDetails}
                                selectedClient={selectedClient}
                                section={"CLIENT"}
                                supportedFormats={["image"]}
                                forProfileClient={forProfileClient}
                              />
                            ),
                            title: "Upload Image",
                          });
                        }}
                      >
                        <Avatar
                          style={{
                            cursor: "pointer",
                          }}
                          size="small"
                          icon={<EditOutlined style={{ color: "#1890ff" }} />}
                        />
                      </div>
                    ),
                  })
                }
                className={styles.profileimg}
                src={
                  personDetails.profileImg &&
                  personDetails.profileImg.original !== ""
                    ? personDetails.profileImg.original
                    : { imagePlaceholder }
                }
              />
            </>
          }
        </div>
      ) : (
        <Avatar
          onClick={() =>
            setModelDrawer({
              status: true,
              component: (
                <ImageUploadDetails
                  setModelDrawer={setModelDrawer}
                  data={personDetails}
                  selectedClient={selectedClient}
                  section={"CLIENT"}
                  supportedFormats={["image"]}
                  forProfileClient={forProfileClient}
                />
              ),
              title: "Upload Image",
            })
          }
          size={67}
          icon={<UserOutlined />}
        ></Avatar>
      )}
    </>
  );
};

export { ProfileImg };
