import axios from "axios";
import dotEnv from "dotenv";
// import dayjs from "dayjs";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

// const getRemindersListRequest = (timestamp) => {
//   let accessToken = localStorage.getItem("userAuth");
//   return axios.get(
//     `${apiURL}/reminder?responseType=DAY&timestamp=${timestamp}`,
//     {
//       headers: {
//         accept: "application/json",
//         authorization: `bearer ${accessToken}`,
//       },
//     }
//   );
// };

const getReminderAndschedule = (value, isDashboard) => {
  let accessToken = localStorage.getItem("userAuth");
  return axios.get(
    `${apiURL}/reminderSechdule?search=${value}&isDashboard=${isDashboard}`,
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    }
  );
};
const getRemindersListRequest = ({timestamp, sourceAxios}) => {
  let accessToken = localStorage.getItem("userAuth");
  let searchParams = "";
  if (timestamp && typeof timestamp == "string") {
    const searchParamParts = timestamp.split("=");
    if (searchParamParts.length === 2) {
      const searchParamKey = searchParamParts[0];
      const searchParamValue = searchParamParts[1];
      searchParams = `&${searchParamKey}=${searchParamValue}`;
    }
  }
  let urlParams = "";
  if (!searchParams) {
    urlParams = `responseType=DAY&timestamp=${timestamp}`;
  }
  if (searchParams) {
    urlParams = `${searchParams}`;
  }
  return axios.get(`${apiURL}/reminder?${urlParams}`, {
    cancelToken: sourceAxios.token,
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};

const createReminderRequest = (data) => {
  let accessToken = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/reminder`, data, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};

const getReminderByIdRequest = (id) => {
  let accessToken = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/reminder/${id}`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};

const updateReminderRequest = (id, data) => {
  let accessToken = localStorage.getItem("userAuth");
  let {
    dashboardType,
    description,
    startTimestamp,
    endTimestamp,
    duration,
    taggedPersonIds,
    personId,
    title,
    name,
  } = data;
  if (data.type === "CLIENT_EVENT") {
    return axios.put(
      `${apiURL}/events/${id}`,
      {
        name,
        description,
        endTimestamp,
        timestamp: startTimestamp?.toString(),
        dashboardType,
        duration,
      },
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessToken}`,
        },
      }
    );
  }else if (data.type === "BIRTHDAY") {
    return axios.put(
      `${apiURL}/person/profileUpdateOfCustomerByRealtor`,
      {
        dashboardType,
        startTimestamp,
        endTimestamp,
        duration,
        personId,
      },
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessToken}`,
        },
      }
    );
  }
  
  else if (data.dashboardType === "REMINDER") {
    return axios.put(
      `${apiURL}/reminder/${id}`,
      {
        dashboardType,
        description,
        startTimestamp,
        taggedPersonIds,
        title,
      },
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessToken}`,
        },
      }
    );
  } else if (data.dashboardType === "CONTRACT_SCHEDULE") {
    return axios.put(
      `${apiURL}/contractreminder/${id}`,
      {
        dashboardType,
        description,
        startTimestamp,
        endTimestamp,
        duration,
      },
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessToken}`,
        },
      }
    );
  } else if (data.dashboardType === "CONTRACT_REMINDER") {
    return axios.put(
      `${apiURL}/contractreminder/${id}`,
      {
        dashboardType,
        description,
        startTimestamp,
        endTimestamp,
      },
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessToken}`,
        },
      }
    );
  } else if (data.dashboardType === "SCHEDULE") {
    return axios.put(
      `${apiURL}/reminder/${id}`,
      {
        dashboardType,
        description,
        startTimestamp,
        endTimestamp,
        taggedPersonIds,
        title,
        duration,
      },
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessToken}`,
        },
      }
    );
  }
  if (data.dashboardType === "EVENT") {
    return axios.put(
      `${apiURL}/offerEvent/${id}`,
      {
        dashboardType: dashboardType,
        description: description,
        endTimestamp,
        startTimestamp,
        // taggedPersonIds: taggedPersonIds,
        // title: title,
      },
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessToken}`,
        },
      }
    );
  }
};

const deleteReminderByIdRequest = (id, type) => {
  let accessToken = localStorage.getItem("userAuth");
  if (type === "REMINDER") {
    return axios.delete(`${apiURL}/reminder/${id}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    });
  } else if (type === "SCHEDULE") {
    return axios.delete(`${apiURL}/reminder/${id}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    });
  } else if (type === "CONTRACT_REMINDER" || type === "CONTRACT_SCHEDULE") {
    return axios.delete(`${apiURL}/contractreminder/${id}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    });
  }
};

// const getScheduleListRequest = (timestamp) => {
//   let accessToken = localStorage.getItem("userAuth");
//   return axios.get(
//     `${apiURL}/schedules?responseType=DAY&timestamp=${timestamp}`,
//     {
//       headers: {
//         accept: "application/json",
//         authorization: `bearer ${accessToken}`,
//       },
//     }
//   );
// };
const getScheduleListRequest = ({timestamp, source}) => {
  let accessToken = localStorage.getItem("userAuth");
  let searchParams = "";
  if (timestamp && typeof timestamp == "string") {
    const searchParamParts = timestamp.split("=");
    if (searchParamParts.length === 2) {
      const searchParamKey = searchParamParts[0];
      const searchParamValue = searchParamParts[1];
      searchParams = `&${searchParamKey}=${searchParamValue}`;
    }
  }
  let urlParams = "";
  if (!searchParams) {
    urlParams = `responseType=DAY&timestamp=${timestamp}`;
  }
  if (searchParams) {
    urlParams = `${searchParams}`;
  }
  return axios.get(`${apiURL}/schedules?${urlParams}`, {
    cancelToken: source.token,
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};

const saveNotesInCalendarRequest = (data) => {
  let accessToken = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/notes`, data, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};

const getSavedNotesRequest = ({ timestamp, queryData, sourceAxios }) => {
  let accessToken = localStorage.getItem("userAuth");
  if (queryData && queryData?.accessibleFor) {
    return axios.get(`${apiURL}/notes`,
      {
      cancelToken: sourceAxios?.token,
      params: {
        ...queryData,
      },
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    });
  }else {
    let searchText;
    let queryParam;
    if (typeof timestamp === "string") {
      searchText = timestamp;
    }
    queryParam = searchText ? `notesFor=CALANDAR&search=${searchText}` : `notesFor=CALANDAR&timeStamp=${timestamp}`;
  
    return axios.get(`${apiURL}/notes?${queryParam}`, {
      cancelToken: sourceAxios?.token,
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    });
  }
};



const getNewsletterData = ()=>{
  let accessToken = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/newsLetter`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
}

export const landingPageApi = {
  getRemindersListRequest,
  createReminderRequest,
  getReminderByIdRequest,
  updateReminderRequest,
  deleteReminderByIdRequest,
  getScheduleListRequest,
  saveNotesInCalendarRequest,
  getSavedNotesRequest,
  getReminderAndschedule,
  getNewsletterData,
};
