import React from "react";
import { Button } from "antd";
import { appStatusChange } from "../../App/state/actions";
import { useDispatch, useSelector } from "react-redux";
import firebase from "../../firebase";
import "../../Template/styles/Template.css";
import CustomModal from "./custom-modal";
import { userSignUpAction } from "../../User/UserAuthenticate/state/actions";

const CommonModal = () => {
  let dispatch = useDispatch();
  const { showNotificationPopUp, userAuth } = useSelector((state) => state.userSignup);

  const handleClose = () => {
    dispatch(userSignUpAction.handleShowNotification(false));
  };
  const pushNotify = () => {
    handleClose();
    Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          const msg = firebase.messaging();
          return msg.getToken();
        } else {
          console.log("Notification permission is denied.");
        }
      })
      .then((deviceToken) => {
        let sessions = {
          deviceToken: deviceToken,
        };
        localStorage.setItem("userResponse", JSON.stringify(sessions));
        dispatch(appStatusChange.updatedeviceToken(deviceToken, userAuth));
      })
      .catch((error) => {
        console.error("Error in getting permission/token:", error);
      });
  };

  return (
    <>
      <CustomModal isModalOpen={showNotificationPopUp} handleModalClose={handleClose}>
        <h2 style={{ fontSize: "30px", color: "#646464", fontWeight: "bold" }}>RE-Sure Notifications</h2>
        <p style={{ fontSize: "20px", color: "#646464" }}>Allow the RE-Sure application to send notifications about your business transactions and reminders.</p>
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Button
            onClick={() => handleClose()}
            style={{
              marginRight: "15px",
              background: "#dbdbdb",
              color: "white",
              bordeRadius: "10px",
              width: "50%",
              borderRadius: "10px",
              height: "45px",
              fontSize: "20px",
              textTransform: "uppercase",
            }}
          >
            No
          </Button>
          <Button
            style={{
              background: "#085191",
              borderRadius: "10px",
              marginRight: "15px",
              color: "white",
              bordeRadius: "10px",
              width: "50%",
              height: "45px",
              fontSize: "20px",
              textTransform: "uppercase",
            }}
            onClick={() => pushNotify()}
          >
            Yes
          </Button>
        </div>
      </CustomModal>
    </>
  );
};

export default CommonModal;
