import {
  Modal,
  Form,
  Row,
  Col,
  Image,
  Popconfirm,
  Typography,
  Button,
  Input,
  Select,
  Checkbox,
} from "antd";
import {
  InboxOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  PlusCircleFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import ReactQuill from "react-quill";
import Dragger from "antd/lib/upload/Dragger";
import { bugReportHandle } from "../state/actions";
import { useDispatch, useSelector } from "react-redux";
import imageCompression from 'browser-image-compression';
import { roleAccessFunc } from "../../Common/utils/extraFunctions";

const BugModal = ({
  bufferData,
  setUserModalVisible,
  setSearchUserData,
  input,
  setInput,
  setbufferData,
  userDataPage,
  role,
  isModalOpen,
  setIsModalOpen,
  clientID,
  setUserDataPage,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isTab = useMediaQuery({ maxWidth: 991 });
  const spot1 = useMediaQuery({ minWidth:481,maxWidth: 775 });
  const spot2 = useMediaQuery({ minWidth:980});
  const { Text } = Typography;
  const [form] = Form.useForm();
  const imageFormdataRef = useRef([]);
  const inputRef = useRef();
  const dispatch = useDispatch();

  const { userName, usersData } = useSelector((state) => state.bugReport);
  const { roleAccessed } = useSelector((state) => state.account);
  const { renderComp } = useSelector((state) => state.bugReport);

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
      md:{
        span:24,
      },
      lg:{
        span:24
      }
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
      md:{
        span:24,
      },
      lg:{
        span:24,
      }
    },
  };

  const priorityOptions = [
    { value: "LOW", label: "Low" },
    { value: "MEDIUM", label: "Medium" },
    { value: "HIGH", label: "High" },
    { value: "CRITICAL", label: "Critical" },
  ];

  const cancelModal = (source) => {
    if(source?.length){
      form.resetFields();
      setbufferData([]);
      setInput("");
      setSearchUserData("");
      setIsModalOpen(false);
    }else{
      Modal.confirm({
        title:`Are you sure you want to delete this ${renderComp === "suggestions"
        ? "suggestion"
        : renderComp === "listings"
        ? "bug"
        : "ticket"}?`,
        okText:"Yes",
        cancelText:"No",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          form.resetFields();
          setbufferData([]);
          setInput("");
          setSearchUserData("");
          setIsModalOpen(false);
        },
        onCancel() {},
      })
    }
  };

  function deleteImg(index) {
    let filterData = bufferData.filter((el, i) => i !== index);
    setbufferData(filterData);
    const imageFormdataCopy = [...imageFormdataRef.current];
    imageFormdataCopy.splice(index, 1);
    imageFormdataRef.current = imageFormdataCopy;
  }

  let handleCustome = async (data) => {
    const image = await compressImage(data.file)
    const reader = new FileReader();
    reader.readAsDataURL(data.file);
    reader.onload = () => {
      const newFile = {
        name: data.file.name,
        size: bytesToSize(image.size),
        file: reader.result,
        origin: "notBase64",
      };
      setbufferData((prevFiles) => {
        if (prevFiles.length) {
          return [...prevFiles, newFile];
        } else {
          return [newFile];
        }
      });
    };
    reader.onerror = (error) => {
      return error;
    };
    imageFormdataRef.current = [...imageFormdataRef.current, image];
  };

  let handleFilesSelected = (event) => {
    const selectedFiles = Array.from(event.target.files);
    selectedFiles.forEach(async (file) => {
      const image = await compressImage(file)
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const newFile = {
          name: file.name,
          size: bytesToSize(image.size),
          file: reader.result,
          origin: "notBase64",
        };
        setbufferData((prevFiles) => [...prevFiles, newFile]);
      };
      reader.onerror = (error) => {
        return error;
      };
      imageFormdataRef.current = [...imageFormdataRef.current, image];
    });
  };

  async function compressImage(file) {
    const maxSizeMB = 3; 
    const maxSizeBytes = maxSizeMB * 1024 * 1024;
    if (file.size <= maxSizeBytes) {
      return file;
    }
  
    const initialQuality = 0.7;
    let currentQuality = initialQuality;
  
    
    while (true) {
      try {
        const compressedFile = await imageCompression(file, { quality: currentQuality });
        if (compressedFile.size <= maxSizeBytes || currentQuality <= 0.1) {
          return compressedFile;
        }
        currentQuality -= 0.1;
      } catch (error) {
        throw error;
      }
    }
  }

  let handleAddFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.multiple = true;
    input.addEventListener("change", handleFilesSelected);
    input.click();
  };

  const handleSuggestions = (values) => {
    const updatedValue = roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS") || role ? { ...values, assignedTo: clientID } : values;
    dispatch(bugReportHandle.resetAllData("SUGGESTIONS"));
    let imageBuffers = imageFormdataRef.current;
    dispatch(
      bugReportHandle.uploadImageforBugNew(
        imageBuffers,
        "notBase64",
        updatedValue,
        userName,
        "SUGGESTIONS",
        true
      )
    );
    imageFormdataRef.current = [];
    setbufferData([]);
    cancelModal("CREATING");
  };

  const handleSupport = (values) => {
    const updatedValue = roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS") || role ? { ...values, assignedTo: clientID } : values;
    dispatch(bugReportHandle.resetAllData("SUPPORT"));
    let imageBuffers = imageFormdataRef.current;
    dispatch(
      bugReportHandle.uploadImageforBugNew(
        imageBuffers,
        "notBase64",
        updatedValue,
        userName,
        "SUPPORT",
        true
      )
    );
    imageFormdataRef.current = [];
    setbufferData([]);
    cancelModal("CREATING");
  };

  const handleBugs = (values) => {
    const updatedValue = roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS") || role ? { ...values, assignedTo: clientID } : values;
    dispatch(bugReportHandle.resetAllData("BUGS"));
    let imageBuffers = imageFormdataRef.current;
    dispatch(
      bugReportHandle.uploadImageforBugNew(
        imageBuffers,
        "notBase64",
        updatedValue,
        userName,
        "BUGS",
        true
      )
    );
    imageFormdataRef.current = [];
    setbufferData([]);
    cancelModal("CREATING");
  };

  function bytesToSize(bytes) {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }
  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      throw new Error("You can only upload JPG/PNG file!");
    }
    return isJpgOrPng;
  }

  const handleQuillPaste = (e) => {
    const clipboardData = e.clipboardData;
    const types = clipboardData.types || [];
    if (types.includes('Files')) {
      e.preventDefault();
    }
  }

  useEffect(() => {
    let handlePasteEvent = async (event) => {
      let count = 0;
      var items = (event.clipboardData || event.originalEvent.clipboardData)
        .items;
      for (let index in items) {
        var item = items[index];
        if (item.kind === "file" && count === 0) {
          var blob = item.getAsFile();
          var reader = new FileReader();
          const ide = Math.floor(10000 + Math.random() * 90000);
          const imageName = "pastedImage" + ide;
          var updatedBlob = new File([blob], imageName, { type: blob.type });
          const image = await compressImage(updatedBlob)
          let checkImage = beforeUpload(updatedBlob);
          if (checkImage) {
            reader.onload = () => {
              const newFile = {
                name: updatedBlob.name,
                size: bytesToSize(image.size),
                file: reader.result,
                origin: "notBase64",
              };
              setbufferData((prevFiles) => {
                if (prevFiles.length) {
                  return [...prevFiles, newFile];
                } else {
                  return [newFile];
                }
              });
            };
            imageFormdataRef.current = [
              ...imageFormdataRef.current,
              image,
            ];
          }
          reader.readAsDataURL(image);
          count = +count + 1;
        }
      }
    };
    let getModal = document;
    getModal.addEventListener("paste", handlePasteEvent);
    return () => {
      getModal.removeEventListener("paste", handlePasteEvent);
    };
  }, []);

  return (
    <>
      <Modal
        footer={false}
        style={{ top: "5%", maxHeight: isMobile ? "" : "700px" }}
        width={spot1 ? "80%" : isMobile ? "" : "60%"}
        title={
          <h1
            style={{
              textAlign: isMobile ? "left" : "center",
              color: "#1990ff",
              width: isMobile ? "350px" : "",
              marginTop: isMobile ? "10px" : "",
              marginLeft:isMobile && renderComp === "suggestions" ? "-5%" : "",
              fontSize:isMobile && renderComp === "suggestions" ? "28px" : "",
              position: "sticky",
              top: 0,
              background: "#fff",
              zIndex: 1,
            }}
          >
            Enter{" "}
            {renderComp === "suggestions"
              ? "Suggestion"
              : renderComp === "listings"
              ? "Bug"
              : "Ticket"}{" "}
            Details
          </h1>
        }
        open={isModalOpen}
        onCancel={cancelModal}
      >
        <div id="uploadDiv" style={{ overflowY: "auto", maxHeight: "600px", padding: "0 10px" }}>
          <Form
            {...formItemLayout}
            form={form}
            name="suggestions"
            onFinish={
              renderComp === "suggestions"
                ? handleSuggestions
                : renderComp === "listings"
                ? handleBugs
                : handleSupport
            }
            scrollToFirstError
          >
            <Form.Item
              name="title"
              label="Title"
              rules={[
                {
                  required: true,
                  message: `Input ${renderComp === "suggestions"
                  ? "Suggestion"
                  : renderComp === "listings"
                  ? "Bug"
                  : "Ticket"} title!`,
                },
              ]}
            >
              <Input ref={inputRef} autoFocus />
            </Form.Item>

            <Form.Item
              name="content"
              label="Description"
              onPaste={handleQuillPaste}
              rules={[
                {
                  required: true,
                  message: `Please input ${renderComp === "suggestions"
                  ? "Suggestion"
                  : renderComp === "listings"
                  ? "Bug"
                  : "Ticket"} Description`,
                },
              ]}
            >
              {/* <Input.TextArea style={{height:"200px"}} showCount maxLength={800} /> */}
              <ReactQuill
                style={{
                  height: "200px",
                  marginBottom: spot2 ? "82px":spot1 ? "100px":"120px",
                  Width: "100%",
                }}
                placeholder="Description"
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"], // toggled buttons
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }], // custom button values
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }], // superscript/subscript
                    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                    [{ direction: "rtl" }], // text direction
                    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    [{ font: [] }],
                    [{ align: [] }],
                    ["clean"], // remove formatting button
                  ],
                }}
              />
            </Form.Item>

            <Form.Item name="platform" label="Platform"
            rules={[
              {
                required: true,
                message: 'Please select a platform!',
              },
            ]}
            >
              <Checkbox.Group>
                <Checkbox value="MOBILE">Mobile</Checkbox>
                <Checkbox value="WEB">Web</Checkbox>
              </Checkbox.Group>
            </Form.Item>

            {roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS") || role ? (
              <Form.Item name="assignedTo" label="Assign To">
                <Row>
                  <Col span={20} style={{ marginRight: "15px" }}>
                    <Input
                      value={input}
                      placeholder="Click on search button for list of users"
                      disabled
                    ></Input>
                  </Col>
                  <Col
                    span={2}
                    style={{ alignSelf: "center", textAlign: "end" }}
                  >
                    <Button
                      onClick={() => {
                        if (usersData?.length === 0 || !input) {
                          dispatch(bugReportHandle.getUsersData(userDataPage));
                        }
                        setInput("");
                        setSearchUserData("");
                        setUserDataPage(1);
                        setUserModalVisible(true);
                      }}
                      style={{ padding: "0px 6px" }}
                    >
                      <SearchOutlined style={{ fontSize: "18px" }} />
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            ) : null}

            <Form.Item name="priority" label="Priority">
              <Select
                placeholder="Select Priority"
                showArrow
                mode="single"
                options={priorityOptions}
                size="medium"
              />
            </Form.Item>

            <Form.Item
              label={
                isMobile ? (
                  <div>
                    <span
                      style={{
                        color: "#1990ff",
                        fontSize: "27px",
                        cursor: "pointer",
                      }}
                      onClick={handleAddFile}
                    >
                      <PlusCircleFilled />
                    </span>{" "}
                    <span style={{ marginLeft: "5px" }}>Images or Ctrl-V</span>
                  </div>
                ) : (
                  <div style={{ marginTop: "30px" }}>
                    <div>
                      <span
                        style={{
                          color: "#1990ff",
                          fontSize: "27px",
                          cursor: "pointer",
                        }}
                        onClick={handleAddFile}
                      >
                        <PlusCircleFilled />
                      </span>{" "}
                      <span style={{ marginLeft: "5px" }}>Images</span>
                    </div>
                    <div style={{ fontSize: "16px", marginLeft: "20px" }}>
                      or Ctrl-V
                    </div>
                  </div>
                )
              }
            >
              {bufferData.length > 0 ? (
                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      maxWidth: isTab ? "100%" : "42vw",
                      overflow: "auto",
                      minHeight: "200px",
                      boxShadow:
                        "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    }}
                  >
                    {bufferData.map((file, index) => (
                      <div key={index} style={{ margin: "10px" }}>
                        <Image
                          width={100}
                          src={file.file}
                          preview={{
                            visible: false,
                            mask: (
                              <div
                                style={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  zIndex: 21,
                                  top: "0px",
                                  right: "0px",
                                }}
                              >
                                <div
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                >
                                  <Popconfirm
                                    title="Are you sure you want to delete this image?"
                                    onConfirm={() => {
                                      deleteImg(index);
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <CloseCircleOutlined
                                      style={{ fontSize: "30px" }}
                                    />
                                  </Popconfirm>
                                </div>
                              </div>
                            ),
                          }}
                        />
                        <br />
                        <Text type="secondary">{file.name}</Text>
                        <br />
                        <Text type="secondary">Size: {file.size}</Text>
                      </div>
                    ))}
                  </div>
                </Form.Item>
              ) : (
                <Dragger
                  accept="image/*"
                  showUploadList={false}
                  customRequest={handleCustome}
                  multiple
                >
                  <div style={{ height: "200px" }}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined
                        style={{ position: "relative", top: "-25px" }}
                      />
                    </p>
                    <p
                      className="ant-upload-text"
                      style={{ position: "relative", top: "-55px" }}
                    >
                      Click or drag or paste file to this area to upload
                    </p>
                    <p
                      className="ant-upload-hint"
                      style={{ position: "relative", top: "-55px" }}
                    >
                      Support for multiple image uploads. Strictly prohibit from
                      uploading company data or other banned files
                    </p>
                  </div>
                </Dragger>
              )}
            </Form.Item>
            <Form.Item>
              <div style={{ display: "flex", justifyContent: "center"}}>
                <Button
                  type="primary"
                  style={{
                    width: isMobile ? "100%" : "55%",
                  }}
                  htmlType="submit"
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default BugModal;
