import { Button, Checkbox, Select } from 'antd'
import { Option } from 'antd/lib/mentions'
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import React from 'react'
import { useState } from 'react';
let arr=[{

}]
const EmailCampaign = () => {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <div style={{width:"1200px"}}>
    <table
          className="licenseTable"
          style={{
            width: "70%",
            marginTop: "50px",
          }}
        >
          <thead
            style={{
              position: "sticky",
              top: -2,
              left: "0px",
              zIndex: 100,
              height: "50px",
            }}
          >
            <tr>
              <th>S.No</th>
              <th>Events</th>
              <th>Select your events</th>
              <th>Edit</th>
              
            </tr>
          </thead>

          <tbody>
            
              <tr style={{ marginTop: "20px" }}>
                <td
                  style={{
                    fontWeight: "bolder",
                    width: "8%",
                    textAlign: "center",
                  }}
                >
                  {1} 
                </td>
                <td
                  style={{
                    fontWeight: "bolder",
                    width: "40%",
                    textAlign:"center"
                  }}
                >
                Birthdays
                </td>

               
                <td
                  style={{
                    color: "#DC143C",
                    textAlign: "center",
                    cursor: "pointer",
                    width: "18%",
                  }}
                >
                <Checkbox checked={checked}  onChange={handleCheckboxChange}>
                 </Checkbox>

                </td>
                <td
                 
                  style={{
                    color: "#178DFA",
                    fontWeight: "bolder",
                    textAlign: "center",
                    cursor: "pointer",
                    width: "8%",
                  }}
                >
                  <EditTwoTone />
                </td>
              </tr>
           
          </tbody>
        </table>
</div>
  )
}

export {EmailCampaign}