import { List, Input, Tooltip, Button, Form, Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { transactionLaneDataHandle } from '../state/actions';
import { Loading } from '../../Common/components/Loading';
import {CloseCircleOutlined, InboxOutlined, MailOutlined, PhoneOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { formatAddress, formatPhoneNumber } from '../../Common/utils/extraFunctions';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';

const TitleOffice = ({ companyModalStep, setCompanyModalStep}) => {
    const [addOffice, setAddOffice] = useState(companyModalStep?.titleOffice ? false : true);
    const [search, setSearch] = useState("");
    const dispatch=useDispatch()
    const { getTitleCompanyData, getTitleCompanyLoading, getTitleCompanyError } = useSelector((state) => state.rtdTransactionLane )

    let unmounted = {
        value: false
    };
    let source = axios.CancelToken.source();
    useEffect(()=>{
        if(companyModalStep?.inBuilt){              
            dispatch(transactionLaneDataHandle.getTitleCompany({type: "OFFICE", id: companyModalStep?.titleCompany?._id, search, unmounted, source}));
            return () => {
                unmounted.value = true;
                source.cancel("Cancelling in cleanup");
            }
        }
    }, [search])

    const handleSelectedValues = (value)=>{
        setCompanyModalStep({
            ...companyModalStep,
            titleOffice: value,
            titleCloser: "",
            salesRep: ""
        })
    }

    const handleSearchChange = (val)=>{
        setSearch(val);
    }

    const handleAdd = () => {
        setAddOffice(!addOffice);
    }

    const onFinish = (values) => {
        setCompanyModalStep({
            ...companyModalStep,
            titleOffice: {
            name: values?.officeName,
            email: values?.officeEmail || "",
            address: values?.address,
            phoneNumber: formatPhoneNumber(values?.phoneNumber),
            faxNumber: formatPhoneNumber(values?.faxNumber)
            }
        })
        setAddOffice(!addOffice);
    }

    const reset = (event)=>{
        event.stopPropagation();
        setCompanyModalStep({
            ...companyModalStep,
            titleOffice: "",
            titleCloser: "",
            salesRep: ""
        })
    }

    return (
    <div style={{width:"100%",margin:"auto"}}>
        {
            (companyModalStep?.inBuilt) && <Input
                value={search}
                id="search-input"
                onChange={(e)=>handleSearchChange(e.target.value)}
                placeholder="Search "
            />
        }
        {
            getTitleCompanyLoading ? <Loading/> :
            getTitleCompanyError ? "" :
            <div style={{maxHeight:"400px",overflowY:"auto"}}>
                {
                companyModalStep?.inBuilt ? <List
                    style={{borderRadius:"8px"}}
                    bordered={true}
                    itemLayout="horizontal"
                    dataSource={getTitleCompanyData}
                    renderItem={(item) => {
                    return <List.Item
                        style={{
                            background: (item?._id == companyModalStep?.titleOffice?._id) ? "rgb(132, 187, 238)" : "white",
                            borderRadius:"8px"
                        }}
                        key={item._id}
                        onClick={() => handleSelectedValues(item)}
                        >
                        {
                            (item?._id == companyModalStep?.titleOffice?._id) && <div style={{position: "absolute", marginTop: "-40px", marginLeft: "590px"}}>
                            <CloseCircleOutlined
                                style={{ fontSize: "20px",  }}
                                onClick={reset}
                            />
                            </div>
                        }
                        <List.Item.Meta
                            title={<><UserOutlined style={{color:"black",marginRight:"10px",fontWeight:"bold",fontSize:"20px"}}/><span  style={{color: "#096dd9",}}>{item?.name}</span></>}
                            description={
                            <div style={{display:"flex",alignItems:"center"}}>
                                <HiOutlineOfficeBuilding style={{color:"black",marginRight:"10px",fontWeight:"bold",fontSize:"20px"}}/>
                                <span style={{ color: "black", fontSize: "16px" }}>
                                {formatAddress (item?.address)}
                                </span>
                            </div>
                            }
                        />
                        </List.Item>
                    }
                    }
                /> :
                companyModalStep?.titleOffice?.name ? <div
                    style={{
                        // height: "80px",
                        cursor :"pointer", 
                        padding: "16px",
                        borderRadius:"8px",
                        background: "rgb(132, 187, 238)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: "5px"
                    }}
                    >
                    <div style={{position: "absolute", marginTop: "-50px", marginLeft: "608px"}}>
                        <CloseCircleOutlined
                        style={{ fontSize: "20px",  }}
                        onClick={reset}
                        />
                    </div>
                    {
                        companyModalStep?.titleOffice?.name && <div style={{display:"flex", alignItems:"center"}}>
                        <UserOutlined style={{color:"black",marginRight:"10px",fontWeight:"bold",fontSize:"20px"}}/>
                        <span  style={{color: "#096dd9", fontSize: "17px", fontWeight: "bold"}}>{companyModalStep?.titleOffice?.name}</span>
                        </div>
                    }
                    {
                        companyModalStep?.titleOffice?.address && <div style={{display:"flex", alignItems:"center"}}>
                        <HiOutlineOfficeBuilding style={{color:"black",marginRight:"10px",fontWeight:"bold",fontSize:"20px"}}/>
                        <span style={{ color: "black", fontSize: "16px" }}>
                            {companyModalStep?.titleOffice?.address}
                        </span>
                        </div>
                    }
                    {
                        companyModalStep?.titleOffice?.email && <div style={{display:"flex", alignItems:"center"}}>
                        <MailOutlined style={{color:"black",marginRight:"10px",fontWeight:"bold",fontSize:"20px"}}/>
                        <span style={{ color: "black", fontSize: "16px" }}>{companyModalStep?.titleOffice?.email}</span>
                        </div>
                    }
                    {
                        companyModalStep?.titleOffice?.phoneNumber && <div style={{display:"flex", alignItems:"center"}}>
                        <PhoneOutlined style={{color:"black",marginRight:"10px",fontWeight:"bold",fontSize:"20px"}}/>
                        <span style={{ color: "black", fontSize: "16px" }}>{companyModalStep?.titleOffice?.phoneNumber}</span>
                        </div>
                    }
                    {
                        companyModalStep?.titleOffice?.faxNumber && <div style={{display:"flex", alignItems:"center"}}>
                        <InboxOutlined style={{color:"black",marginRight:"10px",fontWeight:"bold",fontSize:"20px"}}/>
                        <span style={{ color: "black", fontSize: "16px" }}>{companyModalStep?.titleOffice?.faxNumber}</span>
                        </div>
                    }
                </div> : 
                <div
                    style={{
                    height: "120px",
                    padding:"20px",
                    borderRadius:"8px"
                    }}
                >
                    <Tooltip title="Add Office">
                    <PlusOutlined style={{cursor :"pointer", fontSize: "30px", padding: "25px", border: "1px dashed grey"}} onClick={handleAdd}/>
                    </Tooltip>
                </div>
                }
            </div>
        }
        {
            addOffice && (!companyModalStep?.inBuilt) && <Modal
                title={<div>Add Title Office</div>}
                open={true}
                onCancel={handleAdd}
                style={{ marginTop: "-50px" }}
                footer={false}
            >
                <Form
                onFinish={onFinish}
                colon={false}
                autoComplete="off"
                labelCol={{ span: 8 }}
                labelAlign="left"
                >
                <Form.Item
                    label={<div style={{fontSize: "15px"}}>Office Name</div>}
                    name="officeName"
                    rules={[{ required: true, message: "Please Enter Office Name!" }]}
                >
                    <Input placeholder="Enter Company Name" allowClear autoFocus/>
                </Form.Item>
                <Form.Item
                    label={<div style={{fontSize: "15px"}}>Office Address</div>}
                    name="address"
                    rules={[{ required: true, message: "Please Enter Address!" }]}
                >
                    <Input.TextArea placeholder="Enter Address" allowClear autoFocus/>
                </Form.Item>
                <Form.Item
                    label={<div style={{fontSize: "15px"}}>Office Email</div>}
                    name="officeEmail"
                    rules={[
                    {
                        type: 'email',
                        message: 'Not a valid Email!',
                    },
                    ]}
                >
                    <Input placeholder="Enter Office Email" allowClear autoFocus/>
                </Form.Item>
                <Form.Item
                    label={<div style={{fontSize: "15px"}}>Mobile Phone</div>}
                    name="phoneNumber"
                    hasFeedback
                    // rules={[
                    // ({ getFieldValue }) => ({
                    //     validator(_, value) {
                    //     if (value?.length) {
                    //         if (value?.length === 10) {
                    //         return Promise.resolve();
                    //         }
                    //         return Promise.reject(
                    //         new Error('Please Enter 10 digit Phone Number!')
                    //         );
                    //     } else {
                    //         return Promise.resolve();
                    //     }
                    //     },
                    // }),
                    // ]}
                >
                    <PhoneInput
                    inputStyle={{ width: '100%' }}
                    inputClass="phoneInput"
                    country={'us'}
                    onlyCountries={['us']}
                    maxLength="10"
                    international={false}
                    disableCountryCode={true}
                    placeholder="Enter Phone Number"
                    className="phone-input"
                    style={{ width: '286px !important' }}
                    />
                </Form.Item>
                <Form.Item
                    label={<div style={{fontSize: "15px"}}>Fax Number</div>}
                    name="faxNumber"
                    hasFeedback
                    rules={[
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                        if (value?.length) {
                            if (value?.length === 10) {
                            return Promise.resolve();
                            }
                            return Promise.reject(
                            new Error('Please Enter 10 digit Fax Number!')
                            );
                        } else {
                            return Promise.resolve();
                        }
                        },
                    }),
                    ]}
                >
                    <PhoneInput
                    inputStyle={{ width: '100%' }}
                    inputClass="phoneInput"
                    country={'us'}
                    onlyCountries={['us']}
                    maxLength="10"
                    international={false}
                    disableCountryCode={true}
                    placeholder="Enter Fax Number"
                    className="phone-input"
                    style={{ width: '286px !important' }}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                    type="primary"
                    style={{
                        width: "100%"
                    }}
                    htmlType="submit"
                    >
                    Add
                    </Button>
                </Form.Item>
                </Form>
            </Modal>
        }
    </div>
    )
}

export {TitleOffice}
