import { templateConstants } from "./action-types";
import { templateDataApi } from "../utils/api";
import dotEnv from "dotenv";
import { message } from "antd";
import { v4 as uuidv4 } from "uuid";
dotEnv.config();
const adminURL = process.env.REACT_APP_ADMIN_BASE_URL;
/**
 * All Actions for Admin
 */

/**
 * To update Admin state
 * @param {string} auth
 */
const templateLoading = () => {
  return {
    type: templateConstants.TEMPLATE_DATA_LOADING,
  };
};
const templateError = (error) => {
  return {
    type: templateConstants.TEMPLATE_DATA_FAILURE,
    templateError: error,
  };
};

const templateSuccess = (response) => {
  return {
    type: templateConstants.TEMPLATE_DATA_SUCCESS,
    templateData: response.data.info,
  };
};

const gettemplateData = ({contractType}) => {
  return (dispatch, getState) => {
    dispatch(templateLoading());
    templateDataApi
      .templateDataRequest({contractType})
      .then((response) => {
        dispatch(templateSuccess(response));
      })
      .catch((error) => {
        dispatch(templateError(error));
        // if (error.response.status === 401) {
        //   localStorage.removeItem("userAuth");
        //   window.location.reload();
        // }
      });
  };
};

const deleteTemplateError = (error) => {
  return {
    type: templateConstants.DELETE_TEMPLATE_FAILURE,
    deleteTemplateError: error,
    deleteTemplateLoading: false,
  };
};

const deleteTemplateSuccess = (response) => {
  return {
    type: templateConstants.DELETE_TEMPLATE_SUCCESS,
    deleteTemplate: response.data.info,
    deleteTemplateLoading: false,
  };
};

const deleteTemplateLoading = (response) => {
  return {
    type: templateConstants.DELETE_TEMPLATE_LOADING,
  };
};

const deleteTemplate = (id) => {
  return (dispatch, getState) => {
    dispatch(deleteTemplateLoading());
    templateDataApi
      .deleteTemplateRequest(id)
      .then((response) => {
        dispatch(deleteTemplateSuccess(response));
        dispatch(gettemplateData({}));
        message.success("Template Deleted Successfully!")
      })
      .catch((error) => {
        dispatch(deleteTemplateError(error));
        message.error(error?.response?.data?.message || "Error occured while deleting the template!");
      });
  };
};

// create template and offer template
const templateOfferError = (error) => {
  return {
    type: templateConstants.TEMPLATE_SHORTHAND_URL_FAILURE,
    templateError: error,
    templateLoading: false,
  };
};


const templateOfferCopySuccess = (redirectURL) => {
  return {
    type: templateConstants.TEMPLATE_URL_COPIED,
    templateLoading: false,
    payload: redirectURL,
  };
};

const templateOffer = (data, currentPath, redirect) => {
  return (dispatch) => {
    templateDataApi
      .templateShortening(data)
      .then((response) => {
        const redirectURL = `${adminURL}/contractDocument/${response.data.info._id}`;
        if (redirect) {
          const tabId = uuidv4();
          document.cookie = `${tabId}=${currentPath}; domain=.resure.realestate; path=/;`;
          window.location.href = `${redirectURL}?tabId=${tabId}`;
        } else {
          dispatch(templateOfferCopySuccess(redirectURL));
        }
      })
      .catch((error) => {
        dispatch(templateOfferError(error));
      });
  };
};

// update template name and description

const templateEditDataError = (error) => {
  return {
    type: templateConstants.TEMPLATE_EDITDATA_FAILURE,
    templateEditError: error,
    templateEditLoading: false,
  };
};

const templateEditDataSuccess = (response) => {
  return {
    type: templateConstants.TEMPLATE_EDITDATA_SUCCESS,
    templateEditData: response.data.info,
    templateEditLoading: false,
  };
};

const updateTemplateEditData = (payload) => {
  return (dispatch, getState) => {
    templateDataApi
      .editemplateDataRequest(payload)
      .then((response) => {
        dispatch(gettemplateData({})); 
        dispatch(templateEditDataSuccess(response));
        message.success("Template Updated Successfully!");
      })
      .catch((error) => {
        dispatch(templateEditDataError(error));
        // if (error.response.status === 401) {
        //   localStorage.removeItem("userAuth");
        //   window.location.reload();
        // }
      });
  };
};

// create template
export const templateAction = {
  gettemplateData,
  deleteTemplate,
  templateOffer,
  updateTemplateEditData,
};
