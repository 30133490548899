import { deleteAccountConstants } from "./action-types";
import { deleteAccountApi } from "../utils/api";
import { message } from "antd";

const deleteAccountLoading = () => {
  return {
    type: deleteAccountConstants.DELETE_ACCOUNT_LOADING,
  };
};

const deleteAccountSuccess = (data) => {
  return {
    type: deleteAccountConstants.DELETE_ACCOUNT_SUCCESS,
    deleteAccountMessage:data
  };
};

const deleteAccount = (data) => {
  return (dispatch) => {
    dispatch(deleteAccountLoading());
    deleteAccountApi
      .deleteAccount(data)
      .then((response) => {
        dispatch(deleteAccountSuccess(response?.data?.message));
        message.success("Account deleted Successfully!");
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "We encountered an issue while deleting the account. Please try again later!");
      });
  };
};

export const deleteAccountDataHandle = { deleteAccount };
