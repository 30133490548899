import React, { useState } from "react";
import styled from "styled-components";
import { Col, Modal, Row } from "antd";
import {
  FolderFilled,
  FolderOpenOutlined,
  FileZipOutlined,
  CaretRightOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { clientAction } from "../state/actions";
import { ClientListingModal } from "./ClientListingModal";
import moment from "moment";
import { useEffect } from "react";
import axios from "axios";
const StyledTree = styled.div`
  line-height: 1.5;
`;
const StyledFile = styled.div`
  padding-left: 20px;
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
  }
`;
const StyledFolder = styled.div`
  padding-left: 20px;

  .folder--label {
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }
`;
const Collapsible = styled.div`
  height: ${(p) => (p.isOpen ? "auto" : "0")};
  overflow: hidden;
`;

const File = ({ name, currentCLientDetails, id, items, handleMenuChange }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const modalOpenFunction = () => {
    setIsModalVisible(true);
  };

  let morphedHandleMenuChange = (event, selectedItem) => {
    handleMenuChange(event, selectedItem);
  };

  return (
    <StyledFile>
      {/* render the extension or fallback to generic file icon  */}
      {/* {FILE_ICONS[ext] || <FileZipOutlined />} */}
      <span
        onClick={() => (id ? "" : modalOpenFunction())}
        style={{
          fontSize: "20px",
          fontWeight: 500,
          color: "#8C8C8C",
          cursor: "pointer",
        }}
      >
        {/* <FileZipOutlined style={{ fontSize: "22px" }} />
        {name} */}
        {(currentCLientDetails?.offerList?.length > 0 ||
          currentCLientDetails?.offerList?.length === undefined) &&
        (currentCLientDetails?.listingList?.length > 0 ||
          currentCLientDetails?.listingList?.length === undefined) ? (
          <>
            <FileZipOutlined style={{ fontSize: "22px" }} />
            {name}
          </>
        ) : (
          ""
        )}
      </span>
      <Modal
        title="RE-Sure"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              width: 36,
              height: 36,
              borderRadius: 4,
              backgroundColor: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: 23,
                height: 23,
                borderRadius: 8,
                backgroundColor:
                  items?.status === "SUBMITTED" ? "green" : "yellow",
              }}
            />
          </div>
          <div
            style={{
              fontSize: "20px",
              color: "#8C8C8C",
              marginLeft: "3%",
              fontWeight: "bold",
            }}
          >
            {name}
          </div>
        </div>
        <div
          style={{
            fontSize: "18px",
            color: "#8C8C8C",
            fontWeight: "bolder",
            marginLeft: "10%",
          }}
        >
          Closing Date :{" "}
          {moment(new Date()).add(1, "month").format("MMM-DD-YYYY")}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "24px",
              color: "#8C8C8C",
              fontWeight: "bolder",
              textDecoration: "underline",
              marginBottom: "0px",
              marginTop: "5%",
            }}
          >
            Actions
          </p>
          <ClientListingModal
            selectedItem={items}
            morphedHandleMenuChange={morphedHandleMenuChange}
            id={items?._id}
            item={items}
          />
        </div>
      </Modal>
    </StyledFile>
  );
};

const Folder = ({ name, children, documents, id }) => {
  const { isPastOpen } = useSelector(
    (state) => state.client.draftReducer,
    shallowEqual
  );
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = (e) => {
    e.preventDefault();
    if (name === "Past") {
      dispatch(clientAction.handlePastOpen());
    } else {
      setIsOpen(!isOpen);
    }
  };
  return (
    <StyledFolder>
      <div className="folder--label" onClick={handleToggle}>
        {(isPastOpen && name === "Past") || isOpen ? (
          <>
            <CaretDownOutlined style={{ fontSize: "22px", color: "#8C8C8C" }} />{" "}
            <FolderOpenOutlined style={{ fontSize: "24px" }} />
          </>
        ) : (
          <>
            <CaretRightOutlined style={{ fontSize: "22px", color: "#8C8C8C" }} />
            <FolderFilled style={{ fontSize: "24px", color: "#6B98C7" }} />
          </>
        )}
        <span
          style={{ fontSize: "22px", fontWeight: "bold", color: "#8C8C8C" }}
        >
          {name}
        </span>
      </div>
      <Collapsible isOpen={name === "Past" ? isPastOpen : isOpen}>
        {children}
      </Collapsible>
    </StyledFolder>
  );
};

const Tree = ({ children }) => {
  return <StyledTree>{children}</StyledTree>;
};

Tree.File = File;
Tree.Folder = Folder;

function PastDocumentTransaction({ id, handleMenuChange, documents }) {
  const { pastCLientDetails } = useSelector(
    (state) => state.client.pastClientReducer,
    shallowEqual
  );

  const dispatch = useDispatch();
  let unmounted = {
    value: false
  };
  let source = axios.CancelToken.source();
  useEffect(() => {
    if (id) {
      if (documents === "PAST") {
        dispatch(clientAction.getPastClient({statusType: "PAST", customerId: id, source, unmounted}));
      }
    }

    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  }, [id]);

  return (
    <Row style={{cursor:"pointer"}}>
      <Col span={24}>
        <Tree.Folder name="Past" documents="PAST" id={id}>
          <Tree.Folder name="Offer">
            {pastCLientDetails?.offerList?.length > 0 ? (
              pastCLientDetails?.offerList.map((item) => {
                return (
                  <Tree.File
                    // name=""
                    key={item._id}
                    name={item.address}
                    items={item}
                    handleMenuChange={handleMenuChange}
                  />
                );
              })
            ) : (
              <></>
            )}
          </Tree.Folder>
          <Tree.Folder name="Listing">
            {pastCLientDetails?.listingList?.length > 0 ? (
              pastCLientDetails?.listingList.map((item) => {
                return <Tree.File key={item._id} name={item.address} />;
              })
            ) : (
              <></>
            )}
          </Tree.Folder>
        </Tree.Folder>
      </Col>
    </Row>
  );
}

export { PastDocumentTransaction };
