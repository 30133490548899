import { Tooltip, Typography } from "antd";
import React from "react";
import { BiUser } from "react-icons/bi";
import styles from "../styles/Client.module.css";
import { NameDetails } from "./NameDetails";
import { capitalizeStr } from "../../Common/utils/extraFunctions";

const Name = ({ setModelDrawer, personDetails, onFinish }) => {
  const Text = Typography;
  return (
    <Text style={{ display: "flex", alignItems: "center" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={"Client Name"} placement="left">
          <BiUser className={styles.textAlignment} />
        </Tooltip>
      </div>
      <div
        className={styles.iconsbox}
        onClick={() =>
          setModelDrawer({
            status: true,
            component: (
              <NameDetails
                onFinish={onFinish}
                setModelDrawer={setModelDrawer}
                data={personDetails}
              />
            ),
            title: "NAME DETAILS",
          })
        }
      >
        <div className={styles.iconsclient}>
          <img src="icons/client-details-name.svg" alt=""></img>
        </div>
        <div style={{ marginLeft: "-10%",width: "190px",height:"25px",overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", }} className={styles.userfeild}>
          {(personDetails.firstName
            ? capitalizeStr(personDetails.firstName)
            : "") +
            " " +
            (personDetails.middleName && personDetails.middleName !== "null"
              ? capitalizeStr(personDetails.middleName)
              : "") +
            " " +
            (personDetails.lastName
              ? capitalizeStr(personDetails.lastName)
              : "")}
        </div>
      </div>
    </Text>
  );
};

export { Name };
