import { landingPageConstants } from "../action-types";

const initialState = {
  getRemindersData: [],
  getRemindersError: null,
  getRemindersLoading: false,

  createdReminder: {},
  createdReminderError: null,

  reminderDataById: {},
  reminderDataByIdError: null,

  updateReminder: {},
  updateReminderError: null,

  deletedReminder: {},
  deletedReminderError: null,

  reminderandscheduleloading: false,
  reminderandschedulesuccess: [],
  reminderandschedulerror: false,
  renderReminder: []
};

const reminderReducer = (state = initialState, action) => {
  switch (action.type) {
    case landingPageConstants.RENDER_REMINDER:
      return {
        ...state,
        renderReminder: []
      };
    case landingPageConstants.GET_REMINDERS_LIST_LOADING:
      return {
        ...state,
        getRemindersLoading: true,
        getRemindersError:false,
      };
    case landingPageConstants.GET_REMINDERS_LIST_SUCCESS:
      return {
        ...state,
        getRemindersLoading: false,
        getRemindersData: action.payload.data,
        totalRecords: action.payload.totalRecords,
        getRemindersError:false,
      };
    case landingPageConstants.GET_REMINDERS_LIST_FAILURE:
      return {
        ...state,
        getRemindersLoading: false,
        getRemindersError: true,
      };
    case landingPageConstants.CREATE_REMINDER_SUCCESS:
      return {
        ...state,
        createdReminder: action.payload,
      };
    case landingPageConstants.CREATE_REMINDER_FAILURE:
      return {
        ...state,
        createdReminderError: action.payload,
      };
    case landingPageConstants.GET_REMINDERS_BY_ID_SUCCESS:
      return {
        ...state,
        reminderDataById: action.payload,
      };
    case landingPageConstants.GET_REMINDERS_BY_ID_FAILURE:
      return {
        ...state,
        reminderDataByIdError: action.payload,
      };
    case landingPageConstants.UPDATE_REMINDER_SUCCESS:
      return {
        ...state,
        updateReminder: action.payload,
      };
    case landingPageConstants.UPDATE_REMINDER_FAILURE:
      return {
        ...state,
        updateReminderError: action.payload,
      };
    case landingPageConstants.DELETE_REMINDERS_BY_ID_SUCCESS:
      return {
        ...state,
        deletedReminder: action.payload,
      };
    case landingPageConstants.DELETE_REMINDERS_BY_ID_FAILURE:
      return {
        ...state,
        deletedReminderError: action.payload,
      };

    case landingPageConstants.GET_REMINDER_AND_SCHEDULE_LOADING:
      return {
        ...state,
        reminderandscheduleloading: action.payload,
      };
    case landingPageConstants.GET_REMINDER_AND_SCHEDULE_SUCCESS:
      return {
        ...state,
        reminderandschedulesuccess: action.payload,
      };
    case landingPageConstants.GET_REMINDER_AND_SCHEDULE_FAILURE:
      return {
        ...state,
        reminderandschedulerror: action.payload,
      };
    default:
      return state;
  }
};

export { reminderReducer };
