import React from "react";
import CreatedDoraDocsModalContent from "./CreatedDoraDocsModalContent";
import { Modal } from "antd";

const CreateDoraDocsModal = ({ isDoraDocsModalVisible, setIsDoraDocsModalVisible, selectedData, handleUploadNew, handleCreateNew, element }) => {

  const handleCancel = () => {
    setIsDoraDocsModalVisible(false);
  };

  return (
    <Modal visible={isDoraDocsModalVisible} onCancel={handleCancel} footer={[]}>
      {selectedData?.propertyYearBuilt > 1977 && (element.contractType === "LBPOS" || element.contractType === "LBPD") && (
        <p style={{ fontWeight: "500", color: "#2976be" }}>
          Note : <text style={{ fontWeight: "500", color: "darkgray" }}>Document Not Required ! This property was built after 1977, exempting the need for this document.</text>{" "}
        </p>
      )}
     <CreatedDoraDocsModalContent handleUploadNew={handleUploadNew} handleCreateNew={handleCreateNew} />
    </Modal>
  );
};

export default CreateDoraDocsModal;
