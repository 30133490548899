import { combineReducers } from "redux";
import { personDetailReducer } from "./reducers/personDetailReducer";
import { pastClientReducer } from "./reducers/pastClientReducer";
import { birthdayReducer } from "./reducers/birthdayReducer";
import { addressReducer } from "./reducers/addressReducer";
import { calenderReducer } from "./reducers/calenderReducer";
import { clientReducer } from "./reducers/clientReducer";
import { emailReducer } from "./reducers/emailReducer";
import { eventReducer } from "./reducers/eventReducer";
import { imageReducer } from "./reducers/imageReducer";
import { nameReducer } from "./reducers/nameReducer";
import { notesReducer } from "./reducers/notesReducer";
import { phoneReducer } from "./reducers/phoneReducer";
import { titleReducer } from "./reducers/titleReducer";
import { draftReducer } from "./reducers/draftReducer";
import { memoriesReducer } from "./reducers/memoriesReducer";
import { documentryReducer } from "./reducers/documentryReducer";
import { currentClientReducer } from "./reducers/currentClientReducer";

const client = combineReducers({
  addressReducer,
  birthdayReducer,
  calenderReducer,
  clientReducer,
  currentClientReducer,
  documentryReducer,
  draftReducer,
  emailReducer,
  eventReducer,
  imageReducer,
  memoriesReducer,
  nameReducer,
  notesReducer,
  pastClientReducer,
  personDetailReducer,
  phoneReducer,
  titleReducer,
});

export { client };
