import { Tooltip, Typography } from "antd";
import React from "react";
import styles from "../styles/Client.module.css";
import moment from "moment";
import { HistoryOutlined } from "@ant-design/icons";

const UpdatedAt = ({ personDetails }) => {
  const Text = Typography;
  // gets recent time from phone address event and common for all key updatedAt and returns it
  const lastUpdated = () => {
    let phoneDate = personDetails.phoneData?.updatedAt || null;
    phoneDate = new Date(phoneDate);
    let addressDate = personDetails.addressData?.updatedAt || null;
    addressDate = new Date(addressDate);
    let eventDate = new Date(0);
    let events = personDetails.eventData;
    events?.forEach((e, i) => {
      if (new Date(e.updatedAt) > eventDate) eventDate = new Date(e.updatedAt);
    });
    let allDate = personDetails.updatedAt || null;
    allDate = new Date(allDate);

    return Math.max(
      phoneDate.getTime(),
      addressDate.getTime(),
      eventDate.getTime(),
      allDate.getTime()
    );
  };
  return (
    <Text style={{ display: "flex", alignItems: "center" }}>
      <div className={styles.iconsbox}>
        <div
          className={styles.iconsclient}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Tooltip title={"Date Updated"} placement="left" >
            <HistoryOutlined className={styles.textAlignment} />
          </Tooltip>
        </div>
        <div 
         style={{ 
          paddingLeft: "22px",
          "fontSize": "18px",
          "textTransform": "capitalize" 
              }} >
          {" "}
          {moment(lastUpdated()).format("MMMM D, YYYY hh:mm A")}{" "}
        </div>
      </div>
    </Text>
  );
};

export { UpdatedAt };
