import React, { useEffect, useRef, useState } from "react";
import { Button, Tooltip } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustumToolbar, { formats,modules } from "../../Common/components/CustumToolbar";
import { useMediaQuery } from "react-responsive";

const ContentEditable = ({ setEmail_templatevisible, selectedtext, handleInput, emailList, setEmailList }) => {
    const [componantDidmount, setcomponantDidmount] = useState(false);
    const quillRef = useRef();
    const breakPoint = useMediaQuery({maxWidth:"573px"})
    // 573px

      useEffect(() => {
        setcomponantDidmount(true);
        return () => {
          setcomponantDidmount(false);
        }
      }, [])

  return (
    <>
      {" "}
      {componantDidmount && (
      <div
        style={{
          marginTop: "15px",
          height:"100%"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >

          <span style={{display:"flex",justifyContent:"space-between"}}>
            <div><p style={{fontWeight:"bold",marginTop:"5px",color:"grey"}}>Please Enter your message</p></div>
            </span>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              setEmail_templatevisible(true);
            }}
          >
            Email Template
          </Button>
        </div>
        <div style={breakPoint ? {height:"200px"} : { height: "250px", border: "1px solid grey" }}>
            <CustumToolbar toolbarId={'editTemp'} />
            <ReactQuill
                className="editTemplate"
                placeholder="Enter message..."
                value={emailList?.emailMessage || selectedtext || ""}
                modules={modules}
                formats={formats}
                style={{height:"83%", marginBottom: "100px", }}
                ref={quillRef}
                onChange={(value) => {
                    setEmailList({
                      emailMessage: value,
                    });
                }}
            />
        </div>
      </div>
      )}
    </>
  );
};

export default ContentEditable;
