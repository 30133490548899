import React, { useEffect } from "react";
import { Form, Input, Button, Layout, Row, Col, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { accountHandle } from "../../Account/state/actions";
import { SuccessForm } from "./SuccessForm";
import { useLocation } from "react-router-dom";
import { SomeThingWentWrong } from "./SomeThingWentWrong";
import { breadcrumHandle } from "../../Breadcrum/state/actions";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
const { Content } = Layout;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function ChangePassword() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 575 });
  useEffect(() => {
    return () => {
      dispatch(accountHandle.updatePasswordCleanUp());
    };
  }, []);
  const { updatePasswordData, updatePasswordError } = useSelector(
    (state) => state.account
  );

  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(breadcrumHandle.updateBreadCrum(pathname));
  }, [dispatch, pathname]);

  const [form] = Form.useForm();
  const onFinish = (values) => {
    dispatch(accountHandle.updatePassword(values));
};
const onBackButtonclick = (values) => {
  history.push("/contacts")
  };
  if (updatePasswordError) {
    return <SomeThingWentWrong status="500" title="500" subTitle={"Old password Mismatch"} onBackButtonclick={ onBackButtonclick} />;
  }
  if (
    updatePasswordData ||
    (updatePasswordData && updatePasswordData.length < 0)
  ) {
    return <SuccessForm title="Password updated successfully" />;
  }
  return (
    <Layout style={{ height: "100%", padding: "0px" }}>
      <Content>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ paddingLeft: "6px", paddingRight: "6px" }}
          >
            <div
              style={{
                padding: "1rem",
                border: "1px solid  rgba(0,0,0,0.2)",
                borderRadius: "8px",
                backgroundColor: "#fff",
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                transition: "0.3s",
                display: "flex",
                // height: "5%",
              }}
            >
              <Card style={{margin:"auto"}}>
                <Form
                  {...formItemLayout}
                  form={form}
                  name="register"
                  onFinish={onFinish}
                  scrollToFirstError
                >
                  <Form.Item
                    name="oldPassword"
                    label="Old Password"
                    // style={{minWidth:"500px"}}
                    rules={[
                      {
                        required: true,
                        message: "Please input your Old password !",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="newPassword"
                    label="New Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your new password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || value.length > 5) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error("Required minimum 6 characters password!")
                          );
                        },
                      }),
                    ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("newPassword") === value
                          ) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit" style={{margin: isMobile ? "5% 0 0 35%" : "5% 0 0 12%"}}>
                      Update
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}
export { ChangePassword };
