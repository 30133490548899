import React from "react";
import { Avatar } from "antd";
import { useMediaQuery } from "react-responsive";
import { FaHeadset } from "react-icons/fa";
import { MdOutlineOndemandVideo } from "react-icons/md";

const TutorialsVideos = () => {
  const isMobile = useMediaQuery({maxWidth:900})
  const isTab = useMediaQuery({maxWidth:1352});

  return (
    <div
      style={{
        alignItems: "center",
        width: "100%",
        margin: "auto",
      }}
    >
      <div
        style={{
          color: "black",
          fontSize: "22px",
          textAlign: "center",
          fontWeight: "bold",
          margin: "auto",
          width: "auto",
          position: "sticky",
          top: "0",
          zIndex: "100",
          background: "#fff",
          padding: "10px",
          borderBottom: "1px solid grey",
          letterSpacing:"1px",
          textTransform:"uppercase"
        }}
      >
        Tutorial Videos and Q&A
      </div>
      <div
        style={{
          width: "90%",
          display: "flex",
          justifyContent: isMobile ? "space-between" : "center",
          margin: "auto",
          paddingTop: "30px",
          gap:"20px",
          flexDirection: isMobile ? "column" : "row"
        }}
      >
        <a href="https://training.resure.realestate" rel="noreferrer" target="_blank">
          <div style={{ textAlign: "center",border:"1px solid grey",padding:"30px 80px",backgroundColor:"#F0FFFF", height:isTab ? "250px" : "200px", minWidth:"400px"}}>
            <Avatar
              size={80}
              style={{
                backgroundColor: "#0077D8",
                marginBottom:"10px",
              }}
              icon={<MdOutlineOndemandVideo style={{marginTop:"22px"}} />}
            />
            <div>
              <strong style={{ fontSize: "20px" }}>RE-Sure Training Course</strong>
            </div>
          </div>
        </a>
        <a href="https://connect.goresure.com/l/bP4MnyoFx" rel="noreferrer" target="_blank">
          <div style={{ textAlign: "center", border:"1px solid grey",padding:"30px 80px",backgroundColor:"#F0FFFF",height:isTab ? "250px" : "200px", minWidth:"400px"}}>
            <Avatar
              size={80}
              style={{
                backgroundColor: "#0077D8",
                marginBottom:"10px",
              }}
              icon={<FaHeadset  style={{marginTop:"19px"}} />}
            />
            <div>
              <strong style={{ fontSize: "20px" }}>RE-Sure Drop In Zoom Support</strong>
            </div>
            <div style={{fontSize: "14px",color:"black"}}>Monday to Friday from 12:00 PM to 1:00 PM</div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default TutorialsVideos;
