import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

// URL Shortening API
const urlShorthandApi = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(
    `${apiURL}/url`,
    {
      ...data,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

export const calendarApi = {
  urlShorthandApi,
};
