/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { documentAction } from "../../DocumentSection/state/actions";
import Step1 from "../../DocumentSection/components/OfferClone/StepOne";
import Step2 from "../../DocumentSection/components/OfferClone/StepTwo";
import { Button, Modal, Steps, Alert, Spin } from "antd";
import { message } from "antd";
import { checkAllRequiredFields } from "../../DocumentSection/utils/helper";
const { Step } = Steps;

const OfferImportModal = ({ selectedData, isModalOpen, setIsModalOpen, section }) => {
  const delegate =  (section === "DELEGATE");
  const dispatch = useDispatch();
  const [importModalStep, setImportModalStep] = useState(0);
  const [importUrl, setImportUrl] = useState("");
  const [pdfData, setPdfData] = useState([]);
  const [fileText, setfileText] = useState([]);
  const [disable, setDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const agentDetails = selectedData?.currentAgentId

  const { urlImportErrorAccured, urlImportData, pdfImportError, CurrentPdfImportData } = useSelector((state) => state.documentSection.urlImportReducer, shallowEqual);

  const closeOfferImportModal = () => {
    setIsModalOpen({ offerImport: false });
    setImportModalStep(0);
    dispatch(documentAction.clearUrlData());
    setLoader(false);
    dispatch(documentAction.setCorpState({ clientsArray: [] }));
    dispatch(documentAction.setpdfImportState({}));
    let messsageDiv = document.getElementById("showMsgDiv");
    if (messsageDiv) {
      messsageDiv.style.display = "none";
    }
    let errorDiv = document.getElementById("pdfError");
    if (errorDiv) {
      errorDiv.style.display = "none";
    }
  };

  useEffect(() => {
    if (urlImportErrorAccured) {
      closeOfferImportModal();
      dispatch(documentAction.urlImportErrorClear());
    }
  }, [urlImportErrorAccured]);

  const importPDFApiCall = (redirect) => {
    let payload = {
      file: pdfData,
      propertyId: selectedData.propertyId,
      transactionData: CurrentPdfImportData,
      createOffer: true,
      clonefromPdf: true,
      ...(delegate ? { delegateSide: "SELLER", delegateOwnerId: agentDetails?._id } : {}),
    };
    let buildData = {
      builtForId: agentDetails?._id,
      builtForSection: "OFFER_TABLE",
      propertyId: selectedData?.propertyId,
      propertyImage: selectedData?.images[0]?.imageUrl,
      fullAddress: selectedData?.address,
      token: localStorage.getItem("userAuth"),
      ...(delegate ? { delegateOwnerId: agentDetails?._id } : {}),
    };
    // console.log("payload",delegate, payload)
    dispatch(documentAction.urlImport(payload, redirect, buildData));
  };

  useEffect(() => {
    if (pdfImportError) {
      setfileText([]);
      setPdfData([]);
      setImportModalStep(0);
      dispatch(documentAction.pdfError(false));
      setLoader(false);
      let errorDiv = document.getElementById("pdfError");
      if (errorDiv) {
        errorDiv.style.display = "block";
      }
    }
  }, [pdfImportError]);

  const steps = [
    {
      title: "Upload",
      content: (
        <Step1
          source={"PDF_IMPORT"}
          importUrl={importUrl}
          setImportUrl={setImportUrl}
          beforeUpload={beforeUpload}
          handleCustome={handleCustome}
          fileText={fileText}
        />
      ),
    },
    {
      title: "Confirm",
      content: <Step2 source={"PDF_IMPORT"} />,
    },
  ];

  const getDataForImportPdf = (createOffer) => {
    dispatch(
      documentAction.urlImport({
        file: pdfData,
        clonefromPdf: true,
        createOffer,
        propertyId: selectedData?.propertyId,
        ...(delegate ? { delegateSide: "SELLER", delegateOwnerId: agentDetails?._id  } : {}),
      })
    );
  };

  const prev = () => {
    setImportModalStep(importModalStep - 1);
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "application/pdf";

    if (!isJpgOrPng) {
      message.error("You can only upload PDF file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("PDF must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  }
  function bytesToSize(bytes) {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }
  function handleCustome(event) {
    dispatch(documentAction.clearUrlData());
    setfileText({ name: event.file.name, size: bytesToSize(event.file.size) });
    setPdfData(event.file);
    let errorDiv = document.getElementById("pdfError");
    if (errorDiv) {
      errorDiv.style.display = "none";
    }
  }

  const handleNextButtonClick = () => {
    if (importModalStep === 0) {
      if (!Object.keys(CurrentPdfImportData)?.length) {
        getDataForImportPdf(false);
      }
      setImportModalStep(importModalStep + 1);
    } else if (importModalStep === 1) {
      let messsageDiv = document.getElementById("showMsgDiv");
      if (messsageDiv) {
        messsageDiv.style.display = "none";
      }
      setLoader(true);
      importPDFApiCall(true);
    }
  };

  useEffect(() => {
    if (importModalStep === 0) {
      setDisabled(!fileText?.size);
    } else if (importModalStep === 1) {
      setDisabled(!checkAllRequiredFields(CurrentPdfImportData));
    } else {
      setDisabled(true);
    }
  }, [importModalStep, CurrentPdfImportData, fileText]);

  return (
    <>
      <Modal
        className="offer-clone"
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              margin: "auto",
              fontSize: "22px",
            }}
          >
            <div>
              <h3 style={{ color: " #3d82e3" }}>Offer Import</h3>
            </div>
            <div id="pdfError" style={{ display: "none", color: "red", width: "70%", fontSize: "14px", textAlign: "center" }}>
              Uploaded pdf document not supported! Please contact resure support if the document uploaded was valid.
            </div>
            {urlImportData && Object.keys(urlImportData)?.length && importModalStep === 1 ? (
              <div id="showMsgDiv" style={{ fontSize: "10px", fontWeight: "400", textAlign: "left" }}>
                <Alert style={{ width: "100%" }} message={"Please verify the information below matches the values in the pdf. We've done our best to retrieve values from the pdf, but occasionally the values are not formatted correctly. Changing the information below should only be done to make it consistent with the values in the imported offer. You may need to complete a counterproposal or amend/extend document to change any values in the imported offer."} type="info" showIcon />
              </div>
            ) : null}
          </div>
        }
        visible={isModalOpen?.offerImport}
        onOk={() => closeOfferImportModal()}
        onCancel={() => closeOfferImportModal()}
        footer={null}
        afterClose={() => closeOfferImportModal()}
        width={800}
        style={{ top: 20, height: "90vh" }}
      >
        {loader ? (
          <div style={{ height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            <Steps current={importModalStep}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>

            <div className="steps-content">{steps[importModalStep]?.content}</div>

            <div className="steps-action" style={{ marginTop: "30px" }}>
              {importModalStep > 0 && (
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  onClick={() => prev()}
                >
                  Previous
                </Button>
              )}
              {importModalStep === 0 && (
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  onClick={() => closeOfferImportModal()}
                >
                  Cancel
                </Button>
              )}
              <Button
                type="primary"
                onClick={() => {
                  handleNextButtonClick();
                }}
                disabled={disable}
              >
                {importModalStep === 1 ? "Save And Import" : "Next"}
              </Button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export { OfferImportModal };
