/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Carousel, Modal, Tabs } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { DocumentSign } from "./DocumentSign";
import { EmailFile } from "./EmailFile";
import { useDispatch, useSelector } from "react-redux";
import { transactionLaneDataHandle } from "../state/actions";
import TabPane from "antd/lib/tabs/TabPane";
import rightArrow from "../../Assets/rightArrow.png";
import leftArrow from "../../Assets/leftArrow.png";
import { useMediaQuery } from "react-responsive";
import { generateInitials } from "../../Common/utils/extraFunctions";
import { getcombinedClients, } from "../../PropertyComp/components/helper";
import { Loading } from "../../Common";
import axios from "axios";
import { PropertyHandlers } from "../../PropertyComp/state/actions";
import { AddEmailModalRtd } from "./AddEmailModalRtd";

const ClientPortaModal = ({
  showmodal,
  setShowModal,
  Role,
  offerid,
  thirdpartyIdDoc,
  ertsId,
  personId,
  propertyId,
  delegate,
  clientNullCheck,
  setIsModalOpen,
  isModalOpen,
  filterSort,
  selectedData
}) => {
  const [activeTabKey, setActiveTabKey] = useState("1");
  const dispatch = useDispatch();
  const [highlight, setHeighlight] = useState(null);
  let { propertyDocsWithoutSection } = useSelector((state) => state.PropertyReducer);
  const { getIndividualTransactionData } = useSelector((state) => state.rtdTransactionLane);
  const isMobile = useMediaQuery({maxWidth: 545});
  const isTab = useMediaQuery({minWidth: 546 ,maxWidth: 760});
  const isLarge = useMediaQuery({minWidth: 761 ,maxWidth: 990});

  const spot1 = useMediaQuery({minWidth: 420, maxWidth: 540});
  const spot2 = useMediaQuery({minWidth: 615, maxWidth: 759});
  const spot3 = useMediaQuery({minWidth: 870, maxWidth: 990});

  let { propertDocsWithoutSectionLoading  } = useSelector((state) => state.PropertyReducer);



  const buildQuery = (highlight) => {
    return  {
      ...(offerid ? { offerId: offerid } : {}),
      ...(ertsId ? { ertsId: ertsId } : {}),
      ...(thirdpartyIdDoc ? { thirdpartyIdDoc: thirdpartyIdDoc } : {}),
      ...(Role ? { role: Role } : {}),
      ...(highlight?.key ? { key: highlight?.key } : { key: highlight?._id }),
      ...(highlight?._id ? { _id: highlight?._id } : {}),
    };
  }
  useEffect(() => {
    let unmounted = {
      value: false
    };
    let source = axios.CancelToken.source();
    let type="SendEmail"
    let params = {
      ...(offerid ? { offerId: offerid } : {}),
      ...(ertsId?  { ertsId: ertsId }: propertyId ? { propertyId: propertyId } : {}),
      ...(thirdpartyIdDoc? { thirdpartyIdDoc:thirdpartyIdDoc } : {}),
      openFor:(Role ==="SELLER")? "SELLER_AGENT" : "BUYER_AGENT",
      personId: personId,
     // need changes for sending the delegateOwnerId also
      ...(delegate ? ((Role ==="SELLER")) ? { delegateSide: "SELLER" } : { delegateSide: "BUYER" } : {}),
    };

  dispatch(PropertyHandlers.getPropertyDocsFun({ params, unmounted, source,type }));
}, []);

const combinedClientsData = useMemo(() => {
  const data = getcombinedClients({
    allContractDocuments: propertyDocsWithoutSection || [],
    propertySellerIds: Role === "SELLER" ? getIndividualTransactionData?.sellerIds : [],
  });
  return {
    combinedBuyerIds: data.combinedBuyerIds,
    combinedSellerIds: data.combinedSellerIds,
  };
}, [propertyDocsWithoutSection, getIndividualTransactionData]);

function firstSeller(){
  if(Role==="BUYER"?combinedClientsData?.combinedBuyerIds?.length>0:combinedClientsData?.combinedSellerIds?.length>0){
  const firstSeller = (Role==="BUYER"?combinedClientsData?.combinedBuyerIds:combinedClientsData?.combinedSellerIds)?.[0];
 if(firstSeller?.isCorp){
    return {
      idx: "00",
      key: firstSeller?.key,
      ...(firstSeller?.signers?.[0]?.personId || {})
    }
  }
  else{
    return {
      idx: "0",
      ...(firstSeller?.personId || {})
    }
  }
}
}

useEffect(()=>{
  let nullFound=false;
  if(combinedClientsData?.combinedBuyerIds.length>0||combinedClientsData?.combinedSellerIds.length>0){
if(Role==="BUYER"){
    nullFound = clientNullCheck({clientIds: combinedClientsData?.combinedBuyerIds})
  }else{
    nullFound = clientNullCheck({clientIds: combinedClientsData?.combinedSellerIds})
  }
if (nullFound) {
  setIsModalOpen({...isModalOpen,isAllEmailsModalOpen:true,isEmailModalVisible:false})

  }else{
  setIsModalOpen({...isModalOpen,isAllEmailsModalOpen:false})
  setHeighlight(firstSeller())
  }

}
},[propertyDocsWithoutSection])


useEffect(() => {
if((combinedClientsData?.combinedBuyerIds.length>0||combinedClientsData?.combinedSellerIds.length>0)&&propertyDocsWithoutSection.length>0){
  setActiveTabKey("1");
    const query = buildQuery(highlight, offerid, ertsId, thirdpartyIdDoc, Role);
    dispatch(transactionLaneDataHandle.ClientPortalData(query));
  }}, [highlight]);


  const handleTabChange = (ele) => {
    setActiveTabKey(ele);
    if(ele === "1"){
      let query = buildQuery(highlight);
      dispatch(transactionLaneDataHandle.ClientPortalData(query));
    }
    else {
      let query = {
        ...(offerid ? { offerId: offerid } : {}),
        ...(ertsId ? { ertsId: ertsId } : {}),
        ...(thirdpartyIdDoc ? { thirdpartyIdDoc: thirdpartyIdDoc } : {}),
        ...(Role ? { role: Role } : {}),
        ...(highlight?._id ? { _id: highlight?._id } : {}),
      };
      const params = {
        ...(offerid ? { offerId: offerid } : {}),
        ...(ertsId ? { ertsId: ertsId } : {}),
        ...(thirdpartyIdDoc ? { thirdpartyIdDoc: thirdpartyIdDoc } : {}),
        openFor: Role,
        ...(highlight?._id ? { personId: highlight?._id } : {}),
      };
      dispatch(transactionLaneDataHandle.getPropertyDocsFun(params));
      dispatch(transactionLaneDataHandle.EmailFileData(query));
    }
  }

  let clientCount = isMobile ? 1 : isTab ? 2 : isLarge ? 3 : 4;
  let multipleFour = 0;
  const settings = {
    nextArrow: <img src={rightArrow} alt=""/>,
    prevArrow: <img src={leftArrow} alt=""/>
  };

  let arrayTemp = [];
  return (
    <>
  {!isModalOpen.isAllEmailsModalOpen?
  <Modal
      visible={showmodal}
      onOk={() => {}}
      footer={false}
      width={"960px"}
      style={{ marginTop: "-75px" }}
      onCancel={() => {
        setShowModal(false)
   }}
    >
      <div
        style={{
          textAlign: "center",
          color: "#888888",
          overFlow: "auto",
          height: "85vh",
          fontSize: "18px",
          padding: "5px",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          borderBottomRightRadius: "5px",
          borderBottomLeftRadius: "5px",
        }}
      >
        <div style={{ fontWeight: "bold" }}>Client Portal</div>
        <div
          style={{
            padding: "0px 30px"
          }}
        >
      {propertDocsWithoutSectionLoading  ? (
        <div>
          <Loading />
        </div> ):   <Carousel dots={false} arrows {...settings}>
            {/* made rendering corp specific with added logic */}
            {
              (Role==="BUYER"?combinedClientsData?.combinedBuyerIds:combinedClientsData?.combinedSellerIds)?.map((seller, index) => {
                let endOfSeller = (index === ((Role==="BUYER"?combinedClientsData?.combinedBuyerIds:combinedClientsData?.combinedSellerIds).length - 1))
                if(seller?.isCorp){
                  return seller?.signers?.map((signer, idx)=> {
                    let endOfSigner = (idx === (seller?.signers?.length - 1))
                    arrayTemp[multipleFour%clientCount] =  profileView({profile: signer, corpName: seller?.fullName, idx: (index + "" + idx), key: seller?.key, highlight, setHeighlight,isMobile,spot1,spot2,spot3})
                    ++multipleFour;
                    if((multipleFour%clientCount == 0) || (endOfSeller && endOfSigner && ((multipleFour%clientCount)>0))){
                      let renderArray = [...arrayTemp];
                      arrayTemp = [];
                      return <div><div style={{display: "flex", flexDirection: "row"}}>{renderArray.map((ele)=>ele)}</div></div>
                    }
                  })
                }
                else{
                  arrayTemp[multipleFour%clientCount] = profileView({profile: seller, idx: (index + ""), highlight, setHeighlight,isMobile,spot1,spot2,spot3});
                  ++multipleFour;
                  if((multipleFour%clientCount == 0) || (endOfSeller && ((multipleFour%clientCount)>0))){
                    let renderArray = [...arrayTemp];
                    arrayTemp = [];
                    return <div><div style={{display: "flex", flexDirection: "row"}}>{renderArray.map((ele)=>ele)}</div></div>
                  }
                }
              })
            }
          </Carousel>}
        </div>
        <div style={{ width: "100%" }}>
        {propertDocsWithoutSectionLoading ? (
        <div>
          <Loading />
        </div> ): <div style={{ display: "flex", width: "100%", fontSize: "15px" }}>
            <Tabs
              activeKey={activeTabKey}
              onChange={handleTabChange}
              defaultActiveKey="1"
              style={{ width: "100%", height: "calc(100vh - 8.4rem)" }}
              className="centered-tabs"
              tabBarGutter={10}
            >
              <TabPane
                tab="Document Signing"
                key="1"
                style={{ width: "100%", height: "100%", paddingTop: "15px" }}
              >
                <DocumentSign
                  id={(Role==="BUYER"?combinedClientsData?.combinedBuyerIds:combinedClientsData?.combinedSellerIds)?.[0]?.personId?._id}
                  offerIds={offerid}
                  role={Role}
                  thirdpartyIdDoc={thirdpartyIdDoc}
                  ertsId={ertsId}
                />
              </TabPane>
              <TabPane
                tab="Email & Files"
                key="2"
                style={{ width: "100%", height: "100%", fontSize: "18px" }}
              >
                <EmailFile
                  id={(Role==="BUYER"?combinedClientsData?.combinedBuyerIds:combinedClientsData?.combinedSellerIds)?.[0]?.personId?._id}
                  offerIds={offerid}
                  role={Role}
                  thirdpartyIdDoc={thirdpartyIdDoc}
                  ertsId={ertsId}
                />
              </TabPane>
            </Tabs>
          </div>}
        </div>
      </div>
    </Modal>:
    <AddEmailModalRtd modalVisible={isModalOpen} setModalVisible={setIsModalOpen} selectedData={selectedData} combinedClients={(Role ==="SELLER") ? combinedClientsData?.combinedSellerIds:combinedClientsData?.combinedBuyerIds} seller={(Role ==="SELLER") ?  "SELLER":"BUYER" } filterSort={filterSort} delegateSide={delegate && ((Role ==="SELLER") ?  "SELLER":"BUYER" )} offerId={offerid} propertyId={ propertyId} setShowModal={ setShowModal}/>
   } </>
  );
};

const profileView = ({ profile, corpName, idx, key, highlight, setHeighlight, isMobile, spot1, spot2, spot3 }) => {
  // console.log("Profile", profile);
  const initials = generateInitials(profile?.personId?.fullName || profile?.fullName);
  const handleclientPortal = () => {
    setHeighlight({
      idx,
      ...(profile?.personId || {}),
      ...(key ? {key} : {})
    })
  };

  return <div key={idx}>
    <div
      style={{
        width: spot3 ? "250px" : spot2 ? "250px" : spot1 ? "300px" : isMobile ? "230px" : "206px", // Adjust the width as needed
        height: "70px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        cursor: "pointer",
        padding: "8px", // Adjust the padding as needed
        margin: "2px", // Adjust the margin as needed
        display: "flex",
        alignItems: "center",
        backgroundColor: (highlight?.idx === idx) ? "#96c8f6" : "white"
      }}
      onClick={() => handleclientPortal()}
    >
      {profile?.personId?.profileImg?.original &&
      profile?.personId?.profileImg?.original !==''?
      <Avatar
        src={profile?.personId?.profileImg?.original}
        style={{
          backgroundColor: "#0078d4",
          borderRadius: "50%",
          objectFit:"cover"
        }}
        size={40}
      /> :
      <Avatar
        src={null}
        style={{
            backgroundColor: "#0078d4",
            borderRadius: "50%",
        }}
        size={40}
      >
        {initials}
      </Avatar>
      }
      <div style={{ flex: 1 }}>
        <div
          style={{
            overflow: "hidden",
            textAlign: "left",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "140px",
            fontWeight: "500",
            color: "#333",
            fontSize: "16px",
            marginLeft: "10px",
          }}
        >
          {profile?.fullName}
        </div>
        {
          corpName ?
          <div
            style={{
              overflow: "hidden",
              textAlign: "left",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "120px",
              fontSize: "16px",
              color: "#555",
              marginLeft: "10px",
            }}
         >
           {corpName}
         </div> : ""
        }
      </div>
    </div>
    
  </div>
}

export { ClientPortaModal };
