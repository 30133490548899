import { Button, Collapse, Modal, Input, Tabs, Tooltip, List } from "antd";
import React, { useState } from "react";
import { DeleteTwoTone, ExclamationCircleOutlined, CaretRightOutlined, PlusCircleFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import TabPane from "antd/lib/tabs/TabPane";
import {generateInitialsFromObj } from "../../Common/utils/extraFunctions";
import { documentAction } from "../state/actions";
import { useMediaQuery } from "react-responsive";
import ManalAddClient from "./ManalAddClient";
import EachCorpSigner from "./EachCorpSigner";
const { confirm } = Modal;
const { Panel } = Collapse;
const ManualEditCorpOrClient = ({ sourceType }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  let { clientsArray, currentlySelectedCorpData, entityName } = useSelector((state) => state.documentSection.CorpReducer);

  const [showClientListModal, setShowClientListModal] = useState(false);

  const handleAddSigners = (element) => {
    setShowClientListModal(true);
    dispatch(documentAction.setCorpState({ currentlySelectedCorpData: element }));
  };

  let handleCancel = () => {
    setShowClientListModal(false);
  };

  const handleEntityNameChange = (e) => {
    dispatch(documentAction.setCorpState({ entityName: e.target.value }));
  };

  const handleCorpAdd = (type) => {
    dispatch(documentAction.setCorpState({ entityName: "" }));
    let corpInfo = {
      fullName: entityName?.trim(),
      isCorp: true,
      key: uuidv4(),
      signers: [],
    };
    let newData = [...(clientsArray || []), corpInfo];
    handleDataChange({ Buyers: newData });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleCorpAdd();
    }
  };
  const handleDeleteClientOrCorp = (element, type, item) => {
    if (clientsArray) {
      if (type === "CORP") {
        handleCorpDelete(element);
        return;
      } else if (type === "SIGNERS") {
        let newDataIndex = clientsArray?.findIndex((el) => el.key === element.key);
        if (newDataIndex !== -1) {
          clientsArray[newDataIndex].signers = clientsArray[newDataIndex]?.signers?.filter((signer) => {
            if (item.key) {
              return signer.key !== item.key;
            } else {
              return signer.personId !== item.personId;
            }
          });
        }
      } else {
        clientsArray = clientsArray?.filter((el) => {
          if (element.personId) {
            return el.personId !== element.personId;
          } else {
            return el.key !== element.key;
          }
        });
      }
      handleDataChange({ Buyers: clientsArray });
    }
  };
  const handleCorpDelete = (element = {}) => {
    confirm({
      title: `Are you sure you want to remove ${element.fullName} from this form?`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      content: "Note: This will also remove all the signers associated with it.",
      onOk() {
        let newData = clientsArray?.filter((el) => el.key !== element.key);
        handleDataChange({ Buyers: newData });
      },
      onCancel() {},
    });
  };
  let handleDataChange = (updatedClients) => {
    dispatch(documentAction.setpdfImportState(updatedClients));
  };
  return (
    <>
      {showClientListModal && (
        <Modal width={600} style={{ height: "600px" }} visible={showClientListModal} title={`Add Signers ${currentlySelectedCorpData.fullName ? "for " + currentlySelectedCorpData.fullName : ""}`}
        onOk={handleCancel} onCancel={handleCancel} footer={null}>
          <ManalAddClient sourceType={sourceType} source={"CORP"} currentlySelectedData={currentlySelectedCorpData} clientsArray={clientsArray}  handleDataChange={handleDataChange}/>
        </Modal>
      )}

      <Tabs defaultActiveKey="person" size="small" type="card">
        <TabPane tab={<span style={{ textTransform: "none" }}>Persons</span>} key="person">
       <ManalAddClient sourceType={sourceType} clientsArray={clientsArray} handleDataChange={handleDataChange} /> 
        </TabPane>

        <TabPane tab={<span style={{ textTransform: "none" }}>Corporation/Trust/Other</span>} key="corporation">
          <div style={{ display: "flex", width: "100%", minHeight: "350px", maxHeight: "500px", flexDirection: "column", alignItems: "stretch", overflow: "auto" }}>
            <div style={{ display: "flex", gap: "10px", width: "100%" }}>
              <Input placeholder="Enter Corporation/Trust Name" onKeyPress={(e) => handleKeyPress(e)} name="trustee" value={entityName || ""} onChange={(e) => handleEntityNameChange(e)} />
              <Button type="primary" onClick={(e) => handleCorpAdd("corporation")} disabled={!entityName}>
                Add
              </Button>
            </div>
            <Collapse accordion bordered={false} expandIcon={({ isActive }) => <CaretRightOutlined style={{ fontSize: "20px" }} rotate={isActive ? 90 : 0} />} style={{ background: "white" }}>
              {clientsArray &&
                clientsArray
                  .filter((ele) => ele.isCorp)
                  .map((ele, ind) => (
                    <Panel
                      key={ind}
                      header={
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span style={{ fontSize: "18px" }}>{ele.fullName}</span>
                            <>
                              {" "}
                              <div style={{ position: "absolute", right: "70px" }}>
                                <Tooltip title="Add Signers">
                                  <PlusCircleFilled
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleAddSigners(ele);
                                      return;
                                    }}
                                    style={{ fontSize: "20px", fill: "#085191", color: "#085191", marginTop: "6px" }}
                                  />
                                </Tooltip>
                              </div>
                              <div style={{ position: "absolute", right: "20px", cursor: "pointer" }}>
                                <Tooltip title="Delete Corporation/Trust">
                                  <DeleteTwoTone
                                    style={{ fontSize: "medium", marginTop: "8px" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteClientOrCorp(ele, "CORP");
                                      return;
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            </>
                          </div>
                        </div>
                      }
                    >
                      <List
                        dataSource={ele.signers || []}
                        style={{ overflowY: "auto", minHeight:"250px", maxHeight: "250px" }}
                        renderItem={(item) => {
                          const intials = generateInitialsFromObj(item);
                          return <EachCorpSigner key={item?._id} item={item} ele={ele} intials={intials} isMobile={isMobile} handleDelete={handleDeleteClientOrCorp} clientsArray={clientsArray} handleDataChange={handleDataChange} />;
                        }}
                      />
                    </Panel>
                  ))}
            </Collapse>
          </div>
        </TabPane>
      </Tabs>
    </>
  );
};

export default ManualEditCorpOrClient;
