import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const templateDataRequest = ({contractType}) => {
  let accessToken = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/templates`, {
    params: {
      ...(contractType ? { contractType } : {}),
    },
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const editemplateDataRequest = (payload) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.put(
    `${apiURL}/template`,
    {
      ...payload,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const deleteTemplateRequest = (id) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.delete(`${apiURL}/templates/${id}`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const templateShortening = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(
    `${apiURL}/url/builtForSection`,
    {
      ...data,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

// const UpdatePdfRequest = (data) => {
//   let accessTokan = localStorage.getItem("userAuth");
//   let payload = new FormData()
//   payload.append('fileData', data)
//   payload.append('fileType', 'PDF')
//   return axios.post(
//     `${apiURL}/doctoJSX`,
//     payload,
//     {
//       headers: {
//         // accept: "application/json",
//         'Content-Type': 'multipart/form-data',
//         authorization: `bearer ${accessTokan}`,
//         pdfcoKey : accessTokan

//       },
//     }
//   );
// }

export const templateDataApi = {
  templateDataRequest,
  deleteTemplateRequest,
  templateShortening,
  editemplateDataRequest,
};
