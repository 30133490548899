import { Space } from "antd";
import React from "react";
const IconText = ({ icon, text, item, handle, likedStatus ,comments }) => (
  <Space
    onClick={() => handle(item)}
    style={
      text === "ACTIVE"
        ? { color: "red" }
        : text === "Add Comment" ? {color:"#1990ff",cursor:"pointer"}
        : likedStatus
        ? { color: "blue", cursor: "pointer" }:comments <= 0 ? { color: "grey", cursor: "pointer"}
        : { color: "green", cursor: "pointer" }
    }
  >
    {React.createElement(icon)}
    {text}
  </Space>
);

export default IconText;
