import { combineBrokersFirmName, formatPhoneNumber } from "../../Common/utils/extraFunctions";
import { getFullNamesFromArray } from "../../DocumentSection/utils/helper";
const moment = require("moment");
export const getBuildDataForNewClientdoc = ({ createdDocItem, parentClientdocItem, personDetails, getUserDetailsData, brokerLicenseId, docSecondItem, personDocs }) => {
  let Buyers = [];
  let Sellers = [];
  let BuyerAgents = [],
    SellerAgents = [];
  if (["ERTB", "BDB"].includes(parentClientdocItem.contractType) || docSecondItem?.contractType === "LCAE") {
    Buyers.push({
      personId: personDetails?._id,
      fullName: personDetails?.fullName,
      isCorp: false,
      signers: [],
    });
    BuyerAgents.push({
      personId: getUserDetailsData?._id,
      fullName: getUserDetailsData?.fullName,
      email: getUserDetailsData?.email,
      firmName: getUserDetailsData?.brokerageData?.brokerageName,
      resureFirmId: getUserDetailsData?.brokerageData?.brokerageId?._id,
      firmLicense: getUserDetailsData?.brokerageData?.brokerageLicenseNumber,
      license: brokerLicenseId,
      address: getUserDetailsData?.brokerageData?.brokerageAddress || "",
      phoneNumber: formatPhoneNumber(getUserDetailsData?.mobilePhoneNumber?.phoneNumber),
      faxNumber: formatPhoneNumber(getUserDetailsData?.brokerageData?.brokerageFaxNumber, "FAX") || "",
    });
  } else {
    Sellers.push({
      personId: personDetails?._id,
      fullName: personDetails?.fullName,
      isCorp: false,
      signers: [],
    });
    SellerAgents.push({
      personId: getUserDetailsData?._id,
      fullName: getUserDetailsData?.fullName,
      email: getUserDetailsData?.email,
      resureFirmId: getUserDetailsData?.brokerageData?.brokerageId?._id,
      firmName: getUserDetailsData?.brokerageData?.brokerageName,
      firmLicense: getUserDetailsData?.brokerageData?.brokerageLicenseNumber,
      license: brokerLicenseId,
      address: getUserDetailsData?.brokerageData?.brokerageAddress || "",
      phoneNumber: formatPhoneNumber(getUserDetailsData?.mobilePhoneNumber?.phoneNumber),
      faxNumber: formatPhoneNumber(getUserDetailsData?.brokerageData?.brokerageFaxNumber, "FAX") || "",
    });
  }

  let buildData = {
    urlData: {
      ...(Buyers.length ? { Buyers } : {}),
      ...(Sellers.length ? { Sellers } : {}),
      ...(BuyerAgents.length > 0 ? { BuyerAgents, BuyerBrokersList: getFullNamesFromArray(BuyerAgents), BuyerBrokersFirmList: combineBrokersFirmName(BuyerAgents) } : {}),
      ...(SellerAgents.length > 0 ? { SellerAgents, SellerBrokersList: getFullNamesFromArray(SellerAgents), SellerBrokersFirmList: combineBrokersFirmName(SellerAgents) } : {}),
      ...(docSecondItem?.contractType === "LCAE" ? { LcaeCheckedListingContract: "ERTBListingContract" } : docSecondItem?.contractType === "LCAES_ERTL" ? { LcaeCheckedListingContract: "ERTLListingContract" } : docSecondItem?.contractType === "LCAES_ETCT" ? { LcaeCheckedListingContract: "ExclusiveTenantListingContract" } : {}),
      ...(docSecondItem?.contractType === "LCAE" ? { LcaeInitatingParty: "LcaeInitatingPartyBuyer" } : docSecondItem?.contractType === "LCAES_ERTL" ? { LcaeInitatingParty: "LcaeInitatingPartyLandlord" } : docSecondItem?.contractType === "LCAES_ETCT" ? { LcaeInitatingParty: "LcaeInitatingPartyTenant" } : {}),
      ...(docSecondItem?.contractType ? { ...createdDocItem.transactionData } : {}),
    },
    openFor: ["ERTB", "BDB"].includes(parentClientdocItem.contractType) || docSecondItem?.contractType === "LCAE" ? "BUYER_AGENT" : "SELLER_AGENT",
    RTDInitiator: ["ERTB", "BDB"].includes(parentClientdocItem.contractType) || docSecondItem?.contractType === "LCAE" ? "BUYER" : "SELLER",
    contractId: docSecondItem?.contractId ? docSecondItem?.contractId : parentClientdocItem.contractId,
    contractType: docSecondItem?.contractType ? docSecondItem?.contractType : parentClientdocItem.contractType,
    ...(docSecondItem?.contractType ? { clientDocId: createdDocItem._id } : {}),
    token: localStorage.getItem("userAuth"),
    builtForSection: "DOCUMENT",
    builtForId: getUserDetailsData?._id,
    key: getUserDetailsData?._id,
    signable: true,
  };
  return buildData;
};

export const getMaindocumentStatus = (ele) => {
  const { contractType, createdDocuments } = ele || {};

  if (!createdDocuments || !createdDocuments.length) {
    return { color: "whitesmoke", highlightedDocId: null };
  }

  let fullySignedDoc;
  let color = "yellow";

  // DWR, BDS, BDB contract types
  if (contractType === "DWR" || contractType === "BDS" || contractType === "BDB") {
    const statusKey = (contractType === "DWR" || contractType === "BDS") 
      ? ["sellerSignStatus", "sellerAgentSignStatus"]
      : ["buyerSignStatus", "buyerAgentSignStatus"];

    fullySignedDoc = createdDocuments.find((el) => 
      el[statusKey[0]] === "SUBMITTED" && el[statusKey[1]] === "SUBMITTED"
    );

    if (fullySignedDoc) {
      return { color: "green", highlightedDocId: fullySignedDoc._id };
    }
    return { color: "yellow", highlightedDocId: createdDocuments[0]._id };
  }

  // ERTB, ERTL, ETCT contract types
  if (contractType === "ERTB" || contractType === "ERTL" || contractType === "ETCT") {
    if (contractType === "ERTB") {
      // Buying side doc
      fullySignedDoc = createdDocuments.find((el) => 
        el.buyerSignStatus === "SUBMITTED" && el.buyerAgentSignStatus === "SUBMITTED"
      );
    } else if (contractType === "ERTL" || contractType === "ETCT") {
      // Selling side doc
      fullySignedDoc = createdDocuments.find((el) => 
        el.sellerSignStatus === "SUBMITTED" && el.sellerAgentSignStatus === "SUBMITTED"
      );
    }

    if (fullySignedDoc) {
      try {
        const listingEndDate = moment(fullySignedDoc.transactionData?.ListingPeriodEndByDate);

        if (listingEndDate.isValid()) {
          if (moment().isAfter(listingEndDate)) {
            let isAeFullySignedDoc;
            if (contractType === "ERTB") {
              // Buying side doc
              isAeFullySignedDoc = fullySignedDoc.matchedDocuments?.find((el) => 
                el.buyerSignStatus === "SUBMITTED" && el.buyerAgentSignStatus === "SUBMITTED"
              );
            } else if (contractType === "ERTL" || contractType === "ETCT") {
              // Selling side doc
              isAeFullySignedDoc = fullySignedDoc.matchedDocuments?.find((el) => 
                el.sellerSignStatus === "SUBMITTED" && el.sellerAgentSignStatus === "SUBMITTED"
              );
            }

            if (isAeFullySignedDoc) {
              const aeListingEndDate = moment(isAeFullySignedDoc.transactionData.ListingPeriodEndByDate);
              if (aeListingEndDate.isValid() && aeListingEndDate.isAfter(moment())) {
                color = "green";
              } else {
                color = "red"; // Document is expired
              }
            } else {
              color = "red"; // Document is expired
            }
          } else {
            let isAeFullySignedDoc;
            if (contractType === "ERTB") {
              isAeFullySignedDoc = fullySignedDoc.matchedDocuments?.find((el) => 
                el.buyerSignStatus === "SUBMITTED" && el.buyerAgentSignStatus === "SUBMITTED"
              );
            } else if (contractType === "ERTL" || contractType === "ETCT") {
              isAeFullySignedDoc = fullySignedDoc.matchedDocuments?.find((el) => 
                el.sellerSignStatus === "SUBMITTED" && el.sellerAgentSignStatus === "SUBMITTED"
              );
            }

            if (isAeFullySignedDoc) {
              const aeListingEndDate = moment(isAeFullySignedDoc.transactionData.ListingPeriodEndByDate);
              if (aeListingEndDate.isValid() && aeListingEndDate.isAfter(moment())) {
                color = "green";
              } else {
                color = "red"; // Document is expired
              }
            } else {
              color = "green"; // Document is not expired
            }
          }
        } else {
          color = "yellow"; // Invalid date
        }
      } catch (error) {
        console.error("Error processing date:", error);
        color = "yellow";
      }

      return { color, highlightedDocId: fullySignedDoc._id };
    }
    return { color: "yellow", highlightedDocId: createdDocuments[0]._id };
  }
};


export const checkForDeleteClientDoc = (item) => {
  const { contractType, buyerSignStatus, sellerSignStatus, buyerAgentSignStatus, sellerAgentSignStatus } = item || {};
  if (["BDS", "DWR", "ERTL", "ETCT", "LCAES_ERTL", "LCAES_ETCT"].includes(contractType)) {
    if (sellerSignStatus==="SUBMITTED" &&  sellerAgentSignStatus === "SUBMITTED") {
      return false;
    }
    return true;
  }
  if (["ERTB", "BDB", "LCAE"].includes(contractType)) {
    if (buyerSignStatus==="SUBMITTED" &&  buyerAgentSignStatus === "SUBMITTED" ) {
      return false;
    }
    return true;
  }
};
