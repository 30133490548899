/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import RtdWindow from "./RtdWindow";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../Common/components/Loading";
import { transactionLaneDataHandle } from "../state/actions";
import axios from "axios";
import { useMediaQuery } from "react-responsive";


const RtdDesign = ({ client, section, currentAgentDetails,  allBuyerAgentData, allSellerAgentData }) => {
  const delegate = (section === "DELEGATE");
  const dispatch = useDispatch();
  const { getTransactionLaneLoading, getIndividualTransactionData, getTransactionLaneError, updateSuccessRtd } = useSelector((state) => state.rtdTransactionLane);
  const isSmallScreen = useMediaQuery({maxWidth:680})
 
  useEffect(()=>{
    const delegateSide = delegate && client;
    let unmounted = {
      value: false
    };
    let source = axios.CancelToken.source();
    if (getIndividualTransactionData?.offerId) {
      dispatch(transactionLaneDataHandle.getTransactionLaneMethod({offerId: getIndividualTransactionData?.offerId, role: client, unmounted, source, delegateSide, delegateOwnerId:delegateSide && getIndividualTransactionData?.currentAgentId?._id }));
    }
    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  }, [updateSuccessRtd, getIndividualTransactionData?.offerId]);

  return (
    <>
      {
        getTransactionLaneLoading ? <div style={{height: "60vh"}}>
          <Loading />
        </div> : (
          getTransactionLaneError ? "" : (
            <div
              style={{
                marginTop: "4%",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                minWidth: isSmallScreen ? "670px" : "",
              }}
            >
              <RtdWindow
              client={client}
              section={section}
              currentAgentDetails={currentAgentDetails}
              allBuyerAgentData={allBuyerAgentData} 
              allSellerAgentData={allSellerAgentData} 
             />
            </div>
          )
        )
      }
    </>
  );
};

export default RtdDesign;
