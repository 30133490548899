export const userSignUpConstants = {
  USER_SIGN_UP_LOADING: "USER_SIGN_UP_LOADING",
  USER_SIGN_UP_FAILURE: "USER_SIGN_UP_FAILURE",
  USER_SIGN_UP_SUCCESS: "USER_SIGN_UP_SUCCESS",

  USER_LOG_IN_LOADING: "USER_LOG_IN_LOADING",
  USER_LOG_IN_FAILURE: "USER_LOG_IN_FAILURE",
  USER_LOG_IN_SUCCESS: "USER_LOG_IN_SUCCESS",

  HANDLE_SHOW_NOTIFICATION:"HANDLE_SHOW_NOTIFICATION",

  USER_FORGET_PASSWORD_LOADING: "USER_FORGET_PASSWORD_LOADING",
  USER_FORGET_PASSWORD_FAILURE: "USER_FORGET_PASSWORD_FAILURE",
  USER_FORGET_PASSWORD_SUCCESS: "USER_FORGET_PASSWORD_SUCCESS",
  USER_FORGET_PASSWORD_CLEAR: "USER_FORGET_PASSWORD_CLEAR",

  FORGET_PASSWORD_VERIFICATION_LOADING: "FORGET_PASSWORD_VERIFICATION_LOADING",
  FORGET_PASSWORD_VERIFICATION_FAILURE: "FORGET_PASSWORD_VERIFICATION_FAILURE",
  FORGET_PASSWORD_VERIFICATION_SUCCESS: "FORGET_PASSWORD_VERIFICATION_SUCCESS",

  RESET_PASSWORD_SAVE_NEW_PASSWORD_LOADING:
    "RESET_PASSWORD_SAVE_NEW_PASSWORD_LOADING",
  RESET_PASSWORD_SAVE_NEW_PASSWORD_FAILURE:
    "RESET_PASSWORD_SAVE_NEW_PASSWORD_FAILURE",
  RESET_PASSWORD_SAVE_NEW_PASSWORD_SUCCESS:
    "RESET_PASSWORD_SAVE_NEW_PASSWORD_SUCCESS",

  RESET_PASSWORD: "RESET_PASSWORD",

  LOGOUT_ACTION: "LOGOUT_ACTION",

  RESET_ERROR: "RESET_ERROR",

  RESET_LOGOUT: "RESET_LOGOUT",

  RESET_ALERT: "RESET_ALERT",

  ASSOCIATION_LOADING : "ASSOCIATION_LOADING",
  ASSOCIATION_ERROR : "ASSOCIATION_ERROR",
  ASSOCIATION_SUCCESS : "ASSOCIATION_SUCCESS",

  GET_PRICES_LOADING: "GET_PRICES_LOADING",
  GET_PRICES_ERROR: "GET_PRICES_ERROR",
  GET_PRICES_SUCCESS: "GET_PRICES_SUCCESS",
};
