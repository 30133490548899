/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { transactionLaneDataHandle } from "../state/actions";
import { Tooltip } from "antd";
import styles from "../style/rtd.module.css";
import { useLocation } from "react-router-dom";
import { Modal } from "antd";
import moment from "moment";
import { Loading } from "../../Common/components/Loading";
import { FilePdfOutlined, CheckCircleFilled,  DeleteTwoTone } from "@ant-design/icons";
import { SignableAndReadOnly } from "../../PropertyComp/components/SignableAndReadOnly";
import { checkIfDisabled, createOfferContractTypes, disabledTransactionText } from "../../Common/commondata/commonData";

const { confirm } = Modal;

const SignalIcon = ({ source, documentStatus }) => {
  const { buyerSignStatus, buyerAgentSignStatus, sellerSignStatus, sellerAgentSignStatus, signatureSequence } = documentStatus || {};
  const getStatusColor = (status) => {
    switch (status) {
      case "SUBMITTED":
        return "green";
      case "DRAFT":
        return "white";
      case "INPROGRESS":
        return "yellow";
      default:
        return "white";
    }
  };
  let topColor, bottomColor, fullColor, indicatorNotRequired;
  if (source === "BUYER") {
    if (signatureSequence?.buyer && signatureSequence?.buyerAgent) {
      topColor = getStatusColor(buyerSignStatus);
      bottomColor = getStatusColor(buyerAgentSignStatus);
    } else if (signatureSequence?.buyer) {
      fullColor = getStatusColor(buyerSignStatus);
    } else if (signatureSequence?.buyerAgent) {
      fullColor = getStatusColor(buyerAgentSignStatus);
    } else {
      // fullColor = getStatusColor();
      indicatorNotRequired = true;
    }
  } else if (source === "SELLER") {
    if (signatureSequence?.seller && signatureSequence?.sellerAgent) {
      topColor = getStatusColor(sellerSignStatus);
      bottomColor = getStatusColor(sellerAgentSignStatus);
    } else if (signatureSequence?.seller) {
      fullColor = getStatusColor(sellerSignStatus);
    } else if (signatureSequence?.sellerAgent) {
      fullColor = getStatusColor(sellerAgentSignStatus);
    } else {
      // fullColor = getStatusColor();
      indicatorNotRequired = true;
    }
  }
  // console.log(source, documentStatus.contractType, topColor, bottomColor, fullColor);
  if (indicatorNotRequired) {
    return null;
  }
  return (
    <div className={styles.signalIconOutline}>
      <div
        className={fullColor ? styles.fullIndicator : styles.signalIndicator}
        style={{
          "--topColor": topColor,
          "--bottomColor": bottomColor,
          "--fullColor": fullColor,
        }}
      />
    </div>
  );
};

const SellerComp = (props) => {
  let timestamp = (props.RTDInitiator === "SELLER") ? props.dateSubmitted : props.latestDocumentSignatureAt;
  let toolTipMessage = timestamp && (props.RTDInitiator === "SELLER") ? "Date the document was sent to the Buyer Agent." : "Date the last required signer from the selling side signed the document.";
  const delegate = props?.section === "DELEGATE";
  let date = timestamp && new Date(timestamp);
  const dateNew = timestamp && moment(date).format("MM/DD/YYYY");
  const time = timestamp && moment(date).format("h:mm a");
  const dispatch = useDispatch();

  const confirmDeleteForm = (props) => {
    confirm({
      title: "Are you sure want to delete the form?",
      content: "The document will be removed from Transaction Dashboard but can still be accessed from Property Docs.",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        if (props?.documentId) {
          dispatch(transactionLaneDataHandle.deleteForm({ payload: { ...props, isToggleFrom: "RTD" }, ...(delegate ? { delegateSide: "SELLER", delegateOwnerId: props?.selectedData?.currentAgentId?._id } : {}) }));
        }
      },
      onCancel() {
        // Do nothing, just close the Modal
      },
    });
  };

  const checkForDisable = checkIfDisabled({ openFor: props.RTDInitiator, actualPropertyStatus: props?.selectedData?.actualPropertyStatus, buyingStatus: props?.selectedData?.buyingStatus, offerStatus: props?.selectedData?.status });
  const signalRequired = props?.signatureSequence?.seller || props.signatureSequence?.sellerAgent
  return (
    <div
      style={{
        flex: 1,
        justifyContent: "space-between",
        textAlign: "center",
        alignItems: "center",
        marginBottom: 0,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: signalRequired ? 23 : 35,
        }}
      >
        <>
          <div
            style={{
              width: "100%",
              borderStyle: props.RTDInitiator === "SELLER" ? "solid" : "none",
              height: 0,
              marginTop: 1,
              position: "relative",
              borderColor: props?.RTDInitiator === "SELLER" ? (props?.invalidated ? "darkgray" : "#2976be") : "white",
            }}
          >
            {props.RTDInitiator === "SELLER" &&
              props.client === "SELLER" &&
              (!props.checked.showBuyer ? (
                <></>
              ) : (
                <div style={{ position: "relative", marginTop: "-10px" }}>
                  <Tooltip title="Document sent to Buyer Agent">
                    <CheckCircleFilled style={{ fontSize: "18px", backgroundColor: "white", borderRadius: "100px", color: props?.invalidated ? "darkgray" : "green" }} />
                  </Tooltip>
                </div>
              ))}

            {/* DELETE a FORM */}
            {props.client === "SELLER" && props?.RTDInitiator === "SELLER" && !createOfferContractTypes.includes(props?.contractType) ? (
              <div style={{ position: "absolute", bottom: 0, left: 0, cursor: "pointer" }} {...(checkForDisable?.disabled ? { onClick: disabledTransactionText } : { onClick: () => confirmDeleteForm(props) })}>
                <Tooltip title="Delete Form">
                  <DeleteTwoTone />
                </Tooltip>
              </div>
            ) : null}
          </div>
          <SignalIcon source="SELLER" documentStatus={props} />
        </>
      </div>
      {/* {sellerDate && ( */}
      <div style={{display: "flex", justifyContent: "start"}}>
        {timestamp && <div style={{display: "inline-block"}}>
          <Tooltip title={toolTipMessage} placement="top">
          <p
              style={{
                textAlign: "left",
                marginLeft: 10,
                marginTop: props?.RTDInitiator === "BUYER" ? "-30px" : "-2px",
                fontSize: "13px",
                fontWeight: "bolder",
                // marginBottom:0
              }}
            >
              {dateNew}
            </p>
            <p
              style={{
                textAlign: "left",
                marginLeft: 20,
                marginTop: -18,
                fontSize: "13px",
                fontWeight: "bolder",
                marginBottom: 0,
              }}
            >
              {time}
            </p>
          </Tooltip>
        </div>}
      </div>
      
    </div>
  );
};

const BuyerComp = (props) => {
  let timestamp = (props.RTDInitiator === "BUYER") ? props.dateSubmitted : props.latestDocumentSignatureAt;
  let toolTipMessage = timestamp && (props.RTDInitiator === "BUYER") ? "Date the document was sent to the Seller Agent." : "Date the last required signer from the buying side signed the document.";
  const delegate = props?.section === "DELEGATE";
  let date = timestamp && new Date(timestamp);
  const dateNew = timestamp && moment(date).format("MM/DD/YYYY");
  const time = timestamp && moment(date).format("h:mm a");
  const dispatch = useDispatch();

  const confirmDeleteForm = (props) => {
    confirm({
      title: "Are you sure want to delete the form?",
      content: "The document will be removed from Transaction Dashboard but can still be accessed from Property Docs.",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        if (props?.documentId) {
          dispatch(transactionLaneDataHandle.deleteForm({ payload: { ...props, isToggleFrom: "RTD" }, ...(delegate ? { delegateSide: "BUYER", delegateOwnerId: props?.selectedData?.currentAgentId?._id } : {}) }));
        }
      },
      onCancel() {
        // Do nothing, just close the Modal
      },
    });
  };

  const checkForDisable = checkIfDisabled({ openFor: props.RTDInitiator, actualPropertyStatus: props?.selectedData?.actualPropertyStatus, buyingStatus: props?.selectedData?.buyingStatus, offerStatus: props?.selectedData?.status });

  const signalRequired = props?.signatureSequence?.buyer || props.signatureSequence?.buyerAgent
  return (
    <div
      style={{
        flex: 1,
        justifyContent: "space-between",
        textAlign: "center",
        marginBottom: 0,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: signalRequired ? 23 : 35,
          // border:"2px solid red"
        }}
      >
        <>
          <SignalIcon source="BUYER" documentStatus={props} />
          <div
            style={{
              width: "100%",
              borderStyle: props.RTDInitiator === "BUYER" ? "solid" : "none",
              height: 0,
              marginTop: 1,
              position: "relative",
              borderColor: props.RTDInitiator === "BUYER" ? (props?.invalidated ? "darkgray" : "#ffce51") : "white",
            }}
          >
            {props.RTDInitiator === "BUYER" &&
              props.client === "BUYER" &&
              (!props.checked.showSeller ? (
                <></>
              ) : (
                <div style={{ position: "relative", marginTop: "-10px" }}>
                  <Tooltip title="Document sent to Seller Agent">
                    <CheckCircleFilled style={{ fontSize: "18px", backgroundColor: "white", borderRadius: "100px", color: props.invalidated ? "darkgray" : "green" }} />
                  </Tooltip>
                </div>
              ))}

            {/* DELETE A FORM */}
            {props?.client === "BUYER" && props?.RTDInitiator === "BUYER" && ((createOfferContractTypes.includes(props?.contractType) && props?.selectedData?.status !== "ACCEPTED") || !createOfferContractTypes.includes(props?.contractType)) ? (
              <div style={{ position: "absolute", bottom: 0, right: 0, cursor: "pointer" }} {...(checkForDisable?.disabled ? { onClick: disabledTransactionText } : { onClick: () => confirmDeleteForm(props) })}>
                <Tooltip title="Delete Form">
                  <DeleteTwoTone />
                </Tooltip>
              </div>
            ) : null}
          </div>
        </>
        {/* )} */}
      </div>

      <div style={{display: "flex", justifyContent: "end"}}>
        {timestamp && <div style={{display: "inline-block"}}>
          <Tooltip title={toolTipMessage} placement="top">
            <p
              style={{
                textAlign: "end",
                marginRight: 10,
                marginTop: props?.RTDInitiator === "SELLER" ? "-30px" : "-2px",
                fontSize: "13px",
                fontWeight: "bolder",
              }}
            >
              {dateNew}
            </p>
            <p
              style={{
                textAlign: "right",
                marginRight: 20,
                marginTop: -18,
                fontSize: "13px",
                fontWeight: "bolder",
                marginBottom: 0,
              }}
            >
              {time}
            </p>
          </Tooltip>
        </div>}
      </div>
    </div>
  );
};

const DetailComp = ({ documentName, seller, role, selectedData, ispdfClone, section, currentAgentDetails }) => {
  const delegate = section === "DELEGATE";
  seller = { ...selectedData, ...seller };
  delete seller.selectedData;
  const location = useLocation();
  const dispatch = useDispatch();

  const urlShortningForDraft = () => {
    if (seller?.ispdfClone || seller?.isPdf) {
      let fileUrl = seller?.resureSignedPdfLink ? seller?.resureSignedPdfLink : seller?.pdfLink;
      window.open(fileUrl, "_blank").focus();
    } else {
      let buildData = {
        builtForId: currentAgentDetails?._id,
        key: currentAgentDetails?._id,
        builtForSection: "DOCUMENT",
        signable: true,
        openFor: role === "SELLER" ? "SELLER_AGENT" : "BUYER_AGENT",
        contractType: seller?.contractType,
        offerId: selectedData?.offerId || null,
        documentId: seller?.documentId,
        contractId: seller?.contractId,
        token: localStorage.getItem("userAuth"),
        ...(delegate ? { delegateOwnerId: currentAgentDetails?._id } : {}),
      };
      dispatch(transactionLaneDataHandle.generateUrlIdAndRedirect({ buildData, currentPath: location?.pathname, delegateOwnerId: delegate && currentAgentDetails?._id }));
    }
  };

  return (
    <>
      <div
        style={{
          width: "310px",
          borderStyle: "solid",
          borderWidth: 2,
          borderRadius: 8,
          borderColor: seller?.invalidated ? "darkgray" : seller?.RTDInitiator === "SELLER" ? "#3376a8" : "#ffce51",
          textAlign: "center",
          fontSize: "18px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "4px",
          marginBottom: "4px",
          color: "#8e8e8e",
          fontWeight: 200,
          minHeight: "65px",
        }}
      >
        <p
          style={{
            marginLeft: "2px",
            cursor: "pointer",
            paddingTop: "16px",
          }}
        ></p>

        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            onClick={() => {
              urlShortningForDraft();
            }}
            className={styles.documentName}
            style={{
              textAlign: "center",
              margin: "0%",
              cursor: "pointer",
              padding: 0,
              fontWeight: 500,
              whiteSpace: "normal", 
            
            }}
          >
            <span style={{ ...(seller?.invalidated ? { textDecoration: "line-through" } : {}) }}>{documentName}</span>
            {ispdfClone ? createOfferContractTypes.includes(seller?.contractType) ? <FilePdfOutlined /> : "" : seller?.isPdf && <FilePdfOutlined />}
          </p>
        </div>

        <p
          style={{
            marginRight: "2px",
            paddingTop: "16px",
            cursor: "pointer",
            textAlign: "center",
          }}
        ></p>
      </div>
    </>
  );
};


function RtdRow(props) {
  // console.log(props,"props")

  // not show tr in rtd
  const { contractType, section } = props;
  const delegate = section === "DELEGATE";
  const [nestedModalOpen, setNestedModalOpen] = useState({
    isEmailModalVisible: false,
    isAgent: false,
    sendMultipleDoc: false,
  });
  const [page, setPage] = useState([1]);
  const { updateDocumentLoading } = useSelector(
    (state) => state.rtdTransactionLane
  );

  const checked = {
    showBuyer: props.showBuyer,
    showSeller: props.showSeller,
  };

  return (
    <>
      {updateDocumentLoading && (
        <div style={{ position: "fixed", left: "43%", top: "50%" }}>
          <Loading />
        </div>
      )}

      {contractType !== "TR" && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <SignableAndReadOnly
            nestedModalOpen={nestedModalOpen}
            setNestedModalOpen={setNestedModalOpen}
            source={props?.client === "SELLER" ? "SELLING_RTD" : "BUYING_RTD"}
            selectedData={props?.selectedData}
            documentId={props?.documentId}
            ispdfClone={props?.ispdfClone}
            docSelected={true}
            section={section}
            role={props?.client}
            delegateOwnerId={delegate && props?.currentAgentDetails?._id}
            RTDInitiator={props?.RTDInitiator}
            client={props?.client}
            contractType={props?.contractType}
            reloadSection={"RTD"}
            allBuyerAgentData={props?.allBuyerAgentData || []}
            allSellerAgentData={props?.allSellerAgentData || []}
            page={page}
            setPage={setPage}
          />
          <SellerComp {...props} checked={checked} section={section} />

          <DetailComp section={section} seller={props} documentName={props.contractName} role={props.client} selectedData={props.selectedData} ispdfClone={props.ispdfClone}  client={props?.client}
              currentAgentDetails={props?.currentAgentDetails}
            allBuyerAgentData={props?.allBuyerAgentData || []}
            allSellerAgentData={props?.allSellerAgentData || []} page={page}
            setPage={setPage} />

          <BuyerComp section={section} {...props} checked={checked} />
        </div>
      )}
    </>
  );
}

export default RtdRow;
