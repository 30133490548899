import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Select,
  DatePicker,
  Checkbox,
  Col,
  Modal,
  List,
  Spin,
  Empty,
  Typography,
} from "antd";
import {
  UserOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  LockOutlined,
  SearchOutlined
} from "@ant-design/icons";
import styles from "../styles/User.module.css";
// import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector,useDispatch } from "react-redux";
import moment from "moment";
import {userSignUpAction} from "../state/actions";
import { Link, useLocation } from "react-router-dom";
const { Text } = Typography;

const marketingURL = process.env.REACT_APP_MARKETING_URL;
const { Option } = Select;

function validateMessages(label) {
  return {
    required: `${label} is required!`,
    types: {
      email: `${label} is not a valid email!`,
      number: `${label} is not a valid number!`,
    },
  };
}

//Function to check if user is at least 16 years old
function disabledDate(current) {
  return current && current > moment().subtract(16, "years");
}

function FirstPage({ onFinish, firstPageData }) {
  const [checkedValue, setCheckedValue] = useState(false);
  const [email, setEmail] = useState("");
  const [isModalVisible,setIsModalVisible] = useState(false);
  const [selectedAssociation,setSelectedAssociation] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const via = searchParams.get('via');

  const { userSignupLoading,associationDataLoading,associationData } = useSelector((state) => state.userSignup);
  const onChange = (e) => {
    setCheckedValue(!checkedValue);
  };
  // Have to select T & C
  const validateTerms = (_, value) => {
    if (!checkedValue) {
      return Promise.reject(
        new Error("Please select Terms & Conditions and Privacy Policy!")
      );
    }
    return Promise.resolve();
  };

  const handleCancel = () => {
    setSelectedAssociation({});
    setIsModalVisible(false);
  }

  const handleAssociation = () => {
    dispatch(userSignUpAction.getAssociationData())
    setSelectedAssociation({});
    // setIsModalVisible(true);
  }

  useEffect(() => {
    if(via?.length > 0){
      localStorage.setItem("via",via);
    }
  }, [via]);

  useEffect(() => {
    if(selectedAssociation?.label?.length > 0){
      form.setFieldsValue({association : selectedAssociation?.key})
    }else{
      form.setFieldsValue({association : undefined})
    }
  },[selectedAssociation])

  // useEffect(() => {
  //   dispatch(userSignUpAction.getAssociationData())
  // },[])

  return (
    <>
    <Form
      layout="vertical"
      name="1normal_login_page1"
      form={form}
      onFinish={onFinish}
      validateMessages={validateMessages}
      scrollToFirstError
    >
      <Row gutter={16}>
        <Col span={24} xs={24} sm={12} md={12} lg={12}>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: "Please Enter First Name!" }]}
          >
            <Input placeholder="Enter First Name" allowClear />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} sm={12} md={12} lg={12}>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: "Please Enter Last Name!" }]}
          >
            <Input placeholder="Enter Last Name" allowClear />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Email"
        name="email"
        hasFeedback
        rules={[
          {
            type: "email",
            message: "Not a valid Email!",
          },
          {
            required: true,
            message: "Please Enter Email!",
          },
          { whitespace: true },
        ]}
      >
        <Input
          prefix={
            <UserOutlined
              className="site-form-item-icon"
              style={{ marginRight: "15px" }}
            />
          }
          placeholder="Enter Email"
          allowClear
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Item>

      <Row gutter={16}>
        <Col span={24} xs={24} sm={12} md={12} lg={12}>
          <Form.Item
            name="password"
            label="Password"
            hasFeedback
            rules={[
              {
                required: true,
                message: "",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    getFieldValue("password") !== email &&
                    value?.length > 5 &&
                    value?.includes(" ") === false
                  ) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error("Required minimum 6 characters password!")
                    );
                  }
                },
              }),
            ]}
          >
            <Input.Password
              prefix={
                <LockOutlined
                  className="site-form-item-icon"
                  style={{ marginRight: "15px" }}
                />
              }
              maxLength={20}
              type="password"
              placeholder="Enter Password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={24} xs={24} sm={12} md={12} lg={12}>
          <Form.Item
            name="confirmpassword"
            label="Confirm Password"
            rules={[
              {
                required: true,
                message: "",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error("The password that you entered does not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={
                <LockOutlined
                  className="site-form-item-icon"
                  style={{ marginRight: "15px" }}
                />
              }
              type="password"
              maxLength={20}
              placeholder="Enter Password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} xs={24} sm={12} md={12} lg={12}>
          <Form.Item
            name="phoneNumber"
            label="Mobile Phone"
            hasFeedback
            rules={[
              { required: true, message: "" },
              { whitespace: true },
              // ({ getFieldValue }) => ({
              //   validator(_, value) {
              //     if (value?.length === 10) {
              //       return Promise.resolve();
              //     }
              //     return Promise.reject(
              //       new Error("Please Enter 10 digit Phone Number!")
              //     );
              //   },
              // }),
            ]}
          >
            <PhoneInput
              hasFeedback
              inputStyle={{
                width: "100%",
              }}
              inputClass="phoneInput"
              country={"us"}
              onlyCountries={["us"]}
              maxLength="10"
              international={false}
              disableCountryCode={true}
              placeholder="Enter Phone Number"
            />
          </Form.Item>
        </Col>
        {/* <Col span={12}>
          <Form.Item
            name="birthDate"
            label="Birth Date"
            rules={[{ required: true, message: "Please Enter Birth Date!" }]}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder=" Select Birthday"
              format="MM-DD-YYYY"
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col> */}
        {/* <Col span={12}>
          <Form.Item
            name="association"
            label="Association"
            rules={[
              { required: true, message: "Please select Association" },
            ]}
          >
            <div style={{display:"flex",gap:"10px"}} onClick={handleAssociation}>
            <Input
              style={{ width: "100%",height:"35px" }}
              placeholder="Select Association"
              value={selectedAssociation?.associationLabel}
              readOnly={true}
            />
            <SearchOutlined style={{fontSize:"18px",marginTop:"7px",cursor:"pointer"}}/>
            </div>
          </Form.Item>
        </Col> */}
        <Col span={24} xs={24} sm={12} md={12} lg={12}>
          <Form.Item
            name="association"
            label="Association"
            rules={[
              { required: true, message: "Please select Association" },
            ]}
          >
            {associationData && associationData?.length > 0 ? (
              <Select
                style={{ width: "100%", height: "30px" }}
                placeholder="Select Association"
                onFocus={handleAssociation}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                  {associationData?.map((element, index) => (
                    <Option key={index} value={element.key}>
                      {element.label}
                    </Option>
                  ))
                }
              </Select>
            ) : (
              <Select
                style={{ width: "100%", height: "30px" }}
                placeholder="Select Association"
                onFocus={handleAssociation}
              >
                {associationDataLoading ? (
                  <div style={{ minHeight: "20vh" }}>
                    <Spin size="medium" style={{ margin: "45% 0 0 40%" }} />
                  </div>
                ) : (
                  associationData?.map((element, index) => (
                    <Option key={index} value={element.key}>
                      {element.label}
                    </Option>
                  ))
                )}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        style={{ marginBottom: "35px" }}
        name="termsAndConditions"
        label={
          <div
            style={{
              position: "relative",
              display: "flex",
            }}
          >
            <span
              style={{
                position: "absolute",
                top: 0,
                color: "red",
                fontSize: 18,
              }}
            >
              *
            </span>

            <span style={{ marginLeft: "10px" }}>Terms & Conditions</span>
          </div>
        }
        rules={[
          {
            validator: validateTerms,
          },
        ]}
      >
        <Checkbox onChange={onChange} defaultChecked={false}>
          I agree to the{" "}
          <a
            target="_blank"
            href={`${marketingURL}/termofservice`}
            rel="noreferrer"
          >
            Terms & Conditions
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            href={`${marketingURL}/privacypolicy`}
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          . By providing a phone number/email address to us, you consent to receive SMS and email messages related to our product(s) or to provide you with service and support.
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          size="large"
          className={styles.btnPrimary}
          loading={userSignupLoading}
          disabled={checkedValue ? false : true}
        >
          Signup
        </Button>
        <Row justify="center">
          <Text type="secondary">
            Already have an account ?
            <Link to="/login">
              {" "}
              Sign in here
            </Link>
          </Text>
        </Row>
      </Form.Item>
    </Form>

    {/* Modal to open association */}
    {/* <Modal open={isModalVisible} footer={false} onCancel={handleCancel} title={<p style={{textAlign:"center",color:"gray",fontSize:"20px",fontWeight:"bold"}}>Select Association</p>}>
    {associationDataLoading ? 
      <div style={{minHeight:"60vh"}}>
        <Spin size="large" style={{margin:"40% 0 0 45%"}}/>
      </div> : 
      <div style={{maxHeight:"60vh",overflowY:"auto"}}>
        {associationData && associationData?.length > 0 ? 
        <List
        itemLayout="horizontal"
        dataSource={associationData}
        renderItem={(item, idx) => (
          <List.Item
                style={{
                  cursor: "pointer",
                }}
                key={item.associationKey}
                onMouseEnter={(e) => {
                  e.currentTarget.style.background = "#92b3d1";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.background = "white";
                }}
                onClick={() => {
                  setSelectedAssociation(item);
                  setIsModalVisible(false);
                }}
              >
                <List.Item.Meta
                  title={<span style={{padding:"0 10px",color:"black",fontWeight:"normal"}}>{item?.associationLabel}</span>}
                />
          </List.Item>
            )}
      />
        : <Empty/>}
      </div>}
    </Modal> */}
    </>
  );
}
export { FirstPage };
