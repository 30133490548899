import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Form, Input, Button, Card, message, Col, Row, Layout } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Logo from "../../Assets/Icons/newLogo.png";
import styles from "../styles/TestingRoute.module.css";
import { bugReportHandle } from "../state/actions";
import { Content } from "antd/lib/layout/layout";

/* eslint-enable no-template-curly-in-string */
function validateMessages(label) {
  return {
    required: `${label} is required!`,
    types: {
      email: `${label} is not a valid email!`,
      number: `${label} is not a valid number!`,
    },
  };
}
/* eslint-enable no-template-curly-in-string */
function BugsLogin() {
  const dispatch = useDispatch();
  const { logInError } = useSelector((state) => state.bugReport);

  let onFinish = (data) => {
    dispatch(bugReportHandle.loginModule(data));
  };

  let loggedInUser = localStorage.getItem("userAuth");
  if (loggedInUser !== null) {
    return <Redirect to={"/bugsReport"} />;
  }
  if (logInError !== null) {
    message.error("Login Failed");
  }

  return (
    <Layout>
    <Row style={{ height: "100%" }}>
      <Col
        xs={0}
        sm={0}
        md={0}
        lg={12}
        xl={12}
        xxl={12}
        style={{
          height: "100%",
          position: "sticky",
          top: "0",
          left: "0",
        }}
      >
        <Content>
          <div className={styles.alignBackground}></div>
          <img
            className={styles.alignForhead}
            src={Logo}
            alt="userLoginLogo"
          />
        </Content>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <Content>
        <div >
      {/* <Card className={styles.custForm}> */}
        <div style={{ width: "80%",margin:"100px auto" }}>
          <div>
            <h1 style={{ textAlign: "center" }} className={styles.custFormH1}>
              Sign In
            </h1>
            <h2 className={styles.custFormH2}>Welcome to RE-Sure</h2>
          </div>
          <Form
            name="normal_login"
            layout="vertical"
            className={styles.loginForm}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: "email",
                  message: "Not a valid Email!",
                },
                {
                  required: true,
                  message: "Please Enter Email!",
                },
              ]}
            >
              <Input
                allowClear
                prefix={
                  <UserOutlined
                    className="site-form-item-icon"
                    style={{ marginRight: "15px" }}
                  />
                }
                placeholder="Enter Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value?.length > 5 && value?.includes(" ") === false) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error("Required minimum 6 characters password!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                allowClear
                prefix={
                  <LockOutlined
                    className="site-form-item-icon"
                    style={{ marginRight: "15px" }}
                  />
                }
                type="password"
                placeholder="Enter Password"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.btnPrimary}
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </div>
      {/* </Card> */}
       </div>
        </Content>
      </Col>
    </Row>
  </Layout>
  );
}
export { BugsLogin };
