import { documentConstants } from "../action-types";

const initialState = {
  getCategoryData: null,
  getCategoryLoading: null,
  getCategoryError: null,
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case documentConstants.DOCUMENT_CATEGORY_SUCCESS:
      return {
        ...state,
        getCategoryData: action.getCategoryData,
        getCategoryLoading: action.getCategoryLoading,
        personError: null,
      };
    case documentConstants.DOCUMENT_CATEGORY_FAILURE:
      return {
        ...state,
        personData: [],
        getCategoryLoading: action.getCategoryLoading,
        getCategoryError: action.getCategoryError,
      };

    default:
      return state;
  }
};
export { categoryReducer };
