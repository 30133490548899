import { useSelector } from "react-redux";
import { Loading } from "../../Common/components/Loading";
import { useMediaQuery } from "react-responsive";

const CreateERTS = ({ setisModalOpen, next, importModalStep }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const { ERTS_Loading } = useSelector((state) => state.ERTSReducer);

  const handleUploadClick = () => {
    setisModalOpen((prevState) => ({
      ...prevState,
      docsModal: true,
      importModalStep: 0,
    }));
  };

  return ERTS_Loading ? (
    <div style={{ marginTop: "15%", height: isMobile ? "300px" : "500px" }}>
      <Loading />
    </div>
  ) : (
    <div
      style={
        isMobile && importModalStep === 1
          ? { marginTop: "-40px" }
          : { height: "100%", margin: "auto" }
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div
          onClick={() => {
            next();
          }}
        >
          <span
            style={{ color: "#2C59A9", fontSize: "25px", cursor: "pointer" }}
          >
            Create
          </span>
        </div>
        <div>
          <span style={{ color: "grey", fontSize: "15px" }}>
            ------ OR ------
          </span>
        </div>
        <div onClick={handleUploadClick}>
          <span
            style={{
              color: "#2C59A9",
              fontSize: "22px",
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>Upload</div>
            <div style={{ fontSize: "18px", textAlign: "center" }}>
              (Existing PDF of Exclusive Right To Sell Contract)
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export { CreateERTS };

