import { clientConstants } from "../action-types";

const initialState = {
  updateAddressDetails: null,
  updateAddressLoading: false,
  updateAddressError: null,
};

const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    // Update Address data
    case clientConstants.UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        updateAddressDetails: action.updateAddressDetails,
        updateAddressLoading: action.updateAddressLoading,
      };
    case clientConstants.UPDATE_ADDRESS_FAILURE:
      return {
        ...state,
        updateAddressError: action.updateAddressError,
        updateAddressLoading: action.updateAddressLoading,
      };
    case clientConstants.CLIENT_ADDRESS_RESET:
      return {
        ...state,
        updateAddressDetails: null,
        updateAddressLoading: true,
        updateAddressError: null,
      };
    default:
      return state;
  }
};
export { addressReducer };
