import { Avatar, Col, Form, Select } from "antd";
import React, { useEffect} from "react";
import { accountHandle } from "../../Account/state/actions";
import { useDispatch, useSelector } from "react-redux";
import { generateFullNameFromObj} from "../../Common/utils/extraFunctions";
import { getFilteredDelegateData } from "../utils/helper";

const DelegateSeller = ({
  onChange,
  selectedSellerAgent
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  let delegate = true;
  const { getDelegateData, getDelegateDataLoading } = useSelector((state) => state.account);

  const handleData = (id) => {
    const filteredData = getFilteredDelegateData(id, getDelegateData);
    onChange(filteredData, "delegateSeller");
  };
  

  useEffect(() => {
    dispatch(accountHandle.getDelegateData("DELEGATES_TO", delegate));
    return () => {
        dispatch(accountHandle.resetDelegateData());
    };

}, []);

  return (
    <Col
      style={{ marginBottom: "-20px", marginTop: "-10px" }}
      xs={24}
      sm={24}
      md={24}
      lg={24}
      xl={24}
    >
      <Form.Item
        name="seller"
        label={
          <div
            style={{ fontSize: "16px", fontWeight: "500", color: "#8e959a" }}
          >
            Select Seller Agent
          </div>
        }
        rules={[{ required: true, message: "Please select seller agent!" }]}
      >
        <Select
        id="selectBox"
          allowClear
          style={{ width: "100%", }}
          loading={getDelegateDataLoading}
          disabled={getDelegateDataLoading}
          placeholder="Select Seller Agent"
          onChange={(value) => handleData(value)}
          defaultValue={selectedSellerAgent?.id}
        >
          {getDelegateData?.delegateTo?.map((user) => (
            <Option
            key={user?.id}
            value={user?._id}
            >
              <div style={{ display: "flex", alignItems: "center",textAlign:"center" }}>
               {user?.profileImg?.original ? <img
                  src={user?.profileImg?.original}
                  alt="img"
                  loading="lazy"
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: "50%",
                    marginRight: 10,
                  }}
                /> :
                  <Avatar size={"small"} style={{ background: "#085191", marginRight: "5px", }}>
                    
                <div>{generateFullNameFromObj(user)}</div>
              </Avatar>}
                <span>{user?.fullName}</span>
              </div>
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
};

export default DelegateSeller;
