import { clientConstants } from "../action-types";

const initialState = {
  globalClientLoading: false,

  postImageData: [],
  postImageLoading: false,
  postImageError: null,

  postProfileImageData: null,
  postProfileImageLoading: false,
  postProfileImageError: null,
};

const imageReducer = (state = initialState, action) => {
  switch (action.type) {
    // POST image
    case clientConstants.POST_IMAGE_SUCCESS: {
      return {
        ...state,
        postImageData: action.postImageData,
        postImageLoading: action.postImageLoading,
      };
    }
    case clientConstants.POST_IMAGE_FAILURE:
      return {
        ...state,
        postImageError: action.postImageError,
        postImageLoading: action.postImageLoading,
      };
    //   Upload Image
    case clientConstants.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        uploadImageData: action.uploadImageData,
        uploadImageLoading: action.uploadImageLoading,
        uploadImageError: null,
      };
    case clientConstants.UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        uploadImageData: [],
        uploadImageLoading: action.uploadImageLoading,
        uploadImageError: action.uploadImageError,
      };
    // update && post PROFILE IMAGE
    case clientConstants.POST_PROFILE_IMAGE_LOADING:
      return {
        ...state,
        globalClientLoading: true,
        personDetailsError: false,
      };
    case clientConstants.POST_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        globalClientLoading: false,
        personDetailsError: false,
      };
    case clientConstants.POST_PROFILE_IMAGE_FAILURE:
      return {
        ...state,
        postProfileImageError: action.postProfileImageError,
        postProfileImageLoading: action.postProfileImageLoading,
      };
    //   RESET
    case clientConstants.PROFILE_IMAGE_RESET:
      return {
        ...state,
        postProfileImageData: null,
        postProfileImageLoading: true,
        postProfileImageError: null,
      };

    default:
      return state;
  }
};
export { imageReducer };
