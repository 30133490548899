import { templateConstants } from "./action-types";

const initialState = {
  templateData: [],
  templateLoading: false,
  templateError: null,
  deleteTemplate: [],
  deleteTemplateLoading: false,
  deleteTemplateError: null,
  templateEditData: [],
  templateEditLoading: true,
  templateEditError: null,
  templateUrl: "",
};

const template = (state = initialState, action) => {
  switch (action.type) {
    case templateConstants.TEMPLATE_DATA_LOADING:
      return {
        ...state,
        templateLoading: true,
        templateError: false,
      };
    case templateConstants.TEMPLATE_DATA_SUCCESS:
      return {
        ...state,
        templateData: action.templateData,
        templateLoading: false,
        templateError: false,
      };
    case templateConstants.TEMPLATE_DATA_FAILURE:
      return {
        ...state,
        templateError: action.templateError,
        templateLoading: false,
      };
    case templateConstants.DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        deleteTemplate: action.deleteTemplate,
        deleteTemplateLoading: false,
        deleteTemplateError: false,
      };
    case templateConstants.DELETE_TEMPLATE_FAILURE:
      return {
        ...state,
        deleteTemplateError: true,
        deleteTemplateLoading: false,
      };
    case templateConstants.DELETE_TEMPLATE_LOADING:
      return {
        ...state,
        deleteTemplateLoading: true,
        deleteTemplateError: false,
      };

    case templateConstants.TEMPLATE_EDITDATA_SUCCESS:
      return {
        ...state,
        templateEditData: action.templateData,
        // templateEditLoading: action.templateLoading,
      };
    case templateConstants.TEMPLATE_EDITDATA_FAILURE:
      return {
        ...state,
        templateEditError: action.templateError,
        // templateEditLoading: action.templateLoading,
      };
    case templateConstants.TEMPLATE_URL_COPIED:
      return {
        ...state,
        templateUrl: action.payload,
        // templateEditLoading: action.templateLoading,
      };
    default:
      return state;
  }
};
export { template };
