import React, { useEffect, useState } from "react";
import { Modal, Form, Select, Button, Typography, Tooltip, Tag, message } from "antd";
import { AuditOutlined, PlusCircleFilled } from "@ant-design/icons";
import { clientAction } from "../state/actions";
import { useDispatch } from "react-redux";
import "../styles/RoleHashTag.css"
import { capitalizeFirst } from "../../Common/utils/extraFunctions";

const RoleHashtags = ({ personDetails, setModelDrawer, selectedClient }) => {
    const Text = Typography;
    const [visible, setVisible] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const dispatch = useDispatch();
    const OPTIONS = ["MORTGAGE", "TITLE REP", "CUSTOMER", "BUYER", "SELLER", "STAGER", "PHOTOGRAPHER", "PROPERTY INSPECTOR"];
    const filteredOptions = OPTIONS?.filter((o) => !selectedItems?.includes(o));
    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        if (selectedItems?.length === 0) {
            message?.error("Please Select Hashtag!")
            return;
        }
        dispatch(clientAction.updateRoleHashtags(selectedItems, personDetails?._id));
        setVisible(false);
    };
    const handleCancel = () => {
        setVisible(false);
    };

    useEffect(() => {
        if (personDetails?.roleHashTags?.length !== 0) {
            setSelectedItems(personDetails?.roleHashTags)
        }
    }, [personDetails?.roleHashTags])

    const handleChange = (value) => {
        setSelectedItems(value)
    }


    return (
        <>
            <Text style={{ display: "flex", alignItems: "center" }}>
                <div className="roleContainer">
                    <div
                        className="roleIcon"
                    >
                        <Tooltip title={"Hashtag"} placement="left" >
                            <AuditOutlined className="mainIcon" />
                        </Tooltip>
                    </div>
                    <div
                        style={{
                            paddingLeft: "22px",
                            fontSize: "18px",
                            textTransform: "capitalize",
                        }} >
                        {(personDetails && personDetails?.roleHashTags && personDetails?.roleHashTags?.length === 0 ||
                            !personDetails?.roleHashTags) ? (
                            "Add Hashtag"
                        ) : (
                            <span>
                                {personDetails && personDetails?.roleHashTags && personDetails?.roleHashTags?.map((tag) => {
                                    return (
                                        <Tag style={{ background: "rgb(8, 81, 145)", color: "white" }}>
                                            {tag}
                                        </Tag>
                                    );
                                })}
                            </span>
                        )}
                    </div>
                    <PlusCircleFilled style={{ color: "rgb(8, 81, 145)", fontSize: "1.5rem", marginRight: "0.9rem" }} className="roleBtn" onClick={showModal} />
                    <Modal
                        title={
                            <span style={{ color: 'rgb(61, 124, 183)', textDecoration: 'underline' }}>
                                Hashtag Details
                            </span>
                        }
                        visible={visible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        className="custom-modal"
                        width={580}
                    >
                        <Form>
                            <Form.Item
                                label="Hashtag"
                                name="roleHashTags"
                                rules={[{ required: true, message: "Please Select Hashtag!" }]}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select Hashtag"
                                    value={selectedItems}
                                    defaultValue={selectedItems}
                                    onChange={handleChange}
                                    style={{ width: "70%" }}
                                    options={filteredOptions?.map((item) => {
                                        return {
                                            value: item,
                                            label: capitalizeFirst(item),
                                        }
                                    })}
                                />
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            </Text>

        </>
    );
};

export { RoleHashtags };
