import { breadcrumConstants } from "./action-types";
/**
 * All Actions for breadcrum
 */

/**
 * To updatebreadcrum action
 * @param {string}
 */

const updateBreadCrum = (pathname) => {
  const routes = [];
  pathname.split("/").map((ele) => {
    return routes.push({
      path: `/${ele}`,
      breadcrumbName: `${(ele && ele[0].toUpperCase() + ele.slice(1)) || ""}`,
    });
  });
  return {
    type: breadcrumConstants.UPDATE_NAVBAR_TITLE,
    breadcrum_title: routes,
  };
};

export const breadcrumHandle = {
  updateBreadCrum,
};
