import { clientConstants } from "../action-types";

const initialState = {
  calaendarData: [],
  calaendarLoading: false,
  calaendarError: null,
};

const calenderReducer = (state = initialState, action) => {
  switch (action.type) {
    // calendar url shortning
    case clientConstants.URL_SHORTNING_CALENDAR_SUCCESS:
      return {
        ...state,
        calendarData: action.calendarData,
        calendarLoading: true,
        calendarError: null,
      };
    case clientConstants.URL_SHORTNING_CALENDAR_FAILURE:
      return {
        ...state,
        calendarData: [],
        calendarLoading: false,
        calendarError: null,
      };
    default:
      return state;
  }
};
export { calenderReducer };
