import React, { useEffect, useState,useRef } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button } from "antd";
import { clientAction } from "../state/actions";
import { Loading } from "../../Common/components/Loading";

function AddressDetails({ data, setModelDrawer, selectedClient }) {
  const dispatch = useDispatch();
  const [dataLoader, h] = useState(true);
  const inputRef = useRef()
  const [formData, setFormData] = useState({
    streetNumber: data?.streetNumber || "",
    streetName: data?.streetName || "",
    city: data?.city || "",
    state: data?.state || "CO",
    zipCode: data?.zipCode || "",
    unit: data?.unit || ""
  });
  console.log("formData",formData)
  useEffect(() => {
    setTimeout(() => {
      h(false);
    }, 100);
    return () => {};
  }, []);
  // const { updateAddressDetails } = useSelector((state) => state.client);

  // if (updateAddressDetails !== null) {
  //   dispatch(clientAction.reSetAddress());
  //   setModelDrawer({ status: false, component: "", title: "" });
  // }

  const onFinish = (values) => {
    dispatch(clientAction.updateAddress(values, data, selectedClient));
    setModelDrawer({ status: false, component: "", title: "" });
    setFormData({
      streetNumber: "",
      streetName: "",
      city: "",
      state: "CO",
      zipCode: "",
      unit: ""
    });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <>
      {dataLoader ? (
        <Loading />
      ) : (
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 18 }}
          initialValues={{
            streetNumber: data.streetNumber,
            streetName: data.streetName,
            unit: data.unit ? data.unit : "",
            city: data.city,
            state: data.state || "CO",
            zipCode: data.zipCode,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Street Number"
            name="streetNumber"
            rules={[{ required: true, message: "Please Enter Street Number!" }]}
          >
            <Input
              ref={inputRef}
              placeholder="Enter Street Number"
              allowClear
              autoFocus
              name="streetNumber"
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item
            label="Street Name"
            name="streetName"
            rules={[{ required: true, message: "Please Enter Street Name!" }]}
          >
            <Input
              placeholder="Enter Street Name"
              allowClear
              name="streetName"
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item
            label="Unit"
            style={{ marginLeft: "15px" }}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            name="unit"
          >
            <Input
              placeholder="Enter Unit"
              style={{ marginLeft: "10px", width: "97%" }}
              allowClear
            />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: "Please Enter City Name!" }]}
          >
            <Input
              placeholder="Enter City"
              allowClear
              name="city"
              onChange={handleInputChange}
            />
          </Form.Item>

          <Form.Item
            label="State"
            name="state"
            rules={[{ required: true, message: "Please Enter State Name!" }]}
          >
            <Input
              defaultValue='CO'
              placeholder="Enter State"
              allowClear
              name="state"
              onChange={handleInputChange}
            />
          </Form.Item>

          <Form.Item
            label="Zip Code"
            name="zipCode"
            hasFeedback
            rules={[
              { required: true, message: "Please Enter the Zip Code!" },
              { whitespace: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value?.length > 4 && value?.length < 7) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error("Please Enter valid Zip Code!")
                  );
                },
              }),
            ]}
          >
            <Input
              type="number"
              maxLength={"6"}
              placeholder="Enter Zip Code"
              allowClear
              name="zipCode"
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                !formData.streetNumber ||
                !formData.streetName ||
                !formData.city ||
                !formData.state ||
                !formData.zipCode
              }
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
}
export { AddressDetails };
