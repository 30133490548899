import { clientConstants } from "../action-types";

const initialState = {
  isDraftOpen: false,
  isPastOpen: false,
  isCurrentOpen: false,
};

const draftReducer = (state = initialState, action) => {
  switch (action.type) {
    case clientConstants.DRAFT_TOGGLE:
      return {
        ...state,
        isDraftOpen: !state.isDraftOpen,
      };
    case clientConstants.CURRENT_TOGGLE:
      return {
        ...state,
        isCurrentOpen: !state.isCurrentOpen,
      };
    case clientConstants.PAST_TOGGLE:
      return {
        ...state,
        isPastOpen: !state.isPastOpen,
      };

    default:
      return state;
  }
};
export { draftReducer };
