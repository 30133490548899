import React from "react";
import styled from "styled-components";
import { Col, Row } from "antd";
import {
  FolderFilled,
  FolderOpenOutlined,
  FileZipOutlined,
  PlusCircleOutlined,
  CaretRightOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { clientAction } from "../state/actions";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";

const StyledTree = styled.div`
  line-height: 1.5;
`;
const StyledFile = styled.div`
  padding-left: 20px;
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
  }
`;
const StyledFolder = styled.div`
  padding-left: 20px;

  .folder--label {
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }
`;
const Collapsible = styled.div`
  height: ${(p) => (p.isOpen ? "auto" : "0")};
  overflow: hidden;
`;

const File = ({
  name,
  newRedirectClientToOffer,
  id,
  items,
  // handleMenuChange,
}) => {
  let dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const {  userId } = useSelector((state) => state.account);
  const urlShortningForDraft = (item) => {
    let buildData = {
      builtForId: userId,
      key: userId,
      builtForSection: "DOCUMENT",
      signable: true,
      documentId: item?.CBSRId,
      contractId: item?.contractId,
      token: localStorage.getItem("userAuth"),
      openFor: "BUYER_AGENT",
    };
    if (name !== "New Offer") {
      dispatch(clientAction.draftDocumentUlShortning(buildData,  location?.pathname, id ));
    }
  };

  return (
    <StyledFile>
      <span
        onClick={() => {
          if (name === "New Offer" && id) {
            localStorage.setItem("redirectFromDraft", "true");
          } else {
            urlShortningForDraft(items);
          }
        }}
        
        style={{
          fontSize: "20px",
          fontWeight: 500,
          color: "#8C8C8C",
          cursor: "pointer",
        }}
      >
        <FileZipOutlined style={{ fontSize: "22px" }} />
        {name}
        {name === "New Offer" ? (
          <PlusCircleOutlined   />
        ) : (
          ""
        )}
      </span>
    </StyledFile>
  );
};

const Folder = ({ name, children, documents }) => {
  const dispatch = useDispatch();
  const { isDraftOpen } = useSelector(
    (state) => state.client.draftReducer,
    shallowEqual
  );
  const handleToggle = (e) => {
    e.preventDefault();
    // setIsDraftOpen(!isDraftOpen);
    dispatch(clientAction.handleDraftOpen());
  };
  return (
    <StyledFolder>
      <div className="folder--label" onClick={handleToggle}>
        {isDraftOpen ? (
          <>
            <CaretDownOutlined style={{ fontSize: "22px", color: "#8C8C8C" }} />{" "}
            <FolderOpenOutlined style={{ fontSize: "24px" }} />
          </>
        ) : (
          <>
            <CaretRightOutlined style={{ fontSize: "22px", color: "#8C8C8C" }} />
            <FolderFilled style={{ fontSize: "24px", color: "#6B98C7" }} />
          </>
        )}
        <span
          style={{ fontSize: "22px", fontWeight: "bold", color: "#8C8C8C" }}
        >
          {name}
        </span>
      </div>
      <Collapsible isOpen={isDraftOpen}>{children}</Collapsible>
    </StyledFolder>
  );
};

const Tree = ({ children }) => {
  return <StyledTree>{children}</StyledTree>;
};

Tree.File = File;
Tree.Folder = Folder;

function DraftDocumentTransaction({ id, newRedirectClientToOffer, documents }) {
  const dispatch = useDispatch();
  let unmounted = {
    value: false
  };
  let source = axios.CancelToken.source();
  useEffect(() => {
    if (id) {
      let status = {
        status: "DRAFT",
        clientId: id,
      };
      if (documents === "DRAFT") {
        dispatch(clientAction.getDraftDocumentData({data: status, unmounted, source}));
      }
    }

    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  }, [id]);

  return (
  
    <></>
  );
}

export { DraftDocumentTransaction,File };
