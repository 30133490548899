import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { DashboardOutlined, BulbOutlined, ToolOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {bugReportHandle,} from "../state/actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const { Sider } = Layout;

function SideBar({ toggle,setIsSuggestions, collapsed }) {
  const [selectedKey, setSelectedKey] = useState("listings");
  const [role,setRole] = useState(false);
//   const location = useLocation();
  const dispatch = useDispatch();
  const { getUserDetailsData } = useSelector((state) => state.account);
  const param = new URLSearchParams(window.location.search);
  const type = param.get("type");
  const history = useHistory();

  useEffect(() => {
    for(let key in getUserDetailsData?.roles){
      if(getUserDetailsData?.roles[key] === "RESURE_SUPPORT"){
        setRole(true);
        break;
      }else{
        setRole(false);
      }
    }
  },[getUserDetailsData?.roles])

//   useEffect(() => {
//     switch (location.pathname) {
//       case "/":
//         setSelectedKey("0");
//         break;
//       case "/listing":
//         setSelectedKey("1");
//         break;
//       case "/listEdit":
//         setSelectedKey("2");
//         break;
//       case "/upload":
//         setSelectedKey("4");
//         break;
//       case "/suggestion":
//         setSelectedKey("3");
//         break;
//       case "/user":
//         setSelectedKey("5");
//         break;
//       case "/nda":
//         setSelectedKey("6");
//         break;
//       case "/clauses":
//         setSelectedKey("7");
//         break;
//       default:
//         break;
//     }
//     return () => {};
//   }, [location]);

//   let handleClick = (e) => {
//     setSelectedKey(e.key);
//   };

const onClick = (e) => {
  let compType;
  if(type?.length){
    dispatch(bugReportHandle.resetSearchData());
    dispatch(bugReportHandle.renderComponant(`${e.key}`));
    if(e.key === "listings"){
      compType = "BUGS"
    }else if(e.key === "suggestions"){
      compType = "SUGGESTIONS"
    }else{
      compType = "SUPPORT"
    }
    history.push(`/bugsReport?type=${compType}`);
  }
    setSelectedKey(e.key);
  };

useEffect(() => {
    dispatch(bugReportHandle.renderComponant(selectedKey));
}, [selectedKey,dispatch]);


  return (
    <Sider
      trigger={null}
      breakpoint="sm"
      collapsedWidth="0"
      style={{
        backgroundColor:  "white",
        marginTop:"20px",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        display: "flex",
        color: "#ffffff",
        justifyContent: "center",
        padding: "1rem 0rem 0 0.2rem",
        height:"95%"
      }}
      onCollapse={(collapsed, type) => {}}
    >

     <Menu
      onClick={onClick}
      style={{
        width: '100%',
      }}
      defaultSelectedKeys={type === "SUGGESTIONS" ? ['suggestions'] : type === "SUPPORT" ? ['support'] : ['listings']}
      defaultOpenKeys={type === "SUGGESTIONS" ? ['suggestions'] : type === "SUPPORT" ? ['support'] : ['listings']}
      mode="inline"
    >
        <Menu.Item
          key={"listings"}
          icon={
            <DashboardOutlined
              style={
                collapsed
                  ? { fontSize: "25px", }
                  : { fontSize: "18px",  }
              }
            />
        }
        >
            <span  style={{fontWeight:"bold",fontFamily:"sans-serif" }}>BUG LISTINGS</span>
        </Menu.Item>
        <Menu.Item
          key={"suggestions"}
          icon={
            <BulbOutlined
              style={
                collapsed
                  ? { fontSize: "25px" }
                  : { fontSize: "18px", color: "white" }
              }
            />
          }
        >
            <span  style={{fontWeight:"bold",fontFamily:"sans-serif" }}>SUGGESTIONS</span>
        </Menu.Item>
        <Menu.Item
          key={"support"}
          icon={
            <ToolOutlined
              style={
                collapsed
                  ? { fontSize: "25px" }
                  : { fontSize: "18px", color: "white" }
              }
            />
          }
        >
            <span  style={{fontWeight:"bold",fontFamily:"sans-serif" }}>SUPPORT</span>
        </Menu.Item>
    </Menu>

      {/* <Menu
        style={{
          background: "transparent",
        }}
        theme="dark"
        // style={{ background: "#0077D8" }}
        mode="inline"
        // onSelect={onMenuSelect}
        defaultSelectedKeys={["0"]}
        selectedKeys={[selectedKey]}
        onClick={handleClick}
      >
        <Menu.Item
        //   disabled
          key={"0"}
          icon={
            <DashboardOutlined
              style={
                collapsed
                  ? { fontSize: "25px" }
                  : { fontSize: "18px", color: "white" }
              }
            />
          }
        >
          <Link to="#">
            <span onClick={()=>setIsSuggestions(true)} style={{ color: "#fff" }}>Bug Listing</span>
          </Link>
        </Menu.Item>

        <Menu.Item
        //   disabled
          key={"0"}
          icon={
            <DashboardOutlined
              style={
                collapsed
                  ? { fontSize: "25px" }
                  : { fontSize: "18px", color: "white" }
              }
            />
          }
        >
          <Link to="#">
            <span onClick={()=>setIsSuggestions(true)} style={{ color: "#fff" }}>Suggestions</span>
          </Link>
        </Menu.Item>


        <Menu.Item
          disabled
          key={"1"}
          icon={
            <UnorderedListOutlined
              style={
                collapsed
                  ? { fontSize: "25px" }
                  : { fontSize: "18px", color: "white" }
              }
            />
          }
        >
          <Link to="/listing" disabled>
            <span style={{ color: "#fff" }}>Comming Soon!</span>
          </Link>
        </Menu.Item>
        <Menu.Item
          disabled
          key={"2"}
          icon={
            <EditOutlined
              style={
                collapsed
                  ? { fontSize: "25px" }
                  : { fontSize: "18px", color: "white" }
              }
            />
          }
        >
          <Link to="/listEdit" disabled>
            <span style={{ color: "#fff" }}>Comming Soon!</span>
          </Link>
        </Menu.Item>
        <Menu.Item
          disabled
          key={"3"}
          icon={
            <GoldOutlined
              style={
                collapsed
                  ? { fontSize: "25px" }
                  : { fontSize: "18px", color: "white" }
              }
            />
          }
        >
          <Link to="/suggestion" disabled>
            <span style={{ color: "#fff" }}>Comming Soon!</span>
          </Link>
        </Menu.Item>
        <Menu.Item
          disabled
          key={"4"}
          icon={
            <CloudUploadOutlined
              style={
                collapsed
                  ? { fontSize: "25px" }
                  : { fontSize: "18px", color: "white" }
              }
            />
          }
        >
          <Link to="/upload" disabled>
            <span style={{ color: "#fff" }}> Comming Soon!</span>
          </Link>
        </Menu.Item>
      </Menu> */}
    </Sider>
  );
}
export { SideBar };
