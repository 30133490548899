import {
  Button,
  Form,
  List,
  Modal,
  message,
  Spin,
  Select,
  DatePicker,
  Dropdown,
  Menu,
  Tooltip
} from "antd";
import { FilterOutlined, FilterFilled, MenuOutlined, PlusCircleFilled } from "@ant-design/icons";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bugReportHandle } from "../state/actions";
import { useRef } from "react";
import styles from "../styles/Listing.module.css";
import { useEffect } from "react";
import EachCard from "./EachCard";
import { EmptyData } from "../../Common/components/EmptyData";

import { useMediaQuery } from "react-responsive";
import { UserModal } from "./UserModal";
import BugModal from "./BugModal";
import { useHistory } from "react-router-dom";
import {detectWebView, roleAccessFunc} from "../../Common/utils/extraFunctions"
import { userSignUpAction } from "../../User/UserAuthenticate/state/actions";
import { FiRefreshCw } from "react-icons/fi";
import { FilterModal } from "./FilterModal";

const Suggestions = ({idValue}) => {
  const {
    suggestionsData,
    bugPostData,
    suggestionsDataLoading,
    searchbugdata,
    filteredData,
    filteredDataLoading,
    uploadImageLoading,
    status,
    like,
    priority,
    startDate,
    endDate 
  } = useSelector((state) => state.bugReport);
  const { getUserDetailsData, roleAccessed } = useSelector((state) => state.account);
  const { renderComp } = useSelector((state) => state.bugReport);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bufferData, setbufferData] = useState([]);
  const [page, setPage] = useState(1);
  const imageFormdataRef = useRef([]);
  const [role, setRole] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterToggle, setFilterToggle] = useState(false);
  const [clientID, setClientID] = useState("");
  const [form] = Form.useForm();
  const [input, setInput] = useState("");
  const [userDataPage, setUserDataPage] = useState(1);
  const [searchUserdata, setSearchUserData] = useState("");
  const [userModalVisible, setUserModalVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const userSuggestionsData = suggestionsData?.filter(
    (el) => el?.createdBy?._id === getUserDetailsData?._id
  );
  const searchData = searchbugdata?.filter((el) => el?.type === "SUGGESTIONS");
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isMenu = useMediaQuery({ maxWidth: 985 });
  const inputRef = useRef();

  const showModal = () => {
    setbufferData([]);
    imageFormdataRef.current = [];
    setIsModalOpen(true);
  };

  if (bugPostData && Object.keys(bugPostData).length > 0) {
    if (bugPostData?.message?.length > 0) {
      message.success("Suggestion Updated!");
    } else {
      message.success("Suggestion Created!");
    }
    dispatch(bugReportHandle.resetBugPostData());
  }

  useEffect(() => {
    if(idValue){
      dispatch(bugReportHandle.searchbug(idValue,"SUGGESTIONS"))
    }else{
      dispatch(bugReportHandle.resetAllData("SUGGESTIONS"));
      dispatch(bugReportHandle.resetFilteredData());
      dispatch(bugReportHandle.getSuggestionsData(page));
      dispatch(bugReportHandle.getBugListingDatayourBugs("SUGGESTIONS", true));
    }
  }, [dispatch, page,idValue]);



  const handleFilter = () => {
    if (
      status.length > 0 ||
      like !== null ||
      startDate.length > 0 ||
      priority.length > 0
    ) {
      dispatch(
        bugReportHandle.getFilteredData(
          status,
          like,
          priority,
          startDate,
          endDate,
          "SUGGESTIONS"
        )
      );
      setFilterToggle(true);
      setShowFilter(false);
    }
  };

  const handleClearAll = () => {
    dispatch(bugReportHandle.resetFilteredData());
    setPage(1)
    form.resetFields();
    setFilterToggle(false);
    setShowFilter(false);
    dispatch(bugReportHandle.resetAllData("SUGGESTIONS"));
    dispatch(bugReportHandle.getSuggestionsData(page));
    dispatch(bugReportHandle.getBugListingDatayourBugs("SUGGESTIONS", true));
  };

  useEffect(() => {
    if (userModalVisible) {
      if (userDataPage > 1 && searchUserdata.length <= 0) {
        dispatch(bugReportHandle.getUsersData(userDataPage));
      } else if (searchUserdata.length > 0) {
        const timeoutId = setTimeout(() => {
          dispatch(bugReportHandle.getUsersData(userDataPage, searchUserdata));
        }, 1000);
        return () => clearTimeout(timeoutId);
      }
      if (userDataPage === 1) {
        dispatch(bugReportHandle.getUsersData(userDataPage));
      }
    }
  }, [userDataPage, searchUserdata]);

  const priorityOptions = [
    { value: "LOW", label: "Low" },
    { value: "MEDIUM", label: "Medium" },
    { value: "HIGH", label: "High" },
    { value: "CRITICAL", label: "Critical" },
  ];

  useEffect(() => {
    for (let key in getUserDetailsData?.roles) {
      if (getUserDetailsData?.roles[key] === "RESURE_SUPPORT") {
        setRole(true);
        break;
      } else {
        setRole(false);
      }
    }
  }, [getUserDetailsData?.roles]);

  useEffect(() => {
    if (isModalOpen) {
      const focusTimeout = setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100);
      return () => {
        clearTimeout(focusTimeout);
      };
    }
  }, [isModalOpen]);

  let userKey = new URLSearchParams(window.location.search).get(
    "app_request_key"
  );
  let redirectHome = async () => {
    await window?.ReactNativeWebView?.postMessage("WINDOW_CLOSED");
  };
  const handleClose = async () => {
    const isInWebView =  detectWebView();
      if (isInWebView) {
        redirectHome();
      } else {
         history.push("/tools");
      }
  };

  const logout = () => {
    dispatch(userSignUpAction.logOut(history));
  };
  const dropdownMenu = () => {
    if (userKey === null || userKey === undefined) {
      return (
        <Menu
          style={{ minWidth: "200px" }}
          onClick={(e) => handledropDownMenu(e)}
        >
          <Menu.Item key="0">Bug Listings</Menu.Item>
          <Menu.Item key="5">Suggestions</Menu.Item>
          <Menu.Item key="7">Support</Menu.Item>
          {(roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS")) || role ? <Menu.Item key="1">Your Bugs</Menu.Item> : null}
          {(roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS")) || role ? <Menu.Item key="6">Your Suggestions</Menu.Item> : null}
          {(roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS")) || role ? <Menu.Item key="8">Your Tickets</Menu.Item> : null}
          <Menu.Item key="4">Close</Menu.Item>
        </Menu>
      );
    }
    return (
      <Menu
        style={{ minWidth: "200px" }}
        onClick={(e) => handledropDownMenu(e)}
      >
        <Menu.Item key="0">Bug Listings</Menu.Item>
        <Menu.Item key="5">Suggestions</Menu.Item>
        <Menu.Item key="7">Support</Menu.Item>
        {(roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS")) || role ? <Menu.Item key="1">Your Bugs</Menu.Item> : null}
        {(roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS")) || role ? <Menu.Item key="6">Your Suggestions</Menu.Item> : null}
        {(roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS")) || role ? <Menu.Item key="8">Your Tickets</Menu.Item> : null}
        {/* <Menu.Item key="2">Report Bug</Menu.Item> */}
        <Menu.Item key="4">Close</Menu.Item>
        {/* <Divider />
        <Menu.Item key="3">Logout</Menu.Item> */}
      </Menu>
    );
  };
  let handledropDownMenu = (e) => {
    switch (e.key) {
      case "0": {
        history.push("/bugsReport");
        break;
      }
      case "1": {
        history.push("/yourBugs");
        break;
      }
      // case "2": {
      //   handleReportBug();
      //   break;
      // }
      case "3": {
        logout();
        break;
      }
      case "4": {
        handleClose()
        break;
      }
      case "5": {
        history.push("/suggestions");
        break;
      }
      case "6": {
        history.push("/yourSuggestions");
        break;
      }
      case "7": {
         history.push("/mobilesupport");
        break;
      }
      case "8": {
        history.push("/yourTickets");
        break;
      }
      default:
        break;
    }
  };

  const handleRefresh = () => {
    if(!filterToggle){
      dispatch(bugReportHandle.resetAllData("SUGGESTIONS"));
      dispatch(bugReportHandle.resetFilteredData());
      dispatch(bugReportHandle.getSuggestionsData(page));
      dispatch(bugReportHandle.getBugListingDatayourBugs("SUGGESTIONS", true));
    }else{
      handleFilter()
    }
  }

  const handleScroll = (e) => {
    const element = e.target.scrollTop
    if(element < 0){
      handleRefresh()
    }
  }

  return (
    <div
      style={{
        width: "100%",
        height: isMobile ? "88vh" : "85vh",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
      onScroll={handleScroll}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px",
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 1,
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        }}
      >
        {isMenu && <span style={{height:"auto",marginLeft:"10px" }}>
          <Dropdown overlay={dropdownMenu} trigger={["click", "hover"]}>
            <MenuOutlined style={{ color: "black",fontSize:"22px",marginTop:"35%"}} />
          </Dropdown> 
        </span>}
        <span
          style={{
            color: "grey",
            fontSize: "21px",
            fontWeight: "bold",
            margin: "auto",
          }}
        >
          SUGGESTIONS
        </span>
        <div style={{ display: "flex", gap: "7px" }}>
          {filteredData.length > 0 || filterToggle ? (
            <div>
              <span
                style={{
                  margin: "0 5px 5px -10px",
                  fontSize: "16px",
                  color: "#1990ff",
                  cursor: "pointer",
                }}
                onClick={handleClearAll}
              >
                {isMobile ? "Clear" : "Clear filters"}
              </span>
              <FilterFilled
                style={{ fontSize: "22px", cursor: "pointer", marginTop: "5px" }}
                onClick={() => {
                  setShowFilter(true);
                }}
              />
            </div>
          ) : (
            <FilterOutlined
              style={{ fontSize: "22px", cursor: "pointer", marginTop: "5px" }}
              onClick={() => {
                setShowFilter(true);
              }}
            />
          )}
          <FiRefreshCw
            style={{
              fontSize: "22px",
              color: "blue",
              cursor: "pointer",
              marginTop:"5px"
            }}
            onClick={handleRefresh}
          />
          {isMobile ? <Tooltip placement="left" title={"Add Suggestion"}><PlusCircleFilled style={{color:"#085190",fontSize:"25px",marginTop:"4px"}} onClick={showModal}/></Tooltip> :
          <Button onClick={showModal} type="primary">
            Add Suggestion
          </Button>}
        </div>
      </div>
      {/* suggestions  Modal  */}
      <BugModal
        bufferData={bufferData}
        setUserModalVisible={setUserModalVisible}
        setSearchUserData={setSearchUserData}
        input={input}
        setInput={setInput}
        setbufferData={setbufferData}
        userDataPage={userDataPage}
        role={role}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        clientID={clientID}
        setUserDataPage={setUserDataPage}
      />
      {uploadImageLoading ? 
        <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "30%",
        }}
      >
        <Spin size="large" />
      </div> : null}
      {filteredDataLoading ? <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30%",
          }}
        >
          <Spin size="large" />
        </div> : null}
      {suggestionsDataLoading && page <= 1 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30%",
          }}
        >
          <Spin size="large" />
        </div>
      ) : filterToggle ? (
        filteredData.length > 0 && !uploadImageLoading ? (
          <div
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "82vh",
              width: "100%",
            }}
          >
            <List
              itemLayout="vertical"
              size="large"
              dataSource={filteredData}
              renderItem={(item) => <EachCard item={item} page={page} />}
            />
          </div>
        ) : (
          <div
            style={{
              marginTop: "20%",
            }}
          >
            <EmptyData />
          </div>
        )
      ) : suggestionsData.length > 0 && !uploadImageLoading ? (
        <div
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            height: "82vh",
            width: "100%",
          }}
        >
          {searchData?.length <= 0 ? (
            <List
              itemLayout="vertical"
              size="large"
              dataSource={
                (roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS")) || role
                  ? suggestionsData
                  : userSuggestionsData
              }
              renderItem={(item) => <EachCard item={item} page={page} />}
            />
          ) : (
            renderComp === "suggestions" && (
              <List
                itemLayout="vertical"
                size="large"
                dataSource={searchData}
                renderItem={(item) => <EachCard item={item} page={page} />}
              />
            )
          )}
        </div>
      ) : suggestionsData.length <= 0 && !uploadImageLoading && searchData?.length > 0 ? (
        <div
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            height: "82vh",
            width: "100%",
          }}
        >
          <List
            itemLayout="vertical"
            size="large"
            dataSource={searchData}
            renderItem={(item) => <EachCard item={item} page={page} />}
          />
        </div>
      
      ) : (
        !uploadImageLoading && <div
          style={{
            marginTop: "30%",
          }}
        >
          <EmptyData />
        </div>
      )}
      {suggestionsDataLoading && page > 1 ? 
        <div
          style={{
            position: "fixed",
            bottom: 50,
            left: -100,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div> : null }

      {/* Filter Modal */}
      <FilterModal showFilter={showFilter} setShowFilter={setShowFilter} setPage={setPage} page={page} form={form} filterToggle={filterToggle} setFilterToggle={setFilterToggle} source="SUGGESTIONS"/>
      {/* User Modal */}
      <UserModal
        userModalVisible={userModalVisible}
        setUserModalVisible={setUserModalVisible}
        searchUserdata={searchUserdata}
        setSearchUserData={setSearchUserData}
        setClientID={setClientID}
        setInput={setInput}
        userDataPage={userDataPage}
        setUserDataPage={setUserDataPage}
      />
    </div>
  );
};

export { Suggestions };
