import React, { useEffect, useState } from "react";
import { Menu,} from "antd";
import {
  BookOutlined,
  YoutubeOutlined,
  ToolOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { landingPageAction } from "../state/actions";
import { render } from "@testing-library/react";


const data = [
//   {
//     key: "0",
//     title: "NEWS / RE-Sure EVENTS",
//     image: <BookOutlined style={{ fontSize: "24px", color: "#085191" }} />,
//   },
//   {
//     key: "1",
//     title: "TUTORIALS",
//     image: <YoutubeOutlined style={{ fontSize: "24px", color: "#085191" }} />,
//   },
  // {
  //   key: "2",
  //   title: "BLOG",
  //   image: <CopyOutlined style={{ fontSize: "24px", color: "#085191" }} />,
  // },
  {
    key: "3",
    title: "CONTACT",
    image: <MobileOutlined style={{ fontSize: "24px", color: "#085191" }} />,
  },
  {
    key: "4",
    title: "Customer Support",
    image: <ToolOutlined style={{ fontSize: "24px", color: "#085191" }} />,
  },
  {
    key: "5",
    title: "Tutorial Videos and Q&A",
    image: <YoutubeOutlined style={{ fontSize: "24px", color: "#085191" }} />,
  },
];

const ResureMenu = ({settabKey, settoggle}) => {
  const [item, setItems] = useState("0");
  const {renderComp} = useSelector(state => state.landingPageReducer.supportReducer);
  const dispatch = useDispatch();

  const handleClick = (item) => {
    if (item?.key === "4") {
        setItems("0");
      settabKey("0");
      window.open("/bugsReport");
    } else {
      settabKey(item?.key);
      setItems(item?.key);
      settoggle(true);
    }
};

useEffect(() => {
  if(renderComp==='NEWS') setItems("0")
  if(renderComp==='TUTORIALS') setItems("1")
  if(renderComp==='CONTACT') setItems("3")
  if(renderComp==='TutorialsVideos') setItems("5")
}, [renderComp])




  return (
    <>
      <Menu
        mode="inline"
        selectedKeys={[item]}
        // style={{
        //   width: 256,
        // }}
        onClick={(key) => handleClick(key)}
      >
        {data.map((x) => {
          return (
            <Menu.Item
              key={x.key}
              icon={x.image}
              // onClick={handleClick(0)}
            >
              <span className="left_sidebar_property">{x.title}</span>
            </Menu.Item>
          );
        })}
      </Menu>
      {/* <List
        itemLayout="horizontal"
        dataSource={data}
        className="reminder"
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={item.image}
              title={<a href="https://ant.design">{item.title}</a>}
              //   description="Ant Design, a design language"
            />
          </List.Item>
        )}
      /> */}
    </>
  );
};

export default ResureMenu;
