/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import houseImage from "../../Assets/imagePlaceholder.webp";
import RtdRow from "./RtdRow";
import { Tooltip, Typography, Image } from "antd";
import { FolderFilled } from "@ant-design/icons";
import { BsInfoLg } from "react-icons/bs";
import style from "../style/rtd.module.css";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import TransactionEmail from "./TransactionEmail";
import { useMediaQuery } from "react-responsive";
import AgentDetailsModal from "./AgentDetailsModal";
import AvatarGroup from "./AvatarGroup";
import SharedModel from "./SharedModel";
const { Text } = Typography;

function RtdWindow({ client, section, currentAgentDetails, allBuyerAgentData, allSellerAgentData,   encryptedOwnerId }) {
  const { getTransactionLaneData, getIndividualTransactionData } = useSelector((state) => state.rtdTransactionLane);
  const isSmallScreen = useMediaQuery({ maxWidth: 550 });
  const isMediumScreen = useMediaQuery({ minWidth: 551, maxWidth: 850 });
  const [showmodal, setshowModal] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [userState, setUserState] = useState({
    showDetailModal: false,
    allAgentData: [],
    source: "",
  });

  const handleShowAgentDetail = (agents, source) => {
    setUserState({
      showDetailModal: true,
      allAgentData: agents,
      source,
    });
  };
  const handleNeedToKnowOpen = (ele) => {
    history.push({ pathname: location?.pathname?.replace("Dashboard", "Dashboard/needToKnow") });
  };

  return (
    <>
      {/* Agent Details modal  */}
      {userState?.showDetailModal && <AgentDetailsModal userState={userState} setUserState={setUserState} isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen} />}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          textAlign: "center",
          marginTop: "-60px",
          position: "relative",
        }}
      >
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            left: "25%",
            right: "25%",
            top: "6%",
          }}
        >
          {getIndividualTransactionData?.address ? (
            <>
              <div className={style.box}>
                <div className={style.imgBox}>
                  <Image preview style={{ height: "60px", width: "70px", objectFit: "cover" }} src={typeof getIndividualTransactionData?.images[0]?.imageUrl == "string" && getIndividualTransactionData?.images[0]?.imageUrl !== "" ? getIndividualTransactionData?.images[0]?.imageUrl : houseImage} alt="home" />{" "}
                </div>
                <div className={style.addressBox}>
                  <p>{getIndividualTransactionData?.address}</p>
                  {getIndividualTransactionData?.listingId ? (
                    <p>
                      MLS Listing Id: <>{getIndividualTransactionData?.listingId}</>
                    </p>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {getIndividualTransactionData?.transactionId ? (
            <>
              <div style={{ display: "flex", justifyContent: "center", cursor: "pointer", alignItems: "center", textAlign: "center" }}>
                <span onClick={handleNeedToKnowOpen} style={{ fontWeight: "bold", fontSize: "15px", textDecoration: "underline", color: "#4747FF" }}>
                  Transaction Email Address{" "}
                </span>
                <span style={{ fontSize: "25px", marginLeft: "5px" }}>
                  {" "}
                  <Tooltip title={'Any email sent to the address below will be copied into the transaction report. Agents and people listed on the "Need to Know" list will receive a copy of the message.'} placement="right">
                    <BsInfoLg />
                  </Tooltip>{" "}
                </span>
              </div>
              <TransactionEmail selectedData={getIndividualTransactionData} />
            </>
          ) : (
            ""
          )}
        </div>

        {/* buyer seller divs  */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "9vh",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: allSellerAgentData.length < 2 ? "center" : "flex-start" }}>
            <AvatarGroup allAgents={allSellerAgentData} source={"SELLER_AGENT"} handleShowAgentDetail={handleShowAgentDetail} />
            <div style={{ maxWidth: "130px", margin: "auto" }}>
              <Text ellipsis style={{ fontWeight: "bold", color: "grey", fontSize: "19px", visibility: allSellerAgentData?.length === 1 ? true : "hidden" }}>
                {allSellerAgentData?.[0]?.fullName}
              </Text>
            </div>
            <div
              style={{
                background: "#2976be",
                width: "140px",
                height: "33px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                fontSize: "14px",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                borderBottomRightRadius: "5px",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  marginTop: "12%",
                  fontWeight: "bold",
                  color: "white",
                  fontFamily: "Roboto, sans-serif",
                }}
              >
                <div>{`Seller Agent${allSellerAgentData?.length > 1 ? "s" : ""}`}</div>
              </p>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", alignItems: allBuyerAgentData?.length < 2 ? "center" : "flex-end" }}>
            <AvatarGroup allAgents={allBuyerAgentData} source={"BUYER_AGENT"} handleShowAgentDetail={handleShowAgentDetail} />
            <div style={{ maxWidth: "130px", margin: "auto" }}>
              <Text ellipsis style={{ fontWeight: "bold", color: "grey", fontSize: "19px", visibility: allBuyerAgentData?.length === 1 ? true : "hidden" }}>
                {allBuyerAgentData?.[0]?.fullName}
              </Text>
            </div>
            <div
              style={{
                background: "#ffce51",
                width: "140px",
                height: "33px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                fontSize: "14px",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                borderBottomLeftRadius: "5px",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  marginTop: "12%",
                  fontWeight: "bold",
                  fontFamily: "Roboto, sans-serif",
                }}
              >
                <div>{`Buyer Agent${allBuyerAgentData?.length > 1 ? "s" : ""}`}</div>
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "calc(80vh - 12.2rem)",
          }}
          className={style.invisibleScroll}
        >
          <div
            style={{
              flex: 1,
              overflowY: "auto",
              overflowX: "hidden",
              paddingRight: "17px",
              marginRight: "-17px",
              marginBottom: "5px",
            }}
          >
            <div
              style={{
                width: "100%",
                borderStyle: "solid",
                minHeight: "100%",
                borderRightWidth: 8,
                borderLeftWidth: 8,
                borderBottomWidth: 0,
                borderTopWidth: 0,
                borderRightColor: "#ffce51",
                borderLeftColor: "#3376a8",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                alignSelf: "center",
              }}
            >
              {getTransactionLaneData &&
                getTransactionLaneData.map((item, i) => {
                  return <RtdRow {...item} key={item._id} currentAgentDetails={currentAgentDetails} allBuyerAgentData={allBuyerAgentData} allSellerAgentData={allSellerAgentData} client={client} selectedData={getIndividualTransactionData} section={section}   />;
                })}
            </div>
          </div>
          {!showmodal && (getIndividualTransactionData?.status === "ACCEPTED" || "SUBMITTED" || client === "SELLER") ? (
            <button
              style={{
                width: "60%",
                marginTop: "10px",

                margin: "auto",
                height: "25px",
                borderRadius: "15px",
                fontWeight: "500",
                fontSize: "18px",
                alignItems: "center",
                borderColor: "#2976be",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                color: "#096dd9",
              }}
              onClick={() => {
                setshowModal(true);
              }}
            >
              <div style={{ marginRight: "10px", color: "#2976be" }}>
                <FolderFilled />
              </div>
              Shared
            </button>
          ) : (
            ""
          )}
        </div>
        {/* Shared docs modal */}
        <SharedModel setshowModal={setshowModal} showmodal={showmodal} section={section} client={client} />
      </div>
    </>
  );
}

export default RtdWindow;
