import {
    Button,
    Checkbox,
    Dropdown,
    Form,
    Image,
    Input,
    Menu,
    Modal,
    Tooltip,
  } from "antd";
  import React, { useEffect, useRef, useState } from "react";
  import { PlusCircleFilled, MoreOutlined, FormOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
  import { DraggerComp } from "../../Common/components/DraggerComp";
  import { useDispatch, useSelector } from "react-redux";
  import { accountHandle } from "../state/actions";
  import { Loading } from "../../Common/components/Loading";
  import { EmptyData } from "../../Common/components/EmptyData";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useMediaQuery } from "react-responsive";


const EmailSignature = () => {
    // having doubts on this
    const [ismodal, setIsmodal] = useState({ visible: false, openFor: "", isDeleteImage:false, item:{signatureImageUrl:""} });
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const uploadFile = useRef("");
    const { TextArea } = Input;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { confirm } = Modal;
    const quillRef = useRef();
    const isMobile = useMediaQuery({maxWidth:600})
    const { emailSignData, emailSignLoading, emailSignError, isLoadSignApi } = useSelector(
      (state) => state.account,
    );
    const {
      title,
      footerText,
      isDefault,
      signatureImageUrl,
      _id: uniqueId,
    } = ismodal?.item || [];
    const [footerMessage, setFooterMessage] = useState("");

    const onFieldsChange = (changedFields, allFields) => {
      const signatureText = form.getFieldValue("footerText");
      const uploadImage = form.getFieldValue("uploadImage");
      let blobFile;
      if (uploadFile.current !== "") {
        blobFile = uploadFile.current.get("file");
      }
      // Enable submit button if either footerText or uploadImage is present
      if ((signatureText && signatureText.trim() !== "") || uploadImage || footerMessage || blobFile) {
        setIsSubmitDisabled(false);
      } else {
        setIsSubmitDisabled(true);
      }
    };



    const formats = [
        'header', 'bold', 'italic', 'underline', 'strike', 'list', 'indent', 'color', 'align', 'background'
      ];

      const modules = {
        toolbar: [
          [{ header: [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }]
        ]
      };

    const onEditorChange =(value) => {
        if(value === "<p><br></p>"){
            setFooterMessage("");
            setIsSubmitDisabled(true);
            return
        }
        setFooterMessage(value);
        onFieldsChange();
    };

    const onFinish = (value) => {
      const { footerText, title, isDefault } = value;
      let values = { footerText: footerMessage };
      let blobFile;
      const formData = new FormData();
      if (uploadFile.current !== "") {
        blobFile = uploadFile.current.get("file");
      }

      if (blobFile) {
        formData.append("folderOf", "EMAIL_SIGNATURE");
        formData.append("fileBase64", blobFile);
      }

      if (ismodal.openFor === "CREATE") {
        // create case
        if (blobFile) {
          const payload = {
            imgData: formData,
            footerText,
            title,
            isDefault,
            ismodal,
            ...values
          };
          dispatch(accountHandle.uploadImageForEmailSign(payload));
        } else {
          const payload = { footerText, title, isDefault, ...values };
          dispatch(accountHandle.uploadEmailSignImg(payload));
        }
      } else {
        // edit case
        if (blobFile) {
          const payload = {
            imgData: formData,
            footerText,
            title,
            isDefault,
            ismodal,
            id: uniqueId,
            ...values
          };
          dispatch(accountHandle.uploadImageForEmailSign(payload));
        } else {
          const payload = {footerText, title, isDefault, id: uniqueId, ...values };
          if(ismodal.isDeleteImage){
              payload.imageUrl ="";
            }
          dispatch(accountHandle.updateEmailSignImg(payload));
          setIsmodal((prev)=>({...prev,isDeleteImage:false}));
        }
      }
      handleCancel();
    };

    const handleCancel = () => {
      setIsmodal({ visible: false, openFor: "" });
      form.resetFields();
      uploadFile.current = "";
    };

    const handleDelete = (id) => {
      confirm({
        title: "Do you want to delete this Email Signature?",
        okText: "Yes",
        cancelText: "No",
        okType: "danger",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          dispatch(accountHandle.deleteEmilSign(id));
        },
        onCancel() {},
      });
    };

    const handleEdit = (item) => {
      setIsmodal({ visible: true, openFor: "EDIT", item });
    };

    const handleMakeDefault =(id,title)=>{
        const payload = {isDefault:true, id , title};
        dispatch(accountHandle.updateEmailSignImg(payload));
    };

    const MenuItem = ({ item }) => {
      return (
        <Menu size="large">
          <Menu.Item
            key="1"
            onClick={() => {
              handleEdit(item);
            }}
          >
            <FormOutlined /> EDIT
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={() => {
              handleDelete(item?._id);
            }}
          >
            <DeleteOutlined /> DELETE
          </Menu.Item>
        </Menu>
      );
    };

    let unmounted = {
        value: false
      };
      let source = axios.CancelToken.source();

    useEffect(() => {
      dispatch(accountHandle.getEmailSignData({unmounted, source}));

      return () => {
              unmounted.value = true;
              source.cancel("Cancelling in cleanup");
            }
    }, [isLoadSignApi]);

    useEffect(() => {
        setIsSubmitDisabled(true);
    }, [ismodal.isDeleteImage])


    useEffect(() => {
      if (footerText !== undefined || ismodal.openFor === "EDIT" || signatureImageUrl) {
        form.setFieldsValue({ footerText, title, isDefault });
        setFooterMessage(footerText)
      } else {
        form.setFieldsValue({ footerText: "" });
      }
      onFieldsChange();
    }, [footerText, form, isDefault, ismodal?.openFor, ismodal?.visible, title, ismodal?.isImagePasted]);


    return (
      <div
        style={{
          height: "100%",
          textAlign: "center",
        }}
      >
        <Modal
          footer={false}
          visible={ismodal.visible}
          onCancel={handleCancel}
          style={{ marginTop: "-50px" }}
          width={600}
          title={
            <span
              style={{ color: "grey", display: "flex", justifyContent: "center" }}
            >
             {ismodal.openFor === "EDIT" ? "Update Email Signature" : "Add Email Signature"}
            </span>
          }
        >
          <div>
            <Form
              form={form}
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
              onFieldsChange={onFieldsChange}
              initialValues={{
                isDefault: false,
              }}
            >
              <Form.Item
                label="Name"
                name="title"
                style={{ margin: "-15px 0 0 0" }}
                rules={[{ required: true, message: "Please enter your name!" }]}
              >
                <Input placeholder="Enter Name..." />
              </Form.Item>

              <Form.Item
                label="Signature Text"
                name="footerText"
                style={{ margin: "5px 0 0 0" }}
              >
                <div style={{ height: "200px",marginBottom:isMobile? "75px":"25px"}}>
                  <ReactQuill
                    className="editTemplate"
                    placeholder="Enter message..."
                    formats={formats}
                    style={{ height: "80%", border: "none" }}
                    ref={quillRef}
                    value={footerMessage}
                    onChange={onEditorChange}
                    modules={modules}
                    />
                </div>
              </Form.Item>

              <Form.Item
                label="Signature Image"
                name="uploadImage"
                style={{ margin: "-15px 0 0 0" }}
              >
                <div style={{ display: "flex", justifyContent: "center"}}>
                  <DraggerComp
                    uploadFile={uploadFile}
                    toggle={true}
                    source={"EMAIL_SIGN"}
                    imageUrl={ismodal.openFor === "EDIT" && signatureImageUrl}
                    openFor={ismodal.openFor}
                    setIsmodal={setIsmodal}
                    accept="image/*"
                  />
                </div>
              </Form.Item>

              <Form.Item
                name="isDefault"
                valuePropName="checked"
                style={{ margin: "-15px 0 0 0" }}
              >
                <Checkbox>
                  <span style={{ fontWeight: "bold", color: "gray" }}>
                    Use as default
                  </span>
                </Checkbox>
              </Form.Item>

              <Form.Item style={{ textAlign: "center", margin:"-5px 0px -5px 0px" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isSubmitDisabled}
                >
                  Save Email Signature
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingTop: "5px",
            borderBottom: "1px solid gray",
          }}
        >
          <div style={{ width: "80%" }}>
            <h2>Email Signature</h2>
          </div>
          <div>
            <Tooltip title="Upload Email Signature.">
              <Button
                shape="circle"
                style={{
                  paddingTop: "0px",
                }}
                icon={
                  <PlusCircleFilled
                    style={{
                      fontSize: "30px",
                      color: "#085191",
                    }}
                    onClick={() => {
                      setIsmodal({ visible: true, openFor: "CREATE" });
                      setFooterMessage('');
                    }}
                  />
                }
              ></Button>
            </Tooltip>
          </div>
        </div>

        <div style={{ height: "100%" }}>
          {emailSignLoading ? (
            <div style={{ height: "100%", margin: "auto" }}>
              <Loading />
            </div>
          ) : emailSignError || emailSignData?.length === 0 ? (
            <div style={{ marginTop: "35%" }}>
              <EmptyData />
            </div>
          ) : (
            <div style={{ height: "90%", overflow: "hidden", overflowY: "auto" }}>
              {emailSignData &&
                emailSignData?.length &&
                emailSignData?.map((el, ind) => (
                  <div
                    style={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                      width: "60%",
                      borderRadius: "5px",
                      padding: "10px",
                      margin: "auto",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div>
                        <span
                          style={{
                            color: "gray",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          Name: {el?.title}
                        </span>
                      </div>
                      <div>
                        <Dropdown
                          overlay={<MenuItem item={el} />}
                          trigger={["hover", "click"]}
                        >
                          <MoreOutlined
                            key="ellipsis"
                            style={{
                              fontSize: "26px",
                              color: "#08c",
                              marginTop: "-28px",
                            }}
                          />
                        </Dropdown>
                      </div>
                    </div>


                    <div style={{marginTop:"5px"}}>
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          textAlign: "left",
                          lineHeight: "0.8",
                        }}
                      >
                        {el?.footerText && (
                          <div dangerouslySetInnerHTML={{ __html: el.footerText }} />
                        )}
                      </p>
                    </div>


                    {el?.signatureImageUrl && (
                      <Image
                        style={{ width: "600px", height: "300px", objectFit:"contain" }}
                        src={el?.signatureImageUrl}
                        alt="Signature"
                        loading="lazy"
                      />
                    )}

                    <div style={{textAlign:"left",marginTop:"5px"}}>
                        <Checkbox checked={el?.isDefault} onChange={()=>handleMakeDefault(el?._id,el?.title)}>
                           <span style={{color:"grey", fontWeight:"bold"}}>Use as Default</span>
                        </Checkbox>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  export default EmailSignature;
