import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { account } from "./Account";
import { app } from "./App";
import { client } from "./Client";
import { documentSection } from "./DocumentSection";
import { template } from "./Template";
import { ToolReducers } from "./Tools";
import { userSignup } from "./User/UserAuthenticate";
import { breadcrum } from "./Breadcrum";
import { bugReport } from "./BugReport";
import { calendarReducer } from "./Calendar";
import { rtdTransactionLane } from "./Rtd/state/reducer";
import { landingPageReducer } from "./Landing";
import { pricingPageReducer } from "./Pricing";
import { userSignUpConstants } from "./User/UserAuthenticate/state/action-types";
import { clientAuth } from "./ClientAuth";
import {clientScreen} from "./ClientScreen"
import { ERTSReducer } from "./ErtsModel";
import { deleteAccountReducer } from "./DeleteAccount/state/reducer";
import { commonReducer } from "./Common";
import { PropertyReducer } from "./PropertyComp/state/reducer";
const reducers = {
  account,
  app,
  client,
  documentSection,
  template,
  ToolReducers,
  userSignup,
  breadcrum,
  calendarReducer,
  rtdTransactionLane,
  bugReport,
  landingPageReducer,
  pricingPageReducer,
  ERTSReducer,
  commonReducer,
  clientAuth,
  clientScreen,
  deleteAccountReducer,
  PropertyReducer,
};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const appReducer = combineReducers(reducers);

const rootReducers = (state, action) => {
  if (userSignUpConstants.LOGOUT_ACTION === action.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = createStore(
  rootReducers,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);
export { store, reducers };
