import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button } from "antd";
import { clientAction } from "../state/actions";

function TitleDetails({ data, selectedClient, setModelDrawer }) {
  const dispatch = useDispatch();
  const inputRef = useRef()
  const onFinish = (values) => {
    dispatch(clientAction.updateTitle(values, data, selectedClient));
    setModelDrawer({ status: false, component: "", title: "" });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      initialValues={{
        designation: data.designation,
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="Title"
        name="designation"
        rules={[{ required: true, message: "Please Enter Title!" }]}
      >
        <Input ref={inputRef} placeholder="Enter Title" allowClear autoFocus/>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}
export { TitleDetails };
