/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Select, Row, Col, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { documentAction } from "../state/actions";
import { Loading } from "../../Common/components/Loading";

const ManualEntry = ({
  handleCancelPropertySearch,
  setFieldIsEmpty,
  fieldEmpty,
  form,
  listingForm,
  setListingForm,
  redirectFromClient
}) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isTab = useMediaQuery({ maxWidth: 950 });

  const { addPropertyLoading } = useSelector(
    (state) => state.documentSection.PropertySearchReducer
  );
  const selectOptions = [
    { value: "East", label: "East" },
    { value: "West", label: "West" },
    { value: "North", label: "North" },
    { value: "South", label: "South" },
    { value: "Northeast", label: "North East" },
    { value: "Northwest", label: "North West" },
    { value: "Southeast", label: "South East" },
    { value: "Southwest", label: "South West" },
  ];

  function listingChange(value, key) {
    const fieldEmptyUpdated = listingForm;
    fieldEmptyUpdated[key] = value;
    setListingForm({ ...fieldEmptyUpdated });
  }

  const handleListingForm = () => {
    let urlData = {
      ...(listingForm.email ? { email: listingForm.email } : {}),
      ...(listingForm.firstName ? { firstName: listingForm.firstName } : {}),
      ...(listingForm.middleName ? { middleName: listingForm.middleName } : {}),
      ...(listingForm.lastName ? { lastName: listingForm.lastName } : {}),
      ...(listingForm.streetNumber
        ? { streetNumber: listingForm.streetNumber }
        : {}),
      ...(listingForm.streetName ? { streetName: listingForm.streetName } : {}),
      ...(listingForm.unit ? { unitNumber: listingForm.unit } : {}),
      ...(listingForm.directionPrefix
        ? { directionPrefix: listingForm.directionPrefix }
        : {}),
      ...(listingForm.directionSuffix
        ? { directionSuffix: listingForm.directionSuffix }
        : {}),
      ...(listingForm.stateOrProvince
        ? { stateOrProvince: listingForm.stateOrProvince }
        : {}),
      ...(listingForm.city ? { city: listingForm.city } : {}),
      ...(listingForm.postalCode ? { postalCode: listingForm.postalCode } : {}),
    };
    dispatch(documentAction.addListingProperty({data: urlData, setFieldIsEmpty, fieldEmpty, redirectFromClient}));
    handleCancelPropertySearch();
    form.resetFields();
    setListingForm({
      email: "",
      firstName: "",
      middleName: "",
      lastName: "",
      streetNumber: null,
      streetName: "",
      unit: null,
      directionPrefix: "",
      directionSuffix: "",
      stateOrProvince: "CO",
      city: "",
      postalCode: null,
    });
  };

  return (
    <div
      style={{
        maxHeight: isMobile ? "" : "550px",
        overflow: "auto",
        overflowX: "hidden",
      }}
    >
      {addPropertyLoading ? (
        <Loading />
      ) : (
        <div style={{padding:"5px"}}>
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="email"
                label="Listing Agent Email"
                className="lbl"
                rules={[{ required: true, message: "Listing Agent Email" }]}
              >
                <Input
                  onChange={(e) => {
                    listingChange(e.target.value, "email");
                  }}
                  placeholder="Listing Agent Email"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                className="lbl1"
                name="firstName"
                label="Listing Agent First Name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Agent First Name",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    listingChange(e.target.value, "firstName");
                  }}
                  placeholder="Listing Agent First Name"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="middleName"
                label="Listing Agent Middle Name"
                className="lbl1"
              >
                <Input
                  onChange={(e) => {
                    listingChange(e.target.value, "middleName");
                  }}
                  placeholder="Listing Agent Middle Name"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                className="lbl1"
                name="lastName"
                label="Listing Agent Last Name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Agent Last Name",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    listingChange(e.target.value, "lastName");
                  }}
                  placeholder="Listing Agent Last Name"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="streetNumber"
                label="Street Number"
                className="lbl"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Street Number",
                  },
                ]}
              >
                <Input
                  type="number"
                  onChange={(e) => {
                    listingChange(e.target.value, "streetNumber");
                  }}
                  placeholder="Please Enter Street Number"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="streetName"
                label="Street Name"
                className="lbl"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Street name",
                  },
                ]}
                onChange={(e) => {
                  listingChange(e.target.value, "streetName");
                }}
              >
                <Input placeholder="Please Enter Street Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="directionPrefix"
                label="Direction Prefix"
                className="lbl1"
              >
                <Select
                  placeholder="Select Direction"
                  options={selectOptions}
                  size="middle"
                  style={{
                    fontSize: "16px",
                    "::placeholder": { fontSize: "6px" },
                  }}
                  onChange={(e) => {
                    listingChange(e, "directionPrefix");
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="directionSuffix"
                label="Direction Suffix"
                className="lbl1"
              >
                <Select
                  placeholder="Select Direction"
                  options={selectOptions}
                  size="middle"
                  style={{
                    fontSize: "16px",
                    "::placeholder": { fontSize: "6px" },
                  }}
                  onChange={(e) => {
                    listingChange(e, "directionSuffix");
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="unit"
                label="Unit"
                className="lbl"
              >
                <Input
                  onChange={(e) => {
                    listingChange(e.target.value, "unit");
                  }}
                  placeholder="Please Enter Unit"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                className="lbl1"
                name="city"
                label="City"
                rules={[{ required: true, message: "Please Enter City name" }]}
              >
                <Input
                  onChange={(e) => {
                    listingChange(e.target.value, "city");
                  }}
                  placeholder="Please Enter City Name"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                className="lbl1"
                name="stateOrProvince"
                label="State"
                rules={[
                  {
                    required: true,
                    message: "Please Enter State name",
                  },
                ]}
              >
                <Input
                defaultValue='CO'
                  onChange={(e) => {
                    listingChange(e.target.value, "stateOrProvince");
                  }}
                  placeholder="Please Enter State Name"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                className="lbl1"
                name="postalCode"
                label="Zip Code"
                hasFeedback
                rules={[
                  { required: true, message: "Please Enter Zip Code" },
                  { whitespace: true },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value?.length > 4 && value?.length < 7) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Please Enter valid Zip Code!")
                      );
                    },
                  }),
                ]}
              >
                <Input
                  type="number"
                  maxLength={"6"}
                  allowClear
                  onChange={(e) => {
                    listingChange(e.target.value, "postalCode");
                  }}
                  placeholder="Please Enter Zip Code"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item>
                <Button
                  style={{
                    width: "100%",
                    minWidth: "130px",
                    marginTop: "20px",
                    marginBottom: "-20px",
                    marginLeft: isMobile ? "" : isTab ? "" : "50%",
                  }}
                  type="primary"
                  onClick={handleListingForm}
                  disabled={
                    !listingForm.firstName ||
                    !listingForm.lastName ||
                    !listingForm.streetNumber ||
                    !listingForm.streetName ||
                    !listingForm.city ||
                    !listingForm.stateOrProvince ||
                    !listingForm.postalCode
                  }
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        </div>
      )}
    </div>
  );
};

export default ManualEntry;
