import { calendarConstants } from "./action-types";
import { calendarApi } from "../utils/api";
/**
 * All Actions for contract Page
 */

/**
 * @param {string}
 */

//  url shorthand

const urlShorthandFailure = (error) => {
  return {
    type: calendarConstants.SHORTHAND_URL_FAILURE,
    urlShorthandLoading: error,
    urlShorthandError: false,
  };
};

const urlShorthandSuccess = (response) => {
  return {
    type: calendarConstants.SHORTHAND_URL_SUCCESS,
    urlShorthandData: response,
    urlShorthandLoading: false,
  };
};

const urlShorthandLoading = (status) => {
  return {
    type: calendarConstants.SHORTHAND_URL_LOADING,
    payload: status,
  };
};

const urlShorthandApiCalling = (data) => {
  return (dispatch, getState) => {
    dispatch(urlShorthandLoading(true));
    calendarApi
      .urlShorthandApi(data)
      .then((response) => {
        dispatch(urlShorthandSuccess(response.data.info._id));
      })
      .catch((error) => {
        dispatch(urlShorthandFailure(error));
        // if (error.response.status === 401) {
        //   localStorage.removeItem("userAuth");
        //   window.location.reload();
        // }
      });
  };
};

const saveFromCalendarDate = (data) => {
  return {
    type: calendarConstants.SAVE_FROM_CALENDAR_DATE,
    payload: data,
  };
};

export const calendarHandle = {
  urlShorthandApiCalling,
  saveFromCalendarDate,
};
