import { Tooltip, Typography } from "antd";
import React from "react";
import styles from "../styles/Client.module.css";
import { HiUserAdd } from "react-icons/hi";
import moment from "moment";

const CreatedAt = ({ personDetails }) => {
  const Text = Typography;
  return (
    <Text style={{ display: "flex", alignItems: "center" }}>
      <div style={{ display: "flex", alignItems:"flex-start" }}>
        <Tooltip title={"Date Created"} placement="left" >
          <HiUserAdd className={styles.textAlignment} />
        </Tooltip>
      </div>
      <div>
        <div style={{ paddingLeft: "5px",
                      "fontSize": "18px",
                       "textTransform": "capitalize" }}>
          {" "}
          {moment(personDetails.createdAt).format("MMMM D, YYYY hh:mm A")}{" "}
        </div>
      </div>
    </Text>
  );
};

export { CreatedAt };
